import { useState } from 'react';

import { Box, Container, TextField } from '@mui/material';
import { confirmSignIn } from 'aws-amplify/auth';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import CompanyLogo from '../../Common/CompanyLogo';
import MDLoadingButton from '../MDLoadingButton';
import Title from '../Title';
import { passwordSchema } from '../ValidationSchema/ValidationSchema';

const SetNewPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const signInWithNewPassword = async (password) => {
    setIsLoading(true);
    try {
      const loggedInUser = await confirmSignIn({
        challengeResponse: password,
        options: {},
        autoSignIn: false,
      });
      console.log(loggedInUser);
      setIsLoading(false);
      console.log('Logged In Successfully');
    } catch (error) {
      console.log('Error confirming sign in with new password', error);
      toast.error(error?.message);
      setIsLoading(false);
    }
  };

  const initialValues = { password: '', confirmPassword: '' };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          const isValid = values.password && values.confirmPassword;
          if (isValid) {
            // Perform your login logic here
            const { password } = values;
            await signInWithNewPassword(password);
          }
        }}
        validationSchema={passwordSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <Box
              component='form'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
              }}
              onSubmit={handleSubmit}
            >
              <Box
                sx={{
                  minWidth: '304px',
                  maxWidth: '372px',
                  py: 2,
                  px: 1,
                }}
              >
                <CompanyLogo />

                <Title>Set New Password</Title>

                <TextField
                  error={errors.password && touched.password}
                  variant='standard'
                  fullWidth
                  margin='dense'
                  autoComplete='off'
                  color='common'
                  id='password'
                  name='password'
                  type='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='New Password'
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />

                <TextField
                  error={errors.confirmPassword && touched.confirmPassword}
                  variant='standard'
                  fullWidth
                  margin='dense'
                  autoComplete='off'
                  color='common'
                  type='password'
                  label='Confirm New Password'
                  name='confirmPassword'
                  id='confirmPassword'
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                />
                <MDLoadingButton loading={isLoading}>
                  Set Password
                </MDLoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SetNewPassword;
