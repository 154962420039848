/* eslint-disable no-useless-escape */
import {
  useMutateAudioBiteQuery,
  useUpdateAudioBiteQuery,
  useUpdateDescriptionQuery,
} from 'graphql/gen.types';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEditCMSContext } from '../EditCmsContext';

const AudioContext = createContext(undefined);

const AudioContextProvider = ({ children }) => {
  const { id: moduleId } = useParams();
  const { contentBiteId, setContentBiteId, contentBiteIndex, selectedSection } =
    useEditCMSContext();

  const [preview, setPreview] = useState('');
  const [durationMillis, setDurationMillis] = useState(0);
  const [audioDescription, setAudioDescription] = useState('');

  const mutateAudioBiteInput = {
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    audioDescription: audioDescription,
    audio: preview,
    durationMillis: parseInt(durationMillis),
    index: contentBiteIndex,
  };

  const updateAudioBiteInput = {
    id: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    audio: preview,
    durationMillis: parseInt(durationMillis),
  };

  const updateMediaDescriptionInput = {
    contentBiteId: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    description: audioDescription,
  };

  const [
    executeAudioBite,
    { error: audioBiteError, loading: audioBiteLoading },
  ] = useMutateAudioBiteQuery({
    variables: {
      input: mutateAudioBiteInput,
    },
  });

  const [
    updateAudioBite,
    { error: updateAudioBiteError, loading: updateAudioBiteLoading },
  ] = useUpdateAudioBiteQuery({
    variables: {
      input: updateAudioBiteInput,
    },
  });

  const [
    updateMediaDescription,
    { error: updateDescriptionError, loading: updateDescriptionLoading },
  ] = useUpdateDescriptionQuery({
    variables: {
      input: updateMediaDescriptionInput,
    },
  });

  const resetAudioData = () => {
    setPreview('');
    setDurationMillis('');
    setAudioDescription('');
    setContentBiteId('');
  };

  const audioContextValue = {
    preview,
    setPreview,
    durationMillis,
    setDurationMillis,
    audioDescription,
    setAudioDescription,
    audioBiteError,
    audioBiteLoading,
    executeAudioBite,
    updateAudioBiteError,
    updateAudioBiteLoading,
    updateAudioBite,
    updateDescriptionError,
    updateDescriptionLoading,
    updateMediaDescription,
    resetAudioData,
  };

  return (
    <AudioContext.Provider value={audioContextValue}>
      {children}
    </AudioContext.Provider>
  );
};

const useAudioContext = () => {
  return useContext(AudioContext);
};

export { AudioContext, AudioContextProvider, useAudioContext };
