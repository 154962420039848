import React, { useCallback, useEffect, useState } from 'react';
import { useFileUpload } from 'use-file-upload';
import {
  GetIsModuleIdentifierAvailable,
  ModuleListingDocument,
  useCreateNewModule,
} from 'graphql/gen.types';
import { useMeContext } from 'context/meContext';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloudUpload } from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';

const moduleTypeOptions = [
  { value: 'single', label: 'Single' },
  { value: 'hub', label: 'Hub' },
];

const moduleAccessScopeOptions = [
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' }
];

const REGEX_MODULE_IDENTIFIER = /^[a-z]\w*(\.[a-z]\w*)+$/;

const CreateNewModule = (props) => {
  const { open, onClose } = props;

  // eslint-disable-next-line no-unused-vars
  const [file, selectFile] = useFileUpload();
  const [imgDataUrl, setImgDataUrl] = useState(undefined);
  const [moduleType, setModuleType] = useState('');
  const [moduleAccessScope, setModuleAccessScope] = useState('');
  const [moduleIdentifier, setModuleIdentifier] = useState('');
  const [moduleName, setModuleName] = useState('');

  const { userData } = useMeContext();

  const fileSelectedHandler = ({ file }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImgDataUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
  };

  const handleModuleType = (e) => {
    setModuleType(e.target.value);
  };

  const handleModuleAccessScope = (e) => {
    setModuleAccessScope(e.target.value);
  };

  const [
    queryIsModuleIdentifierAvailable,
    {
      loading: isModuleIdentifierAvailableLoading,
      data: isModuleIdentifierAvailableData,
    },
  ] = useLazyQuery(GetIsModuleIdentifierAvailable);

  const isModuleIdentifierValid = () => {
    return (
      !_.isEmpty(moduleIdentifier) &&
      REGEX_MODULE_IDENTIFIER.test(moduleIdentifier)
    );
  };

  const isModuleIdentifierAvailable = () => {
    return (
      !isModuleIdentifierAvailableLoading &&
      isModuleIdentifierAvailableData?.isModuleIdentifierAvailable
    );
  };

  const moduleFieldErrorState = () => {
    if (_.isEmpty(moduleIdentifier) || isModuleIdentifierAvailableLoading) {
      return false;
    }
    return !(isModuleIdentifierValid() && isModuleIdentifierAvailable());
  };

  const moduleFieldHelperText = () => {
    if (_.isEmpty(moduleIdentifier)) {
      return null;
    } else if (isModuleIdentifierAvailableLoading) {
      return null;
    } else if (!isModuleIdentifierValid()) {
      return 'Identifier should be in the format <domain>.<organization>.<category>.<detail>';
    } else if (!isModuleIdentifierAvailableData?.isModuleIdentifierAvailable) {
      return 'Identifier not available';
    } else {
      return null;
    }
  };

  const createNewModuleInput = {
    accountId: userData?.account?.id,
    name: moduleName,
    icon: imgDataUrl,
    moduleType: moduleType?.toUpperCase(),
    moduleIdentifier: moduleIdentifier,
    moduleAccessScope: moduleAccessScope?.toUpperCase(),
  };

  const [createNewModule, { error, loading }] = useCreateNewModule({
    variables: {
      input: createNewModuleInput,
    },
  });

  const clearData = useCallback(() => {
    setModuleIdentifier('');
    setModuleName('');
    setModuleType('');
    setModuleAccessScope('');
    setImgDataUrl(undefined);
  }, []);

  useEffect(() => {
    if (isModuleIdentifierValid()) {
      queryIsModuleIdentifierAvailable({
        variables: {
          moduleIdentifier: moduleIdentifier?.trim(),
        },
      }).catch((error) => console.log(error));
    }
    // eslint-disable-next-line
  }, [moduleIdentifier]);

  const dialogClose = () => {
    onClose();
  };

  const executeCreateNewModule = async () => {
    if (
      moduleType &&
      imgDataUrl &&
      moduleIdentifier &&
      moduleName &&
      userData
    ) {
      await createNewModule({
        refetchQueries: [
          {
            query: ModuleListingDocument,
            variables: {
              accountId: userData?.account?.id,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          dialogClose();
        },
      }).catch((error) => console.log(error));
    }
  };

  const isDataReady = () => {
    return (
      isModuleIdentifierValid() &&
      isModuleIdentifierAvailable() &&
      !_.isEmpty(moduleName) &&
      !_.isEmpty(moduleType) &&
      !_.isEmpty(imgDataUrl) &&
      !_.isEmpty(moduleAccessScope)
    );
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={dialogClose}>
      <DialogTitle>
        <Typography variant='h4' component='span'>
          Create New Module
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={dialogClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {error && <Alert severity='error'>Something Went Wrong...!</Alert>}
        <Box>
          <TextField
            label='Module Identifier'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            value={moduleIdentifier}
            onChange={(e) => setModuleIdentifier(e.target.value)}
            error={moduleFieldErrorState()}
            helperText={moduleFieldHelperText()}
          />

          <TextField
            label='Module Name'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
          />

          <FormControl margin='normal' fullWidth>
            <InputLabel id='demo-simple-select-label'>Module Type</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Module Type'
              value={moduleType}
              onChange={handleModuleType}
              size='medium'
            >
              {moduleTypeOptions?.map((menuItem, i) => {
                const { label, value } = menuItem;
                return (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl margin='normal' fullWidth>
            <InputLabel id='demo-simple-select-label'>
              Module Access Scope
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Module Access Scope'
              value={moduleAccessScope}
              onChange={handleModuleAccessScope}
              size='medium'
            >
              {moduleAccessScopeOptions?.map((menuItem, i) => {
                const { label, value } = menuItem;
                return (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Avatar sx={{ height: '8rem', width: '8rem' }}>
              <img
                src={imgDataUrl}
                alt=''
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              />
            </Avatar>

            <Button
              sx={{ mt: 1 }}
              variant='primary'
              size='medium'
              startIcon={<CloudUpload />}
              onClick={handleSelectFile}
            >
              Upload image
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            clearData();
          }}
        >
          Clear
        </Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          onClick={executeCreateNewModule}
          disabled={!isDataReady()}
        >
          Create New Module
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewModule;
