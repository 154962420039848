import React, { useEffect, useState } from 'react';
import {
  useAssignAccountRole,
  useMutateUserEmail,
  useResendInvite,
  useUserByIdQuery,
} from 'graphql/gen.types';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const EditUser = (props) => {
  const { userId, onClose, ...rest } = props;

  // eslint-disable-next-line
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [accountRole, setAccountRole] = useState('');

  const handleAccountRoleChange = (event) => {
    const accountRole = event.target.value;
    setAccountRole(accountRole);
  };

  // Fetch the query
  const { loading, error, data } = useUserByIdQuery({
    variables: { userID: userId },
  });

  useEffect(() => {
    if (data) {
      setEmailInput(data?.user?.email);
      setAccountRole(data?.user?.accountRole);
    }
  }, [data]);

  const [
    mutateUserEmail,
    { loading: mutateUserEmailLoading, error: mutateUserEmailError },
  ] = useMutateUserEmail();

  const [
    resendInvite,
    { loading: resendInviteLoading, error: resendInviteError },
  ] = useResendInvite();

  const [assignAccountRole, { loading: assignAccountRoleloading }] =
    useAssignAccountRole({});

  const hasEmailChanged = () => {
    return !_.eq(displayData.email, emailInput);
  };

  const resetEmail = () => {
    setEmailInput(displayData.email);
  };

  const hasAccountRoleChanged = () => {
    return !_.eq(displayData.accountRole, accountRole?.toUpperCase());
  };

  const resetAccountRole = () => {
    setAccountRole(displayData.accountRole);
  };

  const submitUpdateEmail = async () => {
    if (hasEmailChanged()) {
      const mutateUserEmailInput = {
        userId: userId,
        email: emailInput,
      };
      await mutateUserEmail({
        variables: {
          input: mutateUserEmailInput,
        },
      });
    }
  };

  const submitResendInvite = async () => {
    const resendInviteInput = {
      userId: userId,
      email: emailInput,
    };
    await resendInvite({
      variables: {
        input: resendInviteInput,
      },
    });
  };

  const submitUpdateAccountRole = async () => {
    if (hasAccountRoleChanged()) {
      const assignAccountRoleInput = {
        userId: userId,
        accountRole: accountRole?.toUpperCase(),
      };
      const { errors } = await assignAccountRole({
        variables: {
          input: assignAccountRoleInput,
        },
      });
      if (errors) {
        console.log(errors);
      }
    }
  };

  const handleClose = () => {
    onClose();
    resetEmail();
    resetAccountRole();
  };

  // When Loading is true, show a loading view
  if (loading) {
    return (
      <Dialog fullWidth maxWidth='md' open={true}>
        <DialogContent>
          <div className='flex-center'>
            <CircularProgress />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  // If there is an error, show an alert view
  if (error || mutateUserEmailError || resendInviteError) {
    console.log('Error', { error, mutateUserEmailError, resendInviteError });
    return (
      <Dialog fullWidth maxWidth='md' open={true} onHide={onClose}>
        <DialogTitle>Error</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Alert severity='error'>Error performing network request.</Alert>
        </DialogContent>
      </Dialog>
    );
  }

  // Data is ready
  const user = data.user;

  const displayData = {
    name: [user.firstName, user.lastName].join(' '),
    email: user.email,
    accountRole: user.accountRole,
    userConfirmed: user.userConfirmed,
  };

  return (
    <Dialog fullWidth maxWidth='sm' {...rest} onClose={handleClose}>
      <DialogTitle>User Details</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <TextField
            label='Name'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            value={displayData.name}
            onChange={(e) => setNameInput(e.target.value)}
            inputProps={{ readOnly: true }}
            disabled
          />
          <TextField
            label='Email'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                submitUpdateEmail();
                ev.preventDefault();
              } else if (ev.key === 'Escape') {
                resetEmail();
                ev.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: mutateUserEmailLoading ? (
                <InputAdornment position='end'>
                  <CircularProgress
                    color='inherit'
                    size='40px'
                    sx={{ padding: '8px' }}
                  />
                </InputAdornment>
              ) : hasEmailChanged() ? (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='submit changes'
                    onClick={submitUpdateEmail}
                  >
                    <Check />
                  </IconButton>
                  <IconButton aria-label='cancel changes' onClick={resetEmail}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />

          <Box>
            <FormControl margin='normal'>
              <FormLabel>Role In The Account</FormLabel>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                <RadioGroup
                  row
                  aria-labelledby='demo-role'
                  name='role'
                  value={accountRole}
                  onChange={handleAccountRoleChange}
                >
                  <FormControlLabel
                    value='MEMBER'
                    control={<Radio />}
                    label='Member'
                  />
                  <FormControlLabel
                    value='ADMIN'
                    control={<Radio />}
                    label='Admin'
                  />
                </RadioGroup>
                {assignAccountRoleloading ? (
                  <CircularProgress
                    color='inherit'
                    size='40px'
                    sx={{ padding: '8px' }}
                  />
                ) : hasAccountRoleChanged() ? (
                  <Box>
                    <IconButton
                      aria-label='submit changes'
                      onClick={submitUpdateAccountRole}
                    >
                      <Check />
                    </IconButton>
                    <IconButton
                      aria-label='submit changes'
                      onClick={resetAccountRole}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>
            </FormControl>
          </Box>
          {!displayData.userConfirmed && (
            <LoadingButton
              loading={resendInviteLoading}
              onClick={submitResendInvite}
              variant='contained'
            >
              Resend Invite
            </LoadingButton>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditUser;
