/* eslint-disable no-useless-escape */
import { useMutateYouTubeVideoBite } from 'graphql/gen.types';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEditCMSContext } from '../EditCmsContext';

const YTContext = createContext(undefined);

const YTContextProvider = ({ children }) => {
  const { id: moduleId } = useParams();
  const { contentBiteId, contentBiteIndex, selectedSection } =
    useEditCMSContext();

  const [ytVideoUrl, setYtVideoUrl] = useState('');

  const ytVideoId =
    ytVideoUrl &&
    ytVideoUrl?.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    )?.[1];

  const mutateYouTubeVideoBiteInput = {
    ytVideoId: ytVideoId,
    id: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    index: contentBiteIndex,
  };

  const [
    executeYouTubeVideoBite,
    { error: youtubeVideoBiteError, loading: youtubeVideoBiteLoading },
  ] = useMutateYouTubeVideoBite({
    variables: {
      input: mutateYouTubeVideoBiteInput,
    },
  });

  const YTContextValue = {
    ytVideoId,
    ytVideoUrl,
    setYtVideoUrl,
    youtubeVideoBiteError,
    youtubeVideoBiteLoading,
    executeYouTubeVideoBite,
  };

  return (
    <YTContext.Provider value={YTContextValue}>{children}</YTContext.Provider>
  );
};

const useYTContext = () => {
  return useContext(YTContext);
};

export { YTContext, YTContextProvider, useYTContext };
