import { ApolloProvider } from '@apollo/client';
import 'App.scss';
import AdminConsoleModalHandler from 'components/admin_console/AdminConsoleModalHandler';
import PageNotFound from 'components/PageNotFound';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CMS from 'components/cms/CMS';
import { Amplify } from 'aws-amplify';
import AWSConfig from './aws-exports';
import SignIn from 'components/Authentication/SignIn/SignIn';
import SetNewPassword from 'components/Authentication/SetNewPassword/SetNewPassword';
import ForgotPassword from 'components/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from 'components/Authentication/ResetPassword/ResetPassword';
import { MeProvider } from 'context/meContext';
import { ProtectedRoute } from 'router/ProtectedRoute';
import { AuthRoute } from 'router/AuthRoute';
import MainLayout from 'components/layout/MainLayout';
import { routes } from 'routes';
import { apolloClient } from 'graphql/apolloClient';
import { CMSMainProvider } from 'context/CMSMainContext';
import { CMSContextProvider } from 'components/cms/CMSContext';
Amplify.configure(AWSConfig);

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <>
        <div className='container-fluid px-0'>
          <AdminConsoleModalHandler />

          <Routes>
            <Route path='/' element={<MainLayout />}>
              {routes}
            </Route>
            {/**
             * App id should be a path parameter because CMS is used per
             * app (meaningless to not have an appId inside the CMS)
             * */}

            <Route
              path='/login'
              element={
                <AuthRoute>
                  <SignIn />
                </AuthRoute>
              }
            />
            <Route
              path='/set-new-password'
              element={
                <AuthRoute>
                  <SetNewPassword />
                </AuthRoute>
              }
            />
            <Route
              path='/forgot-password'
              element={
                <AuthRoute>
                  <ForgotPassword />
                </AuthRoute>
              }
            />
            <Route
              path='/reset-password'
              element={
                <AuthRoute>
                  <ResetPassword />
                </AuthRoute>
              }
            />

            <Route
              path='/cms/:id/:sectionSlug?/:contentBiteSlug?'
              element={
                <ProtectedRoute>
                  <MeProvider>
                    <CMSMainProvider>
                      <CMSContextProvider>
                        <CMS />
                      </CMSContextProvider>
                    </CMSMainProvider>
                  </MeProvider>
                </ProtectedRoute>
              }
            />

            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </div>
      </>
    </ApolloProvider>
  );
};

export default App;
