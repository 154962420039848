/**
 * This component renders & manages the main content in the CMS. The list of all content bites, section
 * title & section settings belong here.
 */
import { useReactiveVar } from '@apollo/client';
import {
  rVarCMSSectionSelectionID,
  rVarHasModuleAccessRoles,
  rVarSelectedContent,
} from 'global/const';
import { SectionByID } from 'global/DataUtils';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import _ from 'lodash';
import CreateContentBiteList from './CreateContentBiteList';
import { useEditCMSContext } from './EditCmsContext';
import {
  CreateNewContentBite,
  ID_CREATE_AUDIO_BITE,
  ID_CREATE_IMAGE_BITE,
  ID_CREATE_TEXT_BITE,
  ID_CREATE_VIDEO_BITE,
  ID_CREATE_YOUTUBE_VIDEO_BITE,
} from './Content_Bites_List';
import './file-upload.scss';
import { Box, Divider, Grid, Typography } from '@mui/material';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import { ContentBiteComponent } from './content_bites/ContentBiteComponent';
import { useReorderContentBites } from 'graphql/gen.types';
import CMSContentBitesModalHandler from './cms_bites_modal/CMSContentBitesModalHandler';
import TextBiteContentHandler from './cms_bites_modal/TextBiteContentHandler';
import { useCMSMainContext } from 'context/CMSMainContext';
import { useMeContext } from 'context/meContext';
import { scrollIntoContentBiteView } from 'components/utils/CommonFunction';

export const targetModuleAccessRoles = ['ADMIN'];

const CoreContentSection = () => {
  // Reactive variables on which this component depends
  const firstItemSelected = useReactiveVar(rVarCMSSectionSelectionID);
  const selectedContentId = useReactiveVar(rVarSelectedContent);
  const { sectionId } = selectedContentId;

  const { selectedModule } = useCMSMainContext();
  const { userData } = useMeContext();
  const moduleAccessList = userData?.moduleAccessList?.filter(
    (moduleAccess) => moduleAccess?.moduleId === selectedModule?.module?.id,
  );
  useEffect(() => {
    if (moduleAccessList) {
      const hasModuleAccessRoles = targetModuleAccessRoles.some((role) =>
        moduleAccessList?.[0]?.moduleRole?.includes(role),
      );
      rVarHasModuleAccessRoles({
        hasModuleAccessRoles: hasModuleAccessRoles,
      });
    }
  }, [moduleAccessList]);

  const selection = sectionId ? sectionId : firstItemSelected;

  const {
    show,
    setShow,
    setComponentState,
    selectedSection,
    contentBites,
    setModalType,
    contentBiteIndex,
    setContentBiteIndex,
  } = useEditCMSContext();

  const [contentBiteType, setContentBiteType] = useState('');
  const [hasSortEnd, setHasSortEnd] = useState(null);

  const clickOutSideRef = React.createRef();
  const clickOutSideIndexRef = React.createRef();

  const reorderContentBitesInput = {
    moduleId: selectedModule?.module?.id,
    sectionId: selection,
    contentBiteIds: contentBites?.map((contentBite) => contentBite?.id) || [],
  };

  const [reorderContentBites] = useReorderContentBites();

  const reorderContentBitesData = async () => {
    await reorderContentBites({
      variables: { input: reorderContentBitesInput },
    }).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    if (contentBites && hasSortEnd) {
      reorderContentBitesData();
      setTimeout(() => {
        setHasSortEnd(null);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [contentBites]);

  useOnClickOutside(clickOutSideRef, () => setShow(false));
  useOnClickOutside(clickOutSideIndexRef, () => {
    setContentBiteIndex(null);
    handleRemoveContentBiteList();
  });

  // Review this pattern : the RC should work just fine without useMemo and useMemo should be
  // used only as performance optimization. However, without useMemo hook, the component
  // either gets into an infinite rendering loop or doesn't get updated when the reactive
  // variables get updated.
  //
  // Memo-ized function for setting initial component state or updating it when the
  // app or selection changes.

  useEffect(() => {
    if (selection !== undefined) {
      if (selectedModule) {
        // Everytime a new selection is made, the content section should go back to the top
        const selSection = SectionByID(
          selection,
          selectedModule?.module?.sections,
        );

        // There are no situations when SectionByID could return a non valid value.
        // However, we have seen crashes where the result is undefined. It is likely happening
        // because of repeated query executions, the root cause of which is unknown. Disabling
        // React Strict Mode seems to fix the repeated query calls issue.
        if (selSection !== null && selSection !== undefined) {
          setComponentState({
            selectedSection: selSection,
            contentBites: _.cloneDeep(selSection.content),
            scrollToID: undefined,
          });
        } else {
          console.warn('Error State. Section not found for a valid selection');
          console.warn(
            JSON.stringify(
              { appId: selectedModule?.module?.id, selection: selection },
              null,
              2,
            ),
          );
        }
      }
    }
  }, [selectedModule, selection, setComponentState]);

  useLayoutEffect(() => {
    if (
      selectedContentId.contentBiteId !== undefined &&
      selectedModule?.module !== undefined &&
      selection !== undefined &&
      contentBites.length > 0
    ) {
      scrollIntoContentBiteView(selectedContentId.contentBiteId?.toString());
      setTimeout(() => {
        rVarSelectedContent({
          moduleId: selectedModule?.module?.id,
          sectionId: selection,
          contentBiteId: undefined,
        });
      }, 8000);
    }
  }, [selectedContentId, selectedModule, selection, contentBites]);

  const getConvertedList = (contentBiteList) => {
    contentBiteList.forEach((contentBite, i) => {
      if (_.startsWith(contentBite.id, 'create')) {
        if (contentBite.id === ID_CREATE_TEXT_BITE) {
          setContentBiteType('TextBite');
          setContentBiteIndex(i);
        } else if (contentBite.id === ID_CREATE_IMAGE_BITE) {
          setModalType(ID_CREATE_IMAGE_BITE);
          setContentBiteIndex(i);
        } else if (contentBite.id === ID_CREATE_VIDEO_BITE) {
          setModalType(ID_CREATE_VIDEO_BITE);
          setContentBiteIndex(i);
        } else if (contentBite.id === ID_CREATE_AUDIO_BITE) {
          setModalType(ID_CREATE_AUDIO_BITE);
          setContentBiteIndex(i);
        } else if (contentBite.id === ID_CREATE_YOUTUBE_VIDEO_BITE) {
          setModalType(ID_CREATE_YOUTUBE_VIDEO_BITE);
          setContentBiteIndex(i);
        } else {
          setModalType('');
          setShow(false);
          setContentBiteIndex(null);
          setContentBiteType('');
        }
        contentBiteList[i] = CreateNewContentBite(contentBite);
      }
    });
    return { contentBiteList };
  };

  const onSetList = (list) => {
    const { contentBiteList } = getConvertedList(list);
    setComponentState({
      selectedSection,
      contentBites: contentBiteList,
      scrollToID: undefined,
    });
  };

  const handleRemoveContentBiteList = () => {
    // Remove items from contentBiteList where id is not "36"
    const updatedContentBiteList = contentBites?.filter(
      (item) => item?.id?.toString()?.length === 36 || item.id === undefined,
    );
    // Update the component state
    setComponentState({
      selectedSection,
      contentBites: updatedContentBiteList,
      scrollToID: undefined,
    });
    setContentBiteIndex(null);
    setContentBiteType('');
  };

  if (selection === undefined || selectedSection === undefined) {
    return (
      <>
        <Typography variant='h5' sx={{ my: 2, px: 2 }}>
          No Content Found.
        </Typography>
      </>
    );
  }

  const SectionHeader = (selectedSection) => {
    return (
      <>
        <Box sx={{ wordBreak: 'break-all' }} id='core-content-section-header'>
          <Typography gutterBottom variant='h4'>
            {selectedSection?.title}
          </Typography>
          <Divider />
        </Box>
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item sx={{ pr: 1 }} xs={10}>
          <Box sx={{ p: 4 }}>
            {SectionHeader(selectedSection)}
            <Box sx={{ overflowY: 'scroll', height: '73vh', py: 2 }}>
              <ReactSortable
                group={{
                  name: 'core-content-section',
                  put: ['create-bite-list'],
                }}
                list={contentBites}
                setList={onSetList}
                revertOnSpill={true}
                chosenClass='contentbite-chosen'
                delay={200}
                animation={500}
                easing='cubic-bezier(0.22,1,0.36,1)'
                onEnd={() => setHasSortEnd(true)}
                scroll={true}
                bubbleScroll={true}
                scrollSensitivity={240}
                scrollSpeed={20}
                style={{ height: show ? '' : '100%' }}
              >
                {contentBites?.map((contentBite, index) => {
                  return (
                    <Box key={index}>
                      {contentBiteIndex === index &&
                      contentBiteType === 'TextBite' ? (
                        <TextBiteContentHandler
                          setContentBiteType={setContentBiteType}
                          ref={clickOutSideIndexRef}
                        />
                      ) : (
                        ''
                      )}
                      <ContentBiteComponent item={contentBite} />
                    </Box>
                  );
                })}
              </ReactSortable>
              {show ? (
                <TextBiteContentHandler
                  setContentBiteType={setContentBiteType}
                  ref={clickOutSideRef}
                />
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Grid>
        <Grid className='vertical-line-left' sx={{ px: 0 }} item xs={2}>
          <CreateContentBiteList />
        </Grid>
      </Grid>

      <CMSContentBitesModalHandler
        handleRemoveContentBiteList={handleRemoveContentBiteList}
      />
    </>
  );
};

export default CoreContentSection;
