import React, { memo, useRef, useState } from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import MDEditor from '@uiw/react-md-editor';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import DeleteContentBiteDialog from './DeleteContentBiteDialog';
import { useUpdateTextBiteQuery } from 'graphql/gen.types';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';

const TextBiteComponent = (props) => {
  const { id: moduleId } = useParams();

  const {
    setShowEdit,
    showEdit,
    contentBiteId,
    setTextBite,
    textBite,
    deleteContentBiteLoading,
    deleteContentBite,
    selectedSection,
  } = useEditCMSContext();

  const ref = useRef();
  const clickOutSideRef = React.createRef();

  useOnClickOutside(clickOutSideRef, () => {
    close();
  });

  const [deleteContentBiteModal, setDeleteContentBiteModal] = useState(false);

  const textContentBite = props.contentBite;

  const updateTextBiteInput = {
    id: contentBiteId,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    text: textBite,
  };

  const [
    updateTextBite,
    { loading: updateTextBiteLoading, error: updateTextBiteError },
  ] = useUpdateTextBiteQuery({
    variables: { input: updateTextBiteInput },
  });

  const updateTextBiteandClose = () => {
    updateTextBite({
      onCompleted: () => {
        close();
      },
    });
  };

  const close = () => {
    setTextBite('');
    setShowEdit(false);
  };

  const onCloseDialog = () => setDeleteContentBiteModal(false);

  // When Loading is false & data is available, display the data in the table
  if (updateTextBiteError) {
    console.log(updateTextBiteError);
    return <Alert severity='error'>Error performing network request</Alert>;
  }

  return (
    <>
      <Box
        className='text-bite-content'
        ref={ref}
        sx={{ whiteSpace: 'normal', position: 'relative' }}
      >
        {showEdit &&
        contentBiteId?.toString() === textContentBite?.id?.toString() ? (
          ''
        ) : (
          <MDEditor.Markdown source={textContentBite.text} />
        )}

        <Box
          className='text-action-buttons'
          sx={{ zIndex: '100', top: '0', right: '0', position: 'absolute' }}
        >
          <IconButton
            disableRipple
            sx={{ background: 'blue', color: 'white' }}
            aria-label='delete'
            size='medium'
            onClick={props.handleEditItem}
          >
            <EditIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            onClick={() => {
              props.handleDeleteItem();
              setDeleteContentBiteModal(true);
            }}
            disableRipple
            sx={{ background: 'red', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Box>

      {showEdit &&
      contentBiteId?.toString() === textContentBite?.id?.toString() ? (
        <>
          <Box ref={clickOutSideRef}>
            <MDEditor value={textBite} onChange={setTextBite} />
            <Box sx={{ display: 'flex', justifyContent: 'end', my: 2, gap: 1 }}>
              <Button onClick={close}>Close</Button>
              <LoadingButton
                loading={updateTextBiteLoading}
                onClick={updateTextBiteandClose}
                variant='contained'
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </>
      ) : (
        ''
      )}

      {deleteContentBiteModal && (
        <DeleteContentBiteDialog
          onCloseDialog={onCloseDialog}
          deleteContentBite={deleteContentBite}
          deleteContentBiteLoading={deleteContentBiteLoading}
        />
      )}
    </>
  );
};

export default memo(TextBiteComponent);
