import { Typography } from '@mui/material';

const Title = ({ children }) => {
  return (
    <Typography pt='8px' variant='h4'>
      {children}
    </Typography>
  );
};

export default Title;
