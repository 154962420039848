/* eslint-disable no-useless-escape */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteContentBite, useAddTextBiteQuery } from 'graphql/gen.types';
import {
  ID_CREATE_TEXT_BITE,
  CreateBiteList,
  CreateNewContentBite,
} from './Content_Bites_List';
import { Alert } from '@mui/material';

const EditEMSContext = createContext(undefined);

const EditCMSProvider = ({ children }) => {
  const [textBite, setTextBite] = useState('');
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [contentBiteId, setContentBiteId] = useState('');
  const [file, setFile] = useState('');
  const [preview, setPreview] = useState('');
  const [modalType, setModalType] = useState(null);
  const [contentBiteIndex, setContentBiteIndex] = useState(null);

  const { id } = useParams();

  const [{ selectedSection, contentBites, scrollToID }, setComponentState] =
    useState({
      selectedSection: undefined,
      contentBites: [],
      scrollToID: undefined,
    });

  const mutateTextBiteInput = {
    moduleId: id,
    sectionId: selectedSection?.id,
    text: textBite,
    index: contentBiteIndex,
  };

  const deleteContentBiteInput = {
    moduleId: id,
    sectionId: selectedSection?.id,
    contentBiteId: contentBiteId,
  };

  const [createTextBite, { error: textBiteError, loading: textBiteLoading }] =
    useAddTextBiteQuery({
      variables: {
        input: mutateTextBiteInput,
      },
    });

  const [
    deleteContentBite,
    { error: deleteContentBiteError, loading: deleteContentBiteLoading },
  ] = useDeleteContentBite({
    variables: {
      input: deleteContentBiteInput,
    },
    onCompleted: () => {
      setContentBiteId('');
    },
  });

  // Scroll to the created item after the rendering has ended
  useEffect(() => {
    if (scrollToID !== undefined) {
      // Ideally we would like to use the react's way of scrolling.
      document
        .getElementById(scrollToID)
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToID]);

  const onCreateBite = (createBite) => {
    const newBite = CreateNewContentBite(createBite);
    contentBites.push(newBite);
    setComponentState({
      selectedSection,
      contentBites,
      scrollToID: undefined,
    });
  };

  // Function to create a content bite when the modal is closed
  const createContentBite = (close) => {
    const contentBite = {
      text: textBite,
      ...CreateBiteList.find((bite) => bite.id === ID_CREATE_TEXT_BITE), // Use the "Text" label for the content bite
    };
    onCreateBite(contentBite);
    createTextBite({
      onCompleted: () => {
        close();
        setTextBite('');
        setShow(false);
        setShowEdit(false);
      },
    });
  };

  // When Loading is false & data is available, display the data in the table
  if (textBiteError || deleteContentBiteError) {
    console.log(textBiteError || deleteContentBiteError);
    return <Alert severity='error'>Error performing network request.</Alert>;
  }

  return (
    <EditEMSContext.Provider
      value={{
        textBite,
        setTextBite,
        show,
        setShow,
        onCreateBite,
        createContentBite,
        setComponentState,
        selectedSection,
        contentBites,
        scrollToID,
        contentBiteId,
        setContentBiteId,
        textBiteLoading,
        createTextBite,
        showEdit,
        setShowEdit,
        preview,
        setPreview,
        file,
        setFile,
        modalType,
        setModalType,
        deleteContentBiteLoading,
        deleteContentBite,
        contentBiteIndex,
        setContentBiteIndex,
      }}
    >
      {children}
    </EditEMSContext.Provider>
  );
};

const useEditCMSContext = () => {
  return useContext(EditEMSContext);
};

export { EditEMSContext, EditCMSProvider, useEditCMSContext };
