import React from 'react';
import './SearchBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <div className='search-box'>
        <input
          className='search-input'
          value={searchTerm}
          onChange={handleInputChange}
          type='text'
          placeholder=''
        />
        <button className='search-btn'>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </>
  );
};

export default SearchBar;
