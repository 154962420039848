/* eslint-disable no-useless-escape */
import {
  useMutateImageBiteQuery,
  useUpdateDescriptionQuery,
  useUpdateImageBiteQuery,
} from 'graphql/gen.types';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEditCMSContext } from '../EditCmsContext';

const ImageContext = createContext(undefined);

const ImageContextProvider = ({ children }) => {
  const { id: moduleId } = useParams();
  const { contentBiteId, setContentBiteId, contentBiteIndex, selectedSection } =
    useEditCMSContext();

  const [preview, setPreview] = useState('');
  const [imageDimensions, setImageDimensions] = useState({});
  const [imageDescription, setImageDescription] = useState('');

  const mutateImageBiteInput = {
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    image: preview,
    imageDescription: imageDescription,
    imageSize: JSON.stringify(imageDimensions),
    index: contentBiteIndex,
  };

  const updateImageBiteInput = {
    id: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    image: preview,
    imageSize: JSON.stringify(imageDimensions),
  };

  const updateMediaDescriptionInput = {
    contentBiteId: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    description: imageDescription,
  };

  const [
    executeImageBite,
    { error: imageBiteError, loading: imageBiteLoading },
  ] = useMutateImageBiteQuery({
    variables: {
      input: mutateImageBiteInput,
    },
  });

  const [
    updateImageBite,
    { error: updateImageBiteError, loading: updateImageBiteLoading },
  ] = useUpdateImageBiteQuery({
    variables: {
      input: updateImageBiteInput,
    },
  });

  const [
    updateMediaDescription,
    { error: updateDescriptionError, loading: updateDescriptionLoading },
  ] = useUpdateDescriptionQuery({
    variables: {
      input: updateMediaDescriptionInput,
    },
  });

  const resetImageData = () => {
    setPreview('');
    setImageDimensions({});
    setImageDescription('');
    setContentBiteId('');
  };

  const imageContextValue = {
    preview,
    setPreview,
    imageDimensions,
    setImageDimensions,
    imageDescription,
    setImageDescription,
    imageBiteError,
    imageBiteLoading,
    executeImageBite,
    updateImageBiteError,
    updateImageBiteLoading,
    updateImageBite,
    updateDescriptionError,
    updateDescriptionLoading,
    updateMediaDescription,
    resetImageData,
  };

  return (
    <ImageContext.Provider value={imageContextValue}>
      {children}
    </ImageContext.Provider>
  );
};

const useImageContext = () => {
  return useContext(ImageContext);
};

export { ImageContext, ImageContextProvider, useImageContext };
