import * as React from 'react';
import { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Pagination,
} from '@mui/material';
import {
  ADMIN_CONSOLE_MODAL_SELECTION,
  rVarAdminConsoleModalContext,
} from 'global/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { MeDocument, useOrgUsersQuery } from 'graphql/gen.types';
import { useApolloClient } from '@apollo/client';
import {
  StyledTableCell,
  StyledTableRow,
} from 'components/Common/StyledTableComponent';
import { accountRoleToDisplayText } from '../../utils/CommonFunction';
import UserDashboardHeader from './UserDashboardHeader';

let PageSize = 10;

// Main component
const UserDashboardTable = () => {
  const client = useApolloClient();

  const [currentPage, setCurrentPage] = React.useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const user = client.readQuery({
    query: MeDocument,
  });

  const accountId = user?.me?.account?.id || ''; // If it's undefined, accountId will be an empty string

  // Fetch the query
  const { loading, error, data } = useOrgUsersQuery({
    displayName: 'UserDashboardTable',
    variables: {
      accountId: accountId,
    },
  });

  // useMemo hook to memoize response data everytime it changes
  const displayData = useMemo(() => {
    return data === undefined ? [] : data.users;
  }, [data]);

  // When Loading is true, show a loading view
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // When Loading is false & data is available, display the data in the table
  if (error) {
    console.log(error);
    return <Alert severity='error'>Error performing network request.</Alert>;
  }

  const computePagination = (data, currentPage, pageSize) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  };

  const currentData = computePagination(displayData, currentPage, PageSize);
  const totalCount = displayData.length;
  const totalPageCount = Math.ceil(totalCount / PageSize);

  return (
    <Box sx={{ px: '24px' }}>
      <UserDashboardHeader />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row, i) => {
              const hasRequiredRole =
                row.accountRole === 'SUPER_ADMIN' ||
                row.accountRole === 'ACCOUNT_OWNER';
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell
                    sx={{ minWidth: '160px', maxWidth: '160px' }}
                  >
                    {row.firstName === null && row.lastName === null
                      ? 'null'
                      : `${row.firstName} ${row.lastName}`}
                  </StyledTableCell>
                  <StyledTableCell width='auto'>{row.email}</StyledTableCell>
                  <StyledTableCell
                    sx={{ minWidth: '176px', maxWidth: '176px' }}
                  >
                    {accountRoleToDisplayText(row.accountRole)}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ minWidth: '112px', maxWidth: '112px' }}
                  >
                    {!hasRequiredRole && (
                      <>
                        <IconButton
                          onClick={() =>
                            rVarAdminConsoleModalContext({
                              selection:
                                ADMIN_CONSOLE_MODAL_SELECTION.EDIT_USER,
                              data: { userId: row.id },
                            })
                          }
                          color='inherit'
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 'large' }}
                            icon={faPencilAlt}
                          />
                        </IconButton>
                        <IconButton
                          sx={{ mx: 0.2 }}
                          onClick={() =>
                            rVarAdminConsoleModalContext({
                              selection:
                                ADMIN_CONSOLE_MODAL_SELECTION.DELETE_USER,
                              data: { userId: row.id },
                            })
                          }
                          color='inherit'
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 'large' }}
                            icon={faTrashAlt}
                          />
                        </IconButton>
                      </>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'end', my: 2 }}>
        <Pagination
          count={totalPageCount}
          page={currentPage}
          boundaryCount={2}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default UserDashboardTable;
