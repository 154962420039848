import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  GetModuleStructureDocument,
  useRemoveModuleFromHub,
} from 'graphql/gen.types';
import React from 'react';
import { useParams } from 'react-router-dom';

const HubModuleRemove = (props) => {
  const { hubId: hubModuleId } = useParams();

  const { moduleId, onClose, ...rest } = props;

  const [removeModuleFromHub, { loading }] = useRemoveModuleFromHub();

  const removeModuleFromHubInput = {
    hubModuleId: hubModuleId,
    moduleId: moduleId,
  };

  const removeItem = async () => {
    await removeModuleFromHub({
      variables: {
        input: removeModuleFromHubInput,
      },
      refetchQueries: [
        {
          query: GetModuleStructureDocument,
          variables: {
            moduleId: hubModuleId,
          },
        },
      ],
    });
    if (!loading) {
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...rest}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to remove this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={removeItem} loading={loading} size='medium'>
          Yes
        </LoadingButton>
        <Button variant='contained' onClick={onClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HubModuleRemove;
