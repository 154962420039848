import { useLazyQuery } from '@apollo/client';
import { rVarCMSApp, rVarSelectedContent } from 'global/const';
import { ModuleByIdDocument } from 'graphql/gen.types';
import _ from 'lodash';
import { createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const CMSMainContext = createContext(undefined);

export const LoadCMSAppContent = (moduleId, module) => {
  rVarCMSApp({
    moduleId: moduleId ? moduleId : undefined,
    module: module ? module : undefined,
  });
};

const emptySelectedModule = {
  moduleLoading: undefined,
  moduleError: undefined,
  module: undefined,
  refetch: () => {},
};

export const CMSMainProvider = ({ children }) => {
  const { id: moduleId, sectionSlug, contentBiteSlug } = useParams();

  const [
    getSelectedModule,
    { loading: moduleLoading, data: moduleData, error: moduleError, refetch },
  ] = useLazyQuery(ModuleByIdDocument);

  useEffect(() => {
    if (!_.isEmpty(moduleId)) {
      getSelectedModule({
        variables: { moduleId: moduleId },
        onCompleted: (data) => {
          if (data) {
            const { module } = data;
            LoadCMSAppContent(moduleId, module);
          }
        },
      }).catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [moduleId]);

  useEffect(() => {
    rVarSelectedContent({
      moduleId: moduleId,
      sectionId: sectionSlug ? sectionSlug : undefined,
      contentBiteId: contentBiteSlug ? contentBiteSlug : undefined,
    });
  }, [moduleId, sectionSlug, contentBiteSlug]);

  const contextValue = {
    selectedModule: moduleId
      ? {
          moduleLoading,
          moduleError,
          module: moduleData?.module,
          refetch: refetch,
        }
      : emptySelectedModule,
  };

  return (
    <CMSMainContext.Provider value={contextValue}>
      {children}
    </CMSMainContext.Provider>
  );
};

export const useCMSMainContext = () => useContext(CMSMainContext);
