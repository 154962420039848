import React, { memo, useEffect, useState } from 'react';
import {
  useGetVideoPosterFrameQuery,
  useMediaContentLazyQuery,
} from 'graphql/gen.types';
import { useEditCMSContext } from '../EditCmsContext';
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteContentBiteDialog from './DeleteContentBiteDialog';
import ReplayIcon from '@mui/icons-material/Replay';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideoBiteModal from '../cms_bites_modal/VideoBiteModal';
import { useVideoContext } from '../cms_context/VideoContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const VideoPosterComponent = ({ videoPosterFile, videoFullSize }) => {
  return (
    <>
      <img
        className='video-player cursor-pointer object-cover'
        src={videoPosterFile}
        alt=''
        style={{ ...videoFullSize }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          inset: 0,
          ...videoFullSize,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...videoFullSize,
          }}
        >
          <IconButton color='inherit'>
            <CircularProgress color='common' size={54} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const VideoBiteComponent = (props) => {
  const videoContentBite = props.contentBite;
  const { id, videoSize } = videoContentBite;

  const [deleteContentBiteModal, setDeleteContentBiteModal] = useState(false);

  const onCloseDialog = () => setDeleteContentBiteModal(false);

  const [videoEditModal, setVideoEditModal] = useState(false);

  const {
    setFile,
    setModalType,
    contentBiteId,
    deleteContentBiteLoading,
    deleteContentBite,
  } = useEditCMSContext();

  const {
    setVideoDescription,
    setDurationMillis,
    setVideoDimensions,
    setPreview,
    setPosterFrame,
  } = useVideoContext();

  const [videoContentFile, setVideoContentFile] = useState('');
  const [videoPosterFile, setVideoPosterFile] = useState(null);
  const [prevVideoData, setPrevVideoData] = useState({
    mediaContent: '',
    posterFile: '',
  });

  const [getMediaContent, { loading, error, data, refetch, networkStatus }] =
    useMediaContentLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const posterFrameQuery = useGetVideoPosterFrameQuery({
    variables: {
      videoBiteId: id,
    },
  });

  const videoFullSize = {
    width: '100%',
    height: '100%',
  };

  const closeModalType = () => {
    setModalType(null);
    setVideoEditModal(false);
    setFile('');
    setPreview('');
  };

  useEffect(() => {
    if (data) {
      setVideoContentFile(data?.mediaContent);
    }
  }, [data]);

  useEffect(() => {
    if (
      !posterFrameQuery.loading &&
      !posterFrameQuery.error &&
      posterFrameQuery
    ) {
      setVideoPosterFile(posterFrameQuery?.data?.posterFrame);
    }
  }, [posterFrameQuery.loading, posterFrameQuery.error, posterFrameQuery]);

  const videoSizeData = JSON.parse(videoSize);

  if (videoContentBite.__typename !== 'VideoBite') {
    return <p>Incorrect Bite Rendered</p>;
  }

  return (
    <>
      <Box
        className='video-bite-content'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          ...videoFullSize,
        }}
      >
        <Box
          sx={{
            ...videoFullSize,
          }}
        >
          {videoContentFile ? (
            <video
              style={{ ...videoFullSize }}
              className='video-player cursor-pointer object-cover'
              controls
              controlsList='nodownload'
              poster={videoPosterFile}
            >
              <source src={videoContentFile} />
            </video>
          ) : error ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                ...videoFullSize,
              }}
              className='video-player'
            >
              <IconButton color='inherit' onClick={() => refetch()}>
                <ReplayIcon color='common' />
              </IconButton>
            </Box>
          ) : networkStatus === 4 || loading ? (
            <VideoPosterComponent
              videoPosterFile={videoPosterFile}
              videoFullSize={videoFullSize}
            />
          ) : (
            <>
              <img
                className='video-player cursor-pointer object-cover'
                src={videoPosterFile}
                alt=''
                style={{ ...videoFullSize }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  inset: 0,
                  ...videoFullSize,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...videoFullSize,
                  }}
                >
                  <IconButton
                    color='inherit'
                    onClick={() =>
                      getMediaContent({
                        variables: {
                          contentBiteId: id?.toString(),
                        },
                      })
                    }
                  >
                    <PlayCircleIcon
                      sx={{
                        fontSize: '3.5rem',
                        color: videoPosterFile ? 'white' : 'black',
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </>
          )}
        </Box>

        <Box className='video-action-buttons' style={{ zIndex: '100' }}>
          <IconButton
            onClick={() => {
              props.handleEditItem();
              setModalType(null);
              setVideoEditModal(true);
              setVideoDescription(videoContentBite?.videoDescription);
              setDurationMillis(videoContentBite?.durationMillis);
              setVideoDimensions(videoSizeData);
              setPosterFrame(posterFrameQuery?.data?.posterFrame);
              setPrevVideoData({
                mediaContent: data?.mediaContent,
                posterFile: posterFrameQuery?.data?.posterFrame,
              });
            }}
            disableRipple
            sx={{ background: 'blue', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <EditIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            onClick={() => {
              props.handleDeleteItem();
              setDeleteContentBiteModal(true);
            }}
            disableRipple
            sx={{ background: 'red', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
        </Box>

        {deleteContentBiteModal && (
          <DeleteContentBiteDialog
            onCloseDialog={onCloseDialog}
            deleteContentBite={deleteContentBite}
            deleteContentBiteLoading={deleteContentBiteLoading}
          />
        )}

        {contentBiteId?.toString() === videoContentBite?.id?.toString() && (
          <VideoBiteModal
            open={videoEditModal}
            closeModalType={closeModalType}
            edit={true}
            prevVideoData={prevVideoData}
          />
        )}
      </Box>
    </>
  );
};

export default memo(VideoBiteComponent);
