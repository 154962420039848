import { useReactiveVar } from '@apollo/client';
import { rVarHasUsersAccessRole } from 'global/const';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const RoleBasedUI = ({ redirectPath = '/', children }) => {
  const { hasUsersAccessRole } = useReactiveVar(rVarHasUsersAccessRole);

  if (hasUsersAccessRole !== undefined && !hasUsersAccessRole) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
