/**
 * The right portion of the screen where options for creating all bites reside
 */
import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useEditCMSContext } from './EditCmsContext';
import {
  ID_CREATE_TEXT_BITE,
  ID_CREATE_IMAGE_BITE,
  ID_CREATE_AUDIO_BITE,
  ID_CREATE_VIDEO_BITE,
  ID_CREATE_YOUTUBE_VIDEO_BITE,
  CreateBiteList,
} from './Content_Bites_List';
import { Box, Button, Container } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideocamIcon from '@mui/icons-material/Videocam';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import NotesIcon from '@mui/icons-material/Notes';
import ImageIcon from '@mui/icons-material/Image';

const BiteView = (createBite) => {
  switch (createBite.id) {
    case ID_CREATE_TEXT_BITE:
      return (
        <Button
          size='large'
          variant='contained'
          fullWidth
          startIcon={<NotesIcon />}
        >
          {createBite.label}
        </Button>
      );
    case ID_CREATE_IMAGE_BITE:
      return (
        <Button
          size='large'
          variant='contained'
          fullWidth
          startIcon={<ImageIcon />}
        >
          {createBite.label}
        </Button>
      );
    case ID_CREATE_AUDIO_BITE:
      return (
        <Button
          size='large'
          variant='contained'
          fullWidth
          startIcon={<MusicNoteIcon />}
        >
          {createBite.label}
        </Button>
      );
    case ID_CREATE_VIDEO_BITE:
      return (
        <Button
          size='large'
          variant='contained'
          fullWidth
          startIcon={<VideocamIcon />}
        >
          {createBite.label}
        </Button>
      );
    case ID_CREATE_YOUTUBE_VIDEO_BITE:
      return (
        <Button
          size='large'
          variant='contained'
          fullWidth
          startIcon={<YouTubeIcon />}
        >
          {createBite.label}
        </Button>
      );
    default:
      break;
  }
};

const CreateContentBiteList = (props) => {
  const [createBites, setCreateBites] = useState(CreateBiteList);

  const { setTextBite, setShow, onCreateBite, setModalType } =
    useEditCMSContext();

  // Function to open the modal
  const openModal = () => {
    setShow(true);
  };

  // Create bite click handler
  const createBiteClickHandler = (e, createBite) => {
    if (createBite.id === ID_CREATE_TEXT_BITE) {
      openModal(); // Open the modal only for "Text" item
      e.preventDefault();
    } else {
      // Handle other item clicks here
      setModalType(createBite.id);
      onCreateBite(createBite);
      setTextBite('');
      e.preventDefault();
    }
  };

  // Create bite view
  const CreateBiteView = (createBite) => {
    return (
      <Box
        sx={{ py: 1.5 }}
        id={createBite.id}
        key={createBite.id}
        onClick={(e) => createBiteClickHandler(e, createBite)}
      >
        {BiteView(createBite)}
      </Box>
    );
  };

  return (
    <>
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '88vh',
          }}
        >
          <ReactSortable
            group={{ name: 'create-bite-list', put: false, pull: 'clone' }}
            list={createBites}
            setList={setCreateBites}
            sort={false}
            chosenClass='contentbite-chosen'
            direction='vertical'
            delay={200}
            animation={500}
            easing='cubic-bezier(0.22,1,0.36,1)'
          >
            {createBites.map((createBite) => {
              return CreateBiteView(createBite);
            })}
          </ReactSortable>
        </Box>
      </Container>
    </>
  );
};

export default CreateContentBiteList;
