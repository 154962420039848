import { createContext, useContext, useState } from 'react';

const CMSContext = createContext(undefined);

const CMSContextProvider = ({ children }) => {
  const [showSectionModal, setShowSectionModal] = useState('');
  const [displaySelectedCGId, setDisplaySelectedCGId] = useState(undefined);
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionId, setSectionId] = useState(null);
  const [deleteSectionModal, setDeleteSectionModal] = useState(false);
  const [showSectionThumbnailModal, setShowSectionThumbnailModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const CMSContextValue = {
    showSectionModal,
    setShowSectionModal,
    displaySelectedCGId,
    setDisplaySelectedCGId,
    sectionTitle,
    setSectionTitle,
    sectionId,
    setSectionId,
    deleteSectionModal,
    setDeleteSectionModal,
    showSectionThumbnailModal,
    setShowSectionThumbnailModal,
    searchQuery,
    setSearchQuery,
  };

  return (
    <CMSContext.Provider value={CMSContextValue}>
      {children}
    </CMSContext.Provider>
  );
};

const useCMSContext = () => {
  return useContext(CMSContext);
};

export { CMSContext, CMSContextProvider, useCMSContext };
