import * as React from 'react';
import { Box, IconButton, ListItemButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useCMSContext } from './CMSContext';
import { useNavigate, useParams } from 'react-router-dom';
import { SimpleTreeItemWrapper } from 'dnd-kit-sortable-tree';
import { SectionMenuSetting, SectionMenuSubItem } from './SectionComponent';

/*
 * Here's the component that will render a single row of your tree
 */
export const TreeItem = React.forwardRef((props, ref) => {
  const { id: moduleId } = useParams();

  const { item } = props;

  const {
    sectionId,
    setSectionId,
    setSectionTitle,
    displaySelectedCGId,
    setDisplaySelectedCGId,
  } = useCMSContext();

  const navigate = useNavigate();

  const onSectionSelected = (id) => {
    setDisplaySelectedCGId(id);
    navigate(`/cms/${moduleId}/${id}`);
  };

  const [anchorElSetting, setAnchorElSetting] = React.useState(null);
  const [anchorElSection, setAnchorElSection] = React.useState(null);

  const handleOpenSection = (event) => {
    setAnchorElSection(event.currentTarget);
  };

  const handleCloseSection = () => {
    setAnchorElSection(null);
    setSectionId(null);
  };

  const handleOpenSettingMenu = (event) => {
    setAnchorElSetting(event.currentTarget);
  };

  const handleCloseSettingMenu = () => {
    setAnchorElSetting(null);
  };

  return (
    <>
      <ListItemButton
        selected={displaySelectedCGId === item?.id}
        className={`tree-section-node `}
        sx={{
          position: 'relative',
          width: '100%',
          px: 3,
          py: 2,
          wordBreak: 'break-all',
        }}
        id={item?.id}
        onClick={() => onSectionSelected(item?.id)}
      >
        <SimpleTreeItemWrapper
          disableCollapseOnItemClick={true}
          showDragHandle={false}
          {...props}
          ref={ref}
        >
          <Typography className='line-clamp-2'>{item?.title}</Typography>
        </SimpleTreeItemWrapper>
        <Box
          className='tree-section-node-item'
          style={{
            position: 'absolute',
            top: '50%',
            display: 'flex',
            alignItems: 'center',
            transform: 'translate(-50%, -50%)',
            right: 0,
          }}
        >
          <IconButton
            onClick={(e) => {
              handleOpenSettingMenu(e);
              setSectionId(item?.id);
              setSectionTitle(item?.title);
              e.stopPropagation();
            }}
            color='inherit'
          >
            <MoreHorizIcon />
          </IconButton>
        </Box>
      </ListItemButton>

      {sectionId && (
        <SectionMenuSetting
          anchorElSetting={anchorElSetting}
          handleCloseSettingMenu={handleCloseSettingMenu}
          handleOpenSection={handleOpenSection}
        />
      )}

      {anchorElSection && (
        <SectionMenuSubItem
          anchorElSection={anchorElSection}
          handleCloseSection={handleCloseSection}
        />
      )}
    </>
  );
});
/*
 * Configure the tree data.
 */
