/**
 * The header which appears at the top of the screen inside the CMS
 * This component assumes that its parent has made the query to fetch
 * complete app details and that the data is cached. So, when this component
 * requests the data it needs, it would be fetched from the local cache.
 * */
import { useReactiveVar } from '@apollo/client';
import KnidalLogo from 'assets/knidal-logo-white.svg';
import {
  ADMIN_CONSOLE_MODAL_SELECTION,
  CMS_MODAL_SELECTION,
  rVarAdminConsoleModalContext,
  rVarCMSApp,
  rVarCMSModalContext,
  rVarHasUsersAccessRole,
  rVarHasModuleAccessRoles,
} from 'global/const';
import React, { useState } from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Button,
  Avatar,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import ModuleInfo from 'components/admin_console/module_dashboard/ModuleInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCMSContext } from './CMSContext';

const CMSHeader = () => {
  const selectedModule = useReactiveVar(rVarCMSApp);
  const { hasUsersAccessRole } = useReactiveVar(rVarHasUsersAccessRole);
  const { hasModuleAccessRoles } = useReactiveVar(rVarHasModuleAccessRoles);
  const [editInfoModuleId, setEditInfoModuleId] = useState(undefined);

  const { searchQuery, setSearchQuery } = useCMSContext();

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();

  return (
    <>
      <AppBar sx={{ zIndex: 1000 }} color='default'>
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{ width: '64px', height: '64px', cursor: 'pointer' }}
                onClick={() => navigate('/')}
              >
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={KnidalLogo}
                  alt='Knidal Systems logo'
                  id='header-logo-home'
                />
              </Avatar>
              <Typography
                variant='h6'
                component='div'
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', sm: 'block' },
                  ml: 2,
                }}
              >
                {selectedModule?.module?.name}
              </Typography>
            </Box>
            <Box>
              <div className='search-box'>
                <input
                  className='search-input'
                  type='text'
                  placeholder={`Find in ${selectedModule?.module?.name}`}
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
                <button className='search-btn'>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </Box>
            <Box>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1 }}>
                {hasUsersAccessRole || hasModuleAccessRoles ? (
                  <Button
                    variant='contained'
                    onClick={() =>
                      rVarCMSModalContext({
                        selection: CMS_MODAL_SELECTION.EDIT_ACCESS,
                      })
                    }
                  >
                    Manage Access
                  </Button>
                ) : null}

                <Button
                  variant='contained'
                  onClick={() =>
                    setEditInfoModuleId(selectedModule?.module?.id)
                  }
                >
                  Edit Module Info
                </Button>

                <Button variant='contained'>Publish</Button>

                {/* Logout Button Start */}
                <Tooltip arrow title='Logout' placement='top'>
                  <Box>
                    <IconButton
                      color='inherit'
                      onClick={() =>
                        rVarAdminConsoleModalContext({
                          selection: ADMIN_CONSOLE_MODAL_SELECTION.LOG_OUT,
                        })
                      }
                    >
                      <LogoutIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
                {/* Logout Button End */}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />

      {editInfoModuleId && (
        <ModuleInfo
          open={true}
          onClose={() => setEditInfoModuleId(undefined)}
          moduleId={editInfoModuleId}
        />
      )}
    </>
  );
};

export default CMSHeader;
