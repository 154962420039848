/* eslint-disable no-useless-escape */
import {
  useMutatePosterFrame,
  useAddVideoBite,
  useUpdateDescriptionQuery,
  useUpdateVideoBite,
} from 'graphql/gen.types';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEditCMSContext } from '../EditCmsContext';

const VideoContext = createContext(undefined);

const VideoContextProvider = ({ children }) => {
  const { id: moduleId } = useParams();
  const { contentBiteId, contentBiteIndex, selectedSection } =
    useEditCMSContext();

  const [videoDescription, setVideoDescription] = useState('');
  const [durationMillis, setDurationMillis] = useState(0);
  const [videoDimensions, setVideoDimensions] = useState({});
  const [posterFrame, setPosterFrame] = useState('');
  const [preview, setPreview] = useState('');

  const mutateVideoBiteInput = {
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    videoDescription: videoDescription,
    video: preview,
    durationMillis: parseInt(durationMillis),
    videoSize: JSON.stringify(videoDimensions),
    disableAudio: false,
    loop: false,
    index: contentBiteIndex,
  };

  const updateVideoBiteInput = {
    id: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    video: preview,
    durationMillis: parseInt(durationMillis),
    videoSize: JSON.stringify(videoDimensions),
    disableAudio: false,
    loop: false,
  };

  const updateMediaDescriptionInput = {
    contentBiteId: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    description: videoDescription,
  };

  const [
    executeVideoBite,
    { error: videoBiteError, loading: videoBiteLoading, data: videoBiteData },
  ] = useAddVideoBite({
    variables: {
      input: mutateVideoBiteInput,
    },
  });

  const [
    updateVideoBite,
    { error: updateVideoBiteError, loading: updateVideoBiteLoading },
  ] = useUpdateVideoBite({
    variables: {
      input: updateVideoBiteInput,
    },
  });

  const [
    updateMediaDescription,
    { error: updateDescriptionError, loading: updateDescriptionLoading },
  ] = useUpdateDescriptionQuery({
    variables: {
      input: updateMediaDescriptionInput,
    },
  });

  const mutatePosterFrameInput = {
    videoBiteId: contentBiteId ? contentBiteId : null,
    moduleId: moduleId,
    sectionId: selectedSection?.id,
    posterFrame: posterFrame,
  };

  const [
    executePosterFrame,
    { loading: posterFrameLoading, error: posterFrameError },
  ] = useMutatePosterFrame({
    variables: {
      input: mutatePosterFrameInput,
    },
  });

  const resetVideoData = () => {
    setVideoDescription('');
    setDurationMillis(0);
    setVideoDimensions({});
    setPosterFrame('');
    setPreview('');
  };

  const videoContextValue = {
    videoDescription,
    setVideoDescription,
    durationMillis,
    setDurationMillis,
    videoDimensions,
    setVideoDimensions,
    preview,
    setPreview,
    videoBiteLoading,
    videoBiteError,
    videoBiteData,
    executeVideoBite,
    updateVideoBiteError,
    updateVideoBiteLoading,
    updateVideoBite,
    updateDescriptionError,
    updateDescriptionLoading,
    updateMediaDescription,
    resetVideoData,
    posterFrame,
    setPosterFrame,
    posterFrameLoading,
    posterFrameError,
    executePosterFrame,
  };

  return (
    <VideoContext.Provider value={videoContextValue}>
      {children}
    </VideoContext.Provider>
  );
};

const useVideoContext = () => {
  return useContext(VideoContext);
};

export { VideoContext, VideoContextProvider, useVideoContext };
