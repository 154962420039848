/* Dashboard component renders the Module Dashboard. The page where users land after logging in */
import React from 'react';
import NewModule from './NewModule';
import SearchBar from 'components/SearchBar/SearchBar';
import Select, { components } from 'react-select';
import { useAppContext } from 'context/AppContext';
import { Box, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { rVarHasUsersAccessRole } from 'global/const';

const filteredOptions = [
  { value: 'single', label: 'Single' },
  { value: 'hub', label: 'Hub' },
];

const sortByOptions = [
  { type: 'ascending', value: 'asc-name', label: '↑ Name' },
  { type: 'descending', value: 'desc-name', label: '↓ Name' },
  { type: 'ascending', value: 'asc-createdAt', label: '↑ Created' },
  { type: 'descending', value: 'desc-createdAt', label: '↓ Created' },
];

const { Option } = components;
const IconOption = (props) => <Option {...props}>{props.data.label}</Option>;

//Extra Columns are created to for the proper alignment
const ModuleDashboardHeader = () => {
  const {
    searchTerm,
    setSearchTerm,
    filteredOption,
    setFilteredOption,
    sortByOption,
    setSortByOption,
  } = useAppContext();

  const { hasUsersAccessRole } = useReactiveVar(rVarHasUsersAccessRole);

  return (
    <>
      <Box>
        <Typography variant='h4'>Module Dashboard</Typography>
      </Box>
      <div className='d-flex justify-content-between align-items-center my-4'>
        <div>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
        <div className='d-flex align-items-center'>
          <div
            style={{
              minWidth: '144px',
              maxWidth: '144px',
              marginInlineEnd: '32px',
            }}
          >
            <Select
              key='select-section-variants'
              className='basic-multi-select'
              defaultValue={filteredOption}
              isClearable={true}
              isSearchable={false}
              options={filteredOptions}
              classNamePrefix='select'
              placeholder='Filter'
              onChange={setFilteredOption}
            />
          </div>
          <div style={{ minWidth: '172px', maxWidth: '172px' }}>
            <Select
              key='select-section-variants'
              className='basic-multi-select'
              defaultValue={sortByOption}
              isClearable={true}
              isSearchable={false}
              options={sortByOptions}
              classNamePrefix='select'
              placeholder='Sort By'
              onChange={setSortByOption}
              components={{ Option: IconOption }}
            />
          </div>

          {hasUsersAccessRole ? (
            <Box sx={{ pl: 4 }}>
              <NewModule />
            </Box>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default ModuleDashboardHeader;
