import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SignInErrorDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const SignInError = ({ onCloseDialog, errorMessage }) => {
  return (
    <>
      <SignInErrorDialog
        onClose={onCloseDialog}
        aria-labelledby='customized-dialog-title'
        open={true}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Error
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography align='center' gutterBottom>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <MuiButton variant='text' onClick={onCloseDialog}>
            Close
          </MuiButton>
        </DialogActions>
      </SignInErrorDialog>
    </>
  );
};

export default SignInError;
