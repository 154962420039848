import { useReactiveVar } from '@apollo/client';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { rVarCMSApp } from 'global/const';
import { useMutateAddSection } from 'graphql/gen.types';
import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import CloseIcon from '@mui/icons-material/Close';
import { Check } from '@mui/icons-material';
import { scrollIntoView } from 'components/utils/CommonFunction';

const SectionModal = React.forwardRef(
  ({ onCloseDialog, showSectionModal }, ref) => {
    const { moduleId } = useReactiveVar(rVarCMSApp);

    const inputRef = useRef(null);

    useOnClickOutside(ref, () => onCloseDialog());

    const [title, setTitle] = useState('');

    const addSectionInput = {
      title: title,
      moduleId: moduleId,
    };

    const [addSection, { error: sectionError, loading: sectionLoading }] =
      useMutateAddSection();

    const addSectionItem = async () => {
      await addSection({
        variables: {
          input: addSectionInput,
        },
        onCompleted: () => {
          onCloseDialog();
          resetTitle();
        },
      });
    };

    // Function to focus on the input when a button is clicked
    const focusInput = () => {
      inputRef.current.focus();
    };

    useEffect(() => {
      scrollIntoView('create-section-and-subsection');
      focusInput();
    }, []);

    if (sectionError) {
      return <Alert variant='danger'>Error performing network request</Alert>;
    }

    const resetTitle = () => {
      setTitle('');
    };

    const handleCreateSection = () => {
      if (title) {
        if (showSectionModal === 'section') {
          addSectionItem();
        }
      }
    };

    return (
      <>
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: 1,
            py: 0.5,
          }}
          id='create-section-and-subsection'
        >
          <TextField
            fullWidth
            label={
              showSectionModal === 'section'
                ? 'Section Title'
                : 'SubSection Title'
            }
            inputRef={inputRef}
            size='medium'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                handleCreateSection();
                ev.preventDefault();
              } else if (ev.key === 'Escape') {
                resetTitle();
                ev.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: sectionLoading ? (
                <InputAdornment position='end'>
                  <CircularProgress
                    color='inherit'
                    size='40px'
                    sx={{ padding: '8px' }}
                  />
                </InputAdornment>
              ) : title ? (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='submit changes'
                    onClick={handleCreateSection}
                  >
                    <Check />
                  </IconButton>
                  <IconButton aria-label='cancel changes' onClick={resetTitle}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Box>
      </>
    );
  },
);

export default SectionModal;
