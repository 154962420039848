import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import { useAudioContext } from '../cms_context/AudioContext';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { allowedAudioFormats } from 'global/const';
import { gql, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { Check } from '@mui/icons-material';
import { GetMediaContent } from 'graphql/gen.types';

const SPECIFIC_CONTENT_BITE_DOCUMENT = gql`
  query ContentBite($id: ID!) {
    contentBite(id: $id) {
      ... on AudioBite {
        id
        createdAt
        audioDescription
        durationMillis
      }
    }
  }
`;

const AudioBiteModal = ({ closeModalType, open, edit }) => {
  const { setFile, contentBiteId, setContentBiteId } = useEditCMSContext();

  const {
    preview,
    setPreview,
    setDurationMillis,
    audioDescription,
    setAudioDescription,
    audioBiteError,
    audioBiteLoading,
    executeAudioBite,
    updateAudioBiteError,
    updateAudioBiteLoading,
    updateAudioBite,
    updateDescriptionError,
    updateDescriptionLoading,
    updateMediaDescription,
    resetAudioData,
  } = useAudioContext();

  const [isFileUploadError, setIsFileUploadError] = useState('');
  const [audioFile, setAudioFile] = useState('');
  const [isPrevAudioData, setIsPrevAudioData] = useState({
    preview: '',
    audioDescription: '',
    durationMillis: '',
  });
  const fileInputRef = React.createRef();

  const [getAudioBite, { data, loading }] = useLazyQuery(
    SPECIFIC_CONTENT_BITE_DOCUMENT,
    {
      variables: {
        id: contentBiteId,
      },
    },
  );

  const [
    getAudioMediaContent,
    { data: mediaContentData, loading: mediaContentLoading },
  ] = useLazyQuery(GetMediaContent, {
    variables: {
      contentBiteId: contentBiteId,
    },
  });

  const handleOnAudioChange = (e) => {
    e.preventDefault();
    const audioFile = e.target.files[0];

    if (!audioFile) {
      return;
    }

    setAudioFile(window.URL.createObjectURL(audioFile));

    if (!allowedAudioFormats.includes(audioFile.type)) {
      setIsFileUploadError('Invalid file format. Accepted formats: MP3, OGG');
    } else if (audioFile.size > 12000000) {
      setIsFileUploadError('File size exceeds 12MB limit');
    } else {
      setFile(audioFile);
      setIsFileUploadError('');
      const fileReader = new FileReader();
      fileReader.onloadend = function () {
        setPreview(fileReader.result);
        const audio = new Audio(fileReader.result);
        audio.onloadedmetadata = function () {
          setDurationMillis(audio?.duration * 1000);
          // console.log(audio.duration * 1000, 'audioElement');
        };
      };

      fileReader.readAsDataURL(audioFile);
    }
  };

  const hasAudioFieldChange = () => {
    return (
      data &&
      (!_.eq(audioDescription, isPrevAudioData.audioDescription) ||
        !_.eq(data.mediaContent, isPrevAudioData.preview) ||
        (audioFile && !_.eq(data.mediaContent, preview)))
    );
  };

  const hasAudioDescriptionChange = () => {
    return !_.eq(audioDescription, isPrevAudioData.audioDescription);
  };

  const resetAudioDescription = () => {
    const { audioDescription } = isPrevAudioData;
    setAudioDescription(audioDescription);
  };

  const resetData = () => {
    const { preview, durationMillis } = isPrevAudioData;
    setPreview(preview);
    setDurationMillis(durationMillis);
    setAudioFile('');
  };

  const emptyData = () => {
    resetAudioData();
    setIsPrevAudioData({
      preview: '',
      audioDescription: '',
      durationMillis: '',
    });
    setPreview('');
    setContentBiteId('');
    setAudioDescription('');
    setAudioFile('');
  };

  const addAudioContentBite = async () => {
    try {
      await executeAudioBite({
        onCompleted: () => {
          closeModalType();
          emptyData();
        },
      });
    } catch (error) {
      console.log('addAudioContentBite error', error);
    }
  };

  const updateAudioContentBite = async () => {
    try {
      await updateAudioBite({
        onCompleted: () => {
          closeModalType();
          emptyData();
        },
      });
    } catch (error) {
      console.log('updateAudioContentBite error', error);
    }
  };

  const updateAudioDescription = async () => {
    try {
      await updateMediaDescription({
        onCompleted: () => {
          closeModalType();
          emptyData();
        },
      });
    } catch (error) {
      console.log('updateAudioDescription error', error);
    }
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const maxDimension = 600;

  useEffect(() => {
    if (!edit) {
      emptyData();
    } else if (contentBiteId) {
      getAudioBite();
      getAudioMediaContent();
      if (!loading && data) {
        const {
          contentBite: { audioDescription, durationMillis },
        } = data;
        setIsPrevAudioData({
          audioDescription: audioDescription,
          durationMillis: durationMillis,
        });
      }
      if (!mediaContentLoading && mediaContentData) {
        const { mediaContent } = mediaContentData;
        setIsPrevAudioData((prevAudio) => ({
          ...prevAudio,
          preview: mediaContent,
        }));
        setPreview(mediaContent);
      }
    }
    // eslint-disable-next-line
  }, [edit, data, loading, mediaContentLoading, mediaContentData]);

  return (
    <>
      <Dialog maxWidth='md' fullWidth open={open} onClose={closeModalType}>
        <DialogTitle>
          <Typography variant='h4' component='span'>
            {edit ? 'Update Audio' : 'Add Audio'}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={closeModalType}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          className='d-flex align-items-center justify-content-center flex-column'
        >
          <DialogContentText
            sx={{ maxWidth: `${maxDimension}px`, width: '100%' }}
          >
            {audioBiteError ||
            updateAudioBiteError ||
            updateDescriptionError ? (
              <Alert severity='error'>
                Oops, something went wrong. Please try again later.
              </Alert>
            ) : null}
          </DialogContentText>
          {loading ? (
            <CircularProgress />
          ) : (
            <div id='cms_file'>
              <div className='container'>
                <div className='file-input-box'>
                  {isFileUploadError && (
                    <Typography
                      variant='body1'
                      sx={{ my: 1 }}
                      style={{ color: '#d10000' }}
                    >
                      {isFileUploadError}
                    </Typography>
                  )}

                  {audioFile || preview ? (
                    <Box
                      sx={{
                        width: maxDimension,
                        my: 2,
                      }}
                    >
                      <audio
                        style={{ width: '100%' }}
                        src={audioFile ? audioFile : preview}
                        controls={true}
                        controlsList='nodownload'
                      />
                    </Box>
                  ) : null}

                  <div className='wrapper-file-input'>
                    {audioFile || preview ? (
                      <TextField
                        sx={{ marginBottom: '16px', marginTop: '0px' }}
                        label='Audio Description'
                        margin='normal'
                        fullWidth
                        size='medium'
                        value={audioDescription}
                        onChange={(e) => setAudioDescription(e.target.value)}
                        onKeyDown={(ev) => {
                          if (edit && hasAudioDescriptionChange()) {
                            if (ev.key === 'Enter') {
                              updateAudioDescription();
                              ev.preventDefault();
                            } else if (ev.key === 'Escape') {
                              resetAudioDescription();
                              ev.preventDefault();
                            }
                          }
                        }}
                        InputProps={{
                          endAdornment: updateDescriptionLoading ? (
                            <InputAdornment position='end'>
                              <CircularProgress
                                color='inherit'
                                size='40px'
                                sx={{ padding: '8px' }}
                              />
                            </InputAdornment>
                          ) : edit && hasAudioDescriptionChange() ? (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={updateAudioDescription}
                                aria-label='submit changes'
                              >
                                <Check />
                              </IconButton>
                              <IconButton
                                onClick={resetAudioDescription}
                                aria-label='cancel changes'
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                        }}
                      />
                    ) : null}
                    <div className='input-box' onClick={openFileInput}>
                      <h4>
                        <i className='fa-solid fa-upload'></i>
                        Choose File to upload
                      </h4>
                      <input
                        ref={fileInputRef}
                        hidden
                        id='file'
                        type='file'
                        accept={allowedAudioFormats}
                        onChange={handleOnAudioChange}
                      />
                    </div>
                    <small>Files Supported: MP3, OGG</small>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          {edit ? (
            <>
              {hasAudioFieldChange() && (
                <Button onClick={resetData}>Reset</Button>
              )}
              <LoadingButton
                disabled={!(edit && hasAudioFieldChange())}
                loading={updateAudioBiteLoading}
                onClick={updateAudioContentBite}
                variant='contained'
              >
                Save
              </LoadingButton>
            </>
          ) : (
            <LoadingButton
              disabled={!audioFile}
              loading={audioBiteLoading}
              onClick={addAudioContentBite}
              variant='contained'
            >
              Add
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AudioBiteModal;
