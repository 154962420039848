import _ from 'lodash';
import { loader } from 'graphql.macro';
import {
  ObjectizeProductVariants,
  ObjectizeVariantFieldDefinitions,
} from 'global/VariantUtils';

export const ClientSchema = loader('./schema/titan.schema.graphql');

export const typePolicyMap = {
  ModuleContentInfo: {
    fields: {
      // Product variants need to be sanitized for mock data
      productVariants: {
        read(_existingProductVariants) {
          const _newProductVariants = _.cloneDeep(_existingProductVariants);
          return sanitizeVariants(_newProductVariants);
        },
      },
      // Client only field. Should always be queried with @client directive in the request's
      // selection set
      objectizedProductVariants: {
        read(existing, options) {
          const productVariants = options.readField('productVariants');
          return ObjectizeProductVariants(productVariants);
        },
      },
    },
  },
  ModuleAdminInfo: {
    fields: {
      // VariantFieldsDefinition needs to be santized for mock data
      variantFieldsDefinition: {
        read(_existingVariantFieldsDefinition) {
          const _newVariantFieldsDefinition = _.cloneDeep(
            _existingVariantFieldsDefinition,
          );
          return sanitizeVariantDefinition(_newVariantFieldsDefinition);
        },
      },
      objectizedVariantFieldsDefinition: {
        read(existing, options) {
          const variantFieldsDefinition = options.readField(
            'variantFieldsDefinition',
          );
          return ObjectizeVariantFieldDefinitions(variantFieldsDefinition);
        },
      },
    },
  },
  ModuleSection: {
    fields: {
      // variants need to be sanitized for mock data
      productVariants: {
        read(_existingVariants) {
          const _newVariants = _.cloneDeep(_existingVariants);
          return sanitizeVariants(_newVariants);
        },
      },
      // Client only field. Should always be queried with @client directive in the request's
      // selection set
      objectizedVariants: {
        read(existing, options) {
          const variants = options.readField('productVariants');
          return ObjectizeProductVariants(variants);
        },
      },
    },
  },
};

// Sanitize variantDefinition
const sanitizeVariantDefinition = function (fieldDefinition) {
  fieldDefinition.forEach((field, i) => {
    if (i === 0) {
      field.fieldName = 'Product Name';
    } else {
      field.fieldName = 'Product Version';
    }
  });
  return fieldDefinition;
};

// Sanitize the product variants data (ONLY FOR DATA RETURNED FROM MOCK SERVER)
const sanitizeVariants = function (variants) {
  variants.forEach((item) => {
    item.variant.forEach((variantPart, i) => {
      if (i === 0) {
        variantPart.fieldName = 'Product Name';
      } else {
        variantPart.fieldName = 'Product Version';
      }
    });
  });
  return _.uniqWith(variants, _.isEqual);
};
