import { resetPassword } from 'aws-amplify/auth';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Box, Container, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CompanyLogo from '../../Common/CompanyLogo';
import MDLoadingButton from '../MDLoadingButton';
import Title from '../Title';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email Required'),
});

const initialValues = { email: '', password: '' };

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
        );
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case 'DONE':
        console.log('Successfully reset password.');
        break;
      default:
        break;
    }
  };

  // Send confirmation code to user's email
  const forgotPassword = async (email) => {
    try {
      setIsLoading(true);
      const output = await resetPassword({ username: email });
      handleResetPasswordNextSteps(output);
      localStorage.setItem('email', email);
      navigate('/reset-password');
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              const { email } = values;
              await forgotPassword(email);
            } catch (error) {
              console.log(' ~ error:', error);
            }
          }}
          validationSchema={ForgotPasswordSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <Box
                component='form'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                }}
                onSubmit={handleSubmit}
              >
                <Box
                  sx={{
                    minWidth: '304px',
                    maxWidth: '372px',
                    py: 2,
                    px: 1,
                  }}
                >
                  <CompanyLogo />

                  <Title>Forgot Password</Title>

                  <Typography sx={{ mt: 2 }}>
                    Enter your email below and we will send a reset code
                  </Typography>

                  <TextField
                    error={errors.email && touched.email}
                    variant='standard'
                    fullWidth
                    margin='dense'
                    color='common'
                    name='email'
                    id='email'
                    type='text'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Email ID'
                    autoComplete='off'
                    helperText={errors.email && touched.email && errors.email}
                  />
                  <Container
                    sx={{
                      height: '8px',
                    }}
                  />
                  <MDLoadingButton loading={isLoading}>
                    Send Reset Code
                  </MDLoadingButton>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default ForgotPassword;
