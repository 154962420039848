import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleInfo from 'components/admin_console/module_dashboard/ModuleInfo';
import React, { useEffect, useMemo, useState } from 'react';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  MeDocument,
  useModuleIconQuery,
  useModuleListingQuery,
} from 'graphql/gen.types';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useAppContext } from 'context/AppContext';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  StyledTableCell,
  StyledTableRow,
} from 'components/Common/StyledTableComponent';
import ModuleDashboardHeader from './ModuleDashboardHeader';

let PageSize = 10;

const TableData = ({ tableData, setViewInfoModuleId }) => {
  const navigate = useNavigate();
  const { loading, data, error, refetch } = useModuleIconQuery({
    variables: {
      moduleId: tableData?.id?.toString(),
    },
  });

  useEffect(() => {
    if (error) {
      refetch();
    }
  }, [error, refetch]);

  return (
    <StyledTableRow>
      <StyledTableCell align='center'>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '64px',
                height: '64px',
                borderRadius: '16px',
                background: '#eeeeee',
              }}
            />
          </Box>
        ) : (
          <img
            src={data?.moduleIcon}
            style={{
              width: '64px',
              height: '64px',
              borderRadius: '16px',
              objectFit: 'cover',
            }}
            alt={tableData?.name}
          />
        )}
      </StyledTableCell>
      <StyledTableCell align='left'>{tableData?.name}</StyledTableCell>
      <StyledTableCell align='center'>
        {_.upperFirst(tableData?.moduleType.toLowerCase())}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {new Date(tableData?.createdAt).toLocaleString()}
      </StyledTableCell>
      <StyledTableCell align='center'>
        <Box>
          <IconButton
            onClick={() => setViewInfoModuleId(tableData?.id)}
            color='inherit'
          >
            <FontAwesomeIcon style={{ fontSize: 'large' }} icon={faEye} />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate(
                tableData?.moduleType === 'HUB'
                  ? _.join(['/hub/', tableData?.id], '')
                  : _.join(['/cms/', tableData?.id], ''),
              )
            }
            color='inherit'
          >
            <FontAwesomeIcon style={{ fontSize: 'large' }} icon={faPencilAlt} />
          </IconButton>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const ModuleDashboardTable = () => {
  // A state object to show/hide view/edit options
  const [viewInfoModuleId, setViewInfoModuleId] = useState(undefined);
  const [displayData, setDisplayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const { searchTerm, filteredOption, sortByOption } = useAppContext();

  const client = useApolloClient();

  const user = client.readQuery({
    query: MeDocument,
  });

  const accountId = user?.me?.account?.id || ''; // If it's undefined, accountId will be an empty string

  // Fetch the query
  const { loading, error, data } = useModuleListingQuery({
    displayName: 'ModuleDashboardTable',
    variables: {
      accountId: accountId,
    },
    fetchPolicy: 'cache-and-network',
  });

  // useMemo hook to memoize response data every time it changes
  const dataSet = useMemo(() => {
    // Check if data is undefined or not in the expected format
    if (!data || !Array.isArray(data.modules)) {
      return []; // Return an empty array or handle it appropriately
    }
    if (user) {
      if (user?.me?.accountRole === 'MEMBER') {
        const {
          me: { moduleAccessList },
        } = user;

        if (
          !moduleAccessList ||
          !Array.isArray(moduleAccessList) ||
          _.isEmpty(moduleAccessList)
        ) {
          return []; // Return an empty array or handle it appropriately
        }

        // Check if moduleData and moduleAccess arrays are not empty
        if (data?.modules.length > 0 && moduleAccessList.length > 0) {
          // Filter modules based on conditions
          const filteredModules = data?.modules.filter((module) => {
            const matchingAccess = moduleAccessList.find(
              (access) => access.moduleId === module.id,
            );
            return (
              matchingAccess &&
              (matchingAccess.moduleRole === 'WRITE' ||
                matchingAccess.moduleRole === 'ADMIN')
            );
          });

          return filteredModules;
        }
      } else {
        return data.modules;
      }
    }
  }, [data, user]);

  useEffect(() => {
    // Filter, sort, and paginate the data when the search term, moduleType, or sortByOption changes
    const filteredData = computeDisplayData(
      searchTerm?.toLowerCase(),
      filteredOption?.value,
      sortByOption,
    );

    setDisplayData(filteredData);
    setCurrentPage(1); // Reset to the first page when filters change
    // eslint-disable-next-line
  }, [data, searchTerm, filteredOption, sortByOption]);

  const computeDisplayData = (searchTerm, moduleType, sortByOption) => {
    let finalDisplayData = [...dataSet];

    // Filter on moduleType
    if (moduleType) {
      // Filter by moduleType when filteredOption is present
      finalDisplayData = finalDisplayData.filter(
        (obj) => obj?.moduleType?.toLowerCase() === moduleType,
      );
    }

    // Filter on searchTerm
    if (searchTerm) {
      // Filter by name when searchTerm is present
      const searchTermLower = searchTerm.toLowerCase();
      finalDisplayData = finalDisplayData.filter((obj) =>
        obj.name.toLowerCase().includes(searchTermLower),
      );
    }

    // Sort based on the selected option
    if (sortByOption?.value === 'asc-name') {
      // Sort by name
      let field = 'name';
      finalDisplayData = finalDisplayData.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString()),
      );
    } else if (sortByOption?.value === 'desc-name') {
      // Sort by name
      let field = 'name';
      finalDisplayData = finalDisplayData.sort((a, b) =>
        (b[field] || '').toString().localeCompare((a[field] || '').toString()),
      );
    } else if (sortByOption?.value === 'asc-createdAt') {
      // Sort by date
      finalDisplayData = finalDisplayData.sort((a, b) =>
        new Date(a.createdAt)
          ?.toISOString()
          .localeCompare(new Date(b.createdAt)?.toISOString()),
      );
    } else if (sortByOption?.value === 'desc-createdAt') {
      // Sort by date
      finalDisplayData = finalDisplayData.sort((a, b) =>
        new Date(b.createdAt)
          ?.toISOString()
          .localeCompare(new Date(a.createdAt)?.toISOString()),
      );
    }

    return finalDisplayData;
  };

  // When Loading is true, show a loading view
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // When Loading is false & data is available, display the data in the table
  if (error) {
    console.log(error);
    return <Alert severity='error'>Error performing network request.</Alert>;
  }

  const computePagination = (data, currentPage, pageSize) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  };

  const currentData = computePagination(displayData, currentPage, PageSize);
  const totalCount = displayData.length;
  const totalPageCount = Math.ceil(totalCount / PageSize);

  return (
    <Box sx={{ px: '24px' }}>
      <ModuleDashboardHeader />

      {!loading && displayData && displayData?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 264px)',
          }}
        >
          <Typography variant='h5'>No Results Found</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell width='96px' align='center'>
                  Icon
                </StyledTableCell>
                <StyledTableCell width='200px' align='left'>
                  Name
                </StyledTableCell>
                <StyledTableCell width='128px' align='center'>
                  Type
                </StyledTableCell>
                <StyledTableCell width='200px' align='center'>
                  Date Created
                </StyledTableCell>
                <StyledTableCell width='128px' align='center'>
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData?.map((data) => {
                return (
                  <TableData
                    key={data?.id}
                    tableData={data}
                    setViewInfoModuleId={setViewInfoModuleId}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {displayData?.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'end', my: 2 }}>
          <Pagination
            count={totalPageCount}
            page={currentPage}
            boundaryCount={2}
            onChange={handleChange}
          />
        </Box>
      )}

      {viewInfoModuleId && (
        <ModuleInfo
          open={true}
          onClose={() => setViewInfoModuleId(undefined)}
          moduleId={viewInfoModuleId}
        />
      )}
    </Box>
  );
};

export default ModuleDashboardTable;
