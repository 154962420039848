import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ModuleDashboardTable from 'components/admin_console/module_dashboard/ModuleDashboardTable';
import { MeProvider } from 'context/meContext';
import { ProtectedRoute } from 'router/ProtectedRoute';
import UserDashboardTable from 'components/admin_console/user_dashboard/UserDashboardTable';
import { Outlet } from 'react-router-dom';
import HubModule from 'components/HubModule/HubModule';
import CompanySettingComponent from 'components/admin_console/company_settings/CompanySettingComponent';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import { RoleBasedUI } from 'router/RoleBasedUI';

const appRoutes = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <MeProvider>
          <ModuleDashboardTable />
        </MeProvider>
      </ProtectedRoute>
    ),
    state: 'dashboard',
    sidebarProps: {
      displayText: 'Dashboard',
      icon: <DashboardOutlinedIcon />,
    },
  },
  {
    path: '/account',
    element: <Outlet />,
    state: 'account',
    sidebarProps: {
      displayText: 'Account',
      icon: <ApartmentOutlinedIcon />,
    },
    child: [
      {
        path: '/account/users',
        element: (
          <ProtectedRoute>
            <MeProvider>
              <RoleBasedUI>
                <UserDashboardTable />
              </RoleBasedUI>
            </MeProvider>
          </ProtectedRoute>
        ),
        state: 'account.users',
        paddingX: '40px',
        sidebarProps: {
          displayText: 'Users',
          icon: <GroupAddOutlinedIcon />,
        },
      },
      {
        path: '/account/settings',
        element: (
          <ProtectedRoute>
            <MeProvider>
              <RoleBasedUI>
                <CompanySettingComponent />
              </RoleBasedUI>
            </MeProvider>
          </ProtectedRoute>
        ),
        state: 'account.settings',
        paddingX: '40px',
        sidebarProps: {
          displayText: 'Settings',
          icon: <SettingsOutlinedIcon />,
        },
      },
    ],
  },
  {
    path: '/hub/:hubId',
    state: 'hub',
    element: (
      <ProtectedRoute>
        <MeProvider>
          <HubModule />
        </MeProvider>
      </ProtectedRoute>
    ),
  },
];

export default appRoutes;
