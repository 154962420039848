import React, { memo, useState } from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import { Box, IconButton } from '@mui/material';
import DeleteContentBiteDialog from './DeleteContentBiteDialog';
import YTBiteModal from '../cms_bites_modal/YTBiteModal';
import { useYTContext } from '../cms_context/YTContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const YouTubeVideoBiteComponent = (props) => {
  const youtubeContentBite = props.contentBite;
  const ytVideoId = youtubeContentBite?.ytVideoId;
  const embedUrl = `https://www.youtube.com/embed/${ytVideoId}`;

  const [deleteContentBiteModal, setDeleteContentBiteModal] = useState(false);
  const [YTEditModal, setYTEditModal] = useState(false);

  const {
    setModalType,
    contentBiteId,
    deleteContentBiteLoading,
    deleteContentBite,
  } = useEditCMSContext();

  const { setYtVideoUrl } = useYTContext();

  const onCloseDialog = () => setDeleteContentBiteModal(false);

  const closeModalType = () => {
    setModalType(null);
    setYTEditModal(false);
    setYtVideoUrl('');
  };

  if (youtubeContentBite.__typename !== 'YouTubeVideoBite') {
    return <p>Incorrect Bite Rendered</p>;
  }

  return (
    <>
      <Box className='youtube-video-bite-content' sx={{ position: 'relative' }}>
        <Box sx={{ aspectRatio: '16/9', position: 'relative', width: '100%' }}>
          <iframe
            className='w-100 h-100'
            src={embedUrl}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
          <Box className='youtube-action-buttons' style={{ zIndex: '100' }}>
            <IconButton
              onClick={() => {
                props.handleEditItem();
                setModalType(null);
                setYTEditModal(true);
                setYtVideoUrl(embedUrl);
              }}
              disableRipple
              sx={{ background: 'blue', color: 'white' }}
              aria-label='delete'
              size='medium'
            >
              <EditIcon fontSize='inherit' />
            </IconButton>
            <IconButton
              onClick={() => {
                props.handleDeleteItem();
                setDeleteContentBiteModal(true);
              }}
              disableRipple
              sx={{ background: 'red', color: 'white' }}
              aria-label='delete'
              size='medium'
            >
              <DeleteIcon fontSize='inherit' />
            </IconButton>
          </Box>
        </Box>

        {deleteContentBiteModal && (
          <DeleteContentBiteDialog
            onCloseDialog={onCloseDialog}
            deleteContentBite={deleteContentBite}
            deleteContentBiteLoading={deleteContentBiteLoading}
          />
        )}

        {contentBiteId?.toString() === youtubeContentBite?.id?.toString() && (
          <YTBiteModal
            open={YTEditModal}
            closeModalType={closeModalType}
            edit={true}
            prevYTVideoId={ytVideoId}
          />
        )}
      </Box>
    </>
  );
};

export default memo(YouTubeVideoBiteComponent);
