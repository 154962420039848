import React from 'react';
import { HubModuleProvider } from './HubModuleContext';
import HubModuleTable from './HubModuleTable';

const HubModule = () => {
  return (
    <HubModuleProvider>
      <HubModuleTable />
    </HubModuleProvider>
  );
};

export default HubModule;
