import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import React from 'react';

const DeleteContentBiteDialog = (props) => {
  const { onCloseDialog, deleteContentBite, deleteContentBiteLoading } = props;

  const deleteContentBiteItem = async () => {
    await deleteContentBite();
    if (!deleteContentBiteLoading) {
      onCloseDialog();
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      open={true}
      onClose={onCloseDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={deleteContentBiteItem}
          loading={deleteContentBiteLoading}
          size='medium'
        >
          Yes
        </LoadingButton>
        <Button variant='contained' onClick={onCloseDialog}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteContentBiteDialog;
