import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getDimensions } from 'components/utils/CommonFunction';
import React, { useEffect, useState } from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import { useVideoContext } from '../cms_context/VideoContext';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Check, CloudUpload } from '@mui/icons-material';
import { useFileUpload } from 'use-file-upload';
import _ from 'lodash';
import { useLazyQuery, gql } from '@apollo/client';
import { allowedVideoFormats } from 'global/const';
import { GetMediaContent } from 'graphql/gen.types';

const SPECIFIC_CONTENT_BITE_DOCUMENT = gql`
  query ContentBite($id: ID!) {
    contentBite(id: $id) {
      ... on VideoBite {
        id
        createdAt
        videoDescription
        videoSize
        disableAudio
        loop
        durationMillis
      }
    }
  }
`;

const VideoRenderedComponent = ({ src }) => {
  return (
    <video
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      src={src}
      controls={true}
      controlsList='nodownload'
    />
  );
};

const VideoBiteModal = ({ closeModalType, edit, open, prevVideoData }) => {
  const { setFile, contentBiteId, setContentBiteId } = useEditCMSContext();
  const [imageFile, selectFile] = useFileUpload();
  const [, selectVideoFile] = useFileUpload();

  const {
    videoDescription,
    setVideoDescription,
    setDurationMillis,
    videoDimensions,
    setVideoDimensions,
    preview,
    setPreview,
    videoBiteLoading,
    videoBiteError,
    executeVideoBite,
    resetVideoData,
    posterFrame,
    setPosterFrame,
    posterFrameLoading,
    posterFrameError,
    executePosterFrame,
    updateVideoBiteError,
    updateVideoBiteLoading,
    updateVideoBite,
    updateDescriptionError,
    updateDescriptionLoading,
    updateMediaDescription,
  } = useVideoContext();

  const [isFileUploadError, setIsFileUploadError] = useState('');

  const [videoFile, setVideoFile] = useState('');
  const [isPrevVideoData, setIsPrevVideoData] = useState({
    preview: '',
    videoDescription: '',
    videoSize: '',
    durationMillis: '',
  });

  const [getVideoBite, { data, loading }] = useLazyQuery(
    SPECIFIC_CONTENT_BITE_DOCUMENT,
    {
      variables: {
        id: contentBiteId,
      },
    },
  );

  const [
    getVideoMediaContent,
    { data: mediaContentData, loading: mediaContentLoading },
  ] = useLazyQuery(GetMediaContent, {
    variables: {
      contentBiteId: contentBiteId,
    },
  });

  const fileSelectedHandler = ({ name, size, source, file }) => {
    console.log('Files Selected', { name, size, source, file });
    const reader = new FileReader();
    reader.onload = () => {
      setPosterFrame(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
    console.log(imageFile);
  };

  const videoFileSelectHandler = ({ name, size, source, file }) => {
    const videoElement = document.createElement('video');
    videoElement.src = window.URL.createObjectURL(file);

    videoElement.onloadedmetadata = function () {
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;
      const durationMillis = videoElement.duration * 1000;
      setVideoDimensions({ width: width, height: height });
      setDurationMillis(durationMillis);
    };

    if (!allowedVideoFormats.includes(file?.type)) {
      setIsFileUploadError('Invalid file format. Accepted formats: MP4, WEBM');
    } else if (size > 12000000) {
      setIsFileUploadError('File size exceeds 12MB limit');
    } else {
      setVideoFile(source);
      setFile(file);
      setIsFileUploadError('');
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVideoFileChange = () => {
    selectVideoFile({ accept: allowedVideoFormats }, videoFileSelectHandler);
  };

  const addVideoContentBite = () => {
    try {
      executeVideoBite({
        onCompleted: () => {
          closeModalType();
          emptyData();
        },
      });
    } catch (error) {
      console.log('addVideoContentBite  error:', error);
    }
  };

  const updateVideoContentBite = () => {
    try {
      updateVideoBite({});
    } catch (error) {
      console.log('updateVideoContentBite  error:', error);
    }
  };

  const updateVideoDescription = async () => {
    try {
      await updateMediaDescription({});
    } catch (error) {
      console.log('updateImageDescription error', error);
    }
  };

  const submitPosterFrame = () => {
    executePosterFrame({});
  };

  const maxDimension = 600;
  const videoResolution = getDimensions(videoDimensions, maxDimension);

  useEffect(() => {
    if (!edit) {
      emptyData();
    } else if (contentBiteId) {
      getVideoBite();
      getVideoMediaContent();
      if (!loading && data) {
        const {
          contentBite: { videoDescription, durationMillis, videoSize },
        } = data;
        setIsPrevVideoData({
          videoDescription: videoDescription,
          videoSize: videoSize,
          durationMillis: durationMillis,
        });
      }
      if (!mediaContentLoading && mediaContentData) {
        const { mediaContent } = mediaContentData;
        setIsPrevVideoData((prevVideo) => ({
          ...prevVideo,
          preview: mediaContent,
        }));
        setPreview(mediaContent);
      }
    }
    // eslint-disable-next-line
  }, [
    edit,
    loading,
    data,
    contentBiteId,
    mediaContentLoading,
    mediaContentData,
  ]);

  const hasPosterFrameChanged = () => {
    return !_.eq(prevVideoData?.posterFile, posterFrame);
  };

  const resetPosterFrame = () => {
    setPosterFrame(prevVideoData?.posterFile);
  };

  const hasVideoFileChanged = () => {
    return !_.eq(isPrevVideoData.preview, preview);
  };

  const resetVideoFile = () => {
    const { durationMillis, preview, videoSize } = isPrevVideoData;
    setPreview(preview);
    setVideoDimensions(JSON.parse(videoSize));
    setDurationMillis(durationMillis);
    setVideoFile('');
  };

  const hasVideoDescriptionsChange = () => {
    return data && !_.eq(isPrevVideoData.videoDescription, videoDescription);
  };

  const resetVideoDescription = () => {
    setVideoDescription(isPrevVideoData.videoDescription);
  };

  const hasEnabled = () => {
    return (
      !preview ||
      !(
        hasVideoFileChanged() ||
        hasPosterFrameChanged() ||
        hasVideoDescriptionsChange()
      )
    );
  };

  const emptyData = () => {
    resetVideoData();
    setIsPrevVideoData({
      preview: '',
      videoDescription: '',
      videoSize: '',
      durationMillis: '',
    });
    setPreview('');
    setContentBiteId('');
    setVideoDescription('');
    setVideoFile('');
  };

  const clearData = () => {
    if (!edit) {
      emptyData();
    }
  };

  const onClose = () => {
    closeModalType();
    setContentBiteId('');
  };

  return (
    <>
      <Dialog maxWidth='xl' fullWidth open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h4' component='span'>
            {edit ? 'Edit Video' : 'Add Video'}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {edit ? (
          <DialogContent dividers>
            <div className='d-flex' id='cms_file'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '16px',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: `${maxDimension}px`,
                      width: '100%',
                      mb: 1.5,
                    }}
                  >
                    {updateVideoBiteError || updateDescriptionError ? (
                      <Alert severity='error'>
                        Oops, something went wrong. Please try again later.
                      </Alert>
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      aspectRatio: '16/9',
                      width: `${videoResolution?.width}px`,
                      backgroundColor: '#f0f0f0',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      className='media-bite-card'
                      sx={{
                        width: `${videoResolution?.width}px`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          ...videoResolution?.mediaStyle,
                        }}
                      >
                        {videoFile ? (
                          <VideoRenderedComponent src={videoFile} />
                        ) : preview ? (
                          <VideoRenderedComponent src={preview} />
                        ) : loading || mediaContentLoading ? (
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress color='inherit' />
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>

                  {isFileUploadError && (
                    <Typography
                      variant='body1'
                      sx={{ my: 1 }}
                      style={{ color: '#d10000' }}
                    >
                      {isFileUploadError}
                    </Typography>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <Box>
                      <Button
                        variant='primary'
                        size='medium'
                        fullWidth
                        startIcon={<CloudUpload />}
                        onClick={handleVideoFileChange}
                      >
                        Replace Video
                      </Button>
                    </Box>

                    {!mediaContentLoading && hasVideoFileChanged() && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                        }}
                      >
                        <Button
                          onClick={resetVideoFile}
                          size='medium'
                          fullWidth
                        >
                          Reset
                        </Button>
                        <LoadingButton
                          loading={updateVideoBiteLoading}
                          variant='contained'
                          size='medium'
                          onClick={updateVideoContentBite}
                          fullWidth
                        >
                          Save
                        </LoadingButton>
                      </Box>
                    )}
                  </Box>

                  <TextField
                    label='Video Description'
                    margin='normal'
                    fullWidth
                    size='medium'
                    value={videoDescription}
                    onChange={(e) => setVideoDescription(e.target.value)}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Enter') {
                        updateVideoDescription();
                        ev.preventDefault();
                      } else if (ev.key === 'Escape') {
                        resetVideoDescription();
                        ev.preventDefault();
                      }
                    }}
                    InputProps={{
                      endAdornment:
                        hasVideoDescriptionsChange &&
                        updateDescriptionLoading ? (
                          <InputAdornment position='end'>
                            <CircularProgress
                              color='inherit'
                              size='40px'
                              sx={{ padding: '8px' }}
                            />
                          </InputAdornment>
                        ) : hasVideoDescriptionsChange() ? (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='submit changes'
                              onClick={updateVideoDescription}
                            >
                              <Check />
                            </IconButton>
                            <IconButton
                              aria-label='cancel changes'
                              onClick={resetVideoDescription}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: `${maxDimension}px`,
                      width: '100%',
                      mb: 1.5,
                    }}
                  >
                    {posterFrameError ? (
                      <Alert severity='error'>
                        Oops, something went wrong. Please try again later.
                      </Alert>
                    ) : null}
                  </Box>

                  <Box
                    className='media-bite-card'
                    sx={{
                      width: `${videoResolution?.width}px`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      sx={{
                        ...videoResolution?.mediaStyle,
                        aspectRatio: '16/9',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#f0f0f0',
                      }}
                    >
                      {preview ? (
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          src={posterFrame}
                          alt=''
                        />
                      ) : loading ? (
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress color='inherit' />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <Box>
                      <Button
                        variant='primary'
                        size='medium'
                        fullWidth
                        startIcon={<CloudUpload />}
                        onClick={handleFileChange}
                      >
                        {edit && posterFrame
                          ? 'Replace Poster Frame'
                          : 'Add Poster Frame'}
                      </Button>
                    </Box>
                    {hasPosterFrameChanged() && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                        }}
                      >
                        <Button
                          onClick={resetPosterFrame}
                          size='medium'
                          fullWidth
                        >
                          Reset
                        </Button>
                        <LoadingButton
                          loading={posterFrameLoading}
                          variant='contained'
                          size='medium'
                          onClick={submitPosterFrame}
                          fullWidth
                        >
                          Save
                        </LoadingButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>
            </div>
          </DialogContent>
        ) : (
          <>
            <DialogContent dividers>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <DialogContentText
                  sx={{ maxWidth: `${maxDimension}px`, width: '100%' }}
                >
                  {videoBiteError ? (
                    <Alert sx={{ mb: 2 }} severity='error'>
                      Oops, something went wrong. Please try again later.
                    </Alert>
                  ) : null}
                </DialogContentText>
                <div id='cms_file'>
                  <div className='container'>
                    <div className='file-input-box'>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {videoResolution && (
                          <Box
                            className='media-bite-card'
                            sx={{
                              width: `${videoResolution?.width}px`,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <Box sx={videoResolution?.mediaStyle}>
                              {videoFile ? (
                                <VideoRenderedComponent src={videoFile} />
                              ) : preview ? (
                                <VideoRenderedComponent src={preview} />
                              ) : null}
                            </Box>
                          </Box>
                        )}
                      </Box>

                      {isFileUploadError && (
                        <Typography
                          variant='body1'
                          sx={{ my: 1 }}
                          style={{ color: '#d10000' }}
                        >
                          {isFileUploadError}
                        </Typography>
                      )}
                      <div className='wrapper-file-input'>
                        {videoFile || preview ? (
                          <TextField
                            label='Video Description'
                            margin='normal'
                            fullWidth
                            size='medium'
                            value={videoDescription}
                            onChange={(e) =>
                              setVideoDescription(e.target.value)
                            }
                          />
                        ) : null}
                        <div
                          className='input-box'
                          onClick={handleVideoFileChange}
                        >
                          <h4>
                            <i className='fa-solid fa-upload'></i>
                            Choose File to upload
                          </h4>
                        </div>
                        <small>Files Supported: MP4, WEBM</small>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {preview && <Button onClick={clearData}>Reset</Button>}
              <LoadingButton
                disabled={hasEnabled()}
                loading={videoBiteLoading}
                onClick={addVideoContentBite}
                variant='contained'
              >
                Add
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default VideoBiteModal;
