import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY, // Replace it with your api key.
  plugins: [new BugsnagPluginReact()],
});

const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorBoundary = ({ children }) => {
  return <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>;
};

export default ErrorBoundary;
