import { Box, Container, Typography } from '@mui/material';
import { useSimpleKeywordSearchLazyQuery } from 'graphql/gen.types';
import React, { useEffect } from 'react';
import SearchResultItem from './SearchResultItem';
import _ from 'lodash';

const SearchResultComponent = ({ moduleIdentifier, searchQuery }) => {
  const [
    getSearchQueryData,
    { loading: searchQueryLoading, data: searchQueryData },
  ] = useSimpleKeywordSearchLazyQuery({
    variables: {
      input: {
        moduleIdentifier: moduleIdentifier,
        searchQuery: searchQuery,
      },
    },
  });

  useEffect(() => {
    if (searchQuery !== '' && !_.isEmpty(searchQuery)) {
      getSearchQueryData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '80vh',
        }}
      >
        {searchQueryLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant='h6'>Waiting for search input...</Typography>
          </Box>
        ) : (
          <>
            {searchQueryData?.simpleKeywordSearch.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h6'>No Results Found</Typography>
              </Box>
            ) : (
              <Box>
                {searchQueryData?.simpleKeywordSearch.map(
                  (searchResultItem, index) => {
                    return (
                      <SearchResultItem
                        key={index}
                        contentBite={searchResultItem}
                        moduleIdentifier={moduleIdentifier}
                      />
                    );
                  },
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default SearchResultComponent;
