import { useMediaContentQuery } from 'graphql/gen.types';
import React, { memo, useEffect, useState } from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteContentBiteDialog from './DeleteContentBiteDialog';
import ReplayIcon from '@mui/icons-material/Replay';
import ImageBiteModal from '../cms_bites_modal/ImageBiteModal';
import { useImageContext } from '../cms_context/ImageContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// A simple image bite which occupies the entire container width to display
// the image bite passed in props.contentbite. Renders an error message
// if the content bite is invalid.
const ImageBiteComponent = (props) => {
  const imageBite = props.contentBite;
  const { deleteContentBiteLoading, deleteContentBite } = useEditCMSContext();

  const { id } = imageBite;

  const [deleteContentBiteModal, setDeleteContentBiteModal] = useState(false);
  const [imageEditModal, setImageEditModal] = useState(false);

  const onCloseDialog = () => setDeleteContentBiteModal(false);

  const { setFile, setModalType, contentBiteId } = useEditCMSContext();

  const {
    setPreview,
    imageDescription,
    setImageDimensions,
    setImageDescription,
  } = useImageContext();

  const [imageUrl, setImageUrl] = useState(null);

  const { loading, error, data, networkStatus, refetch } = useMediaContentQuery(
    {
      variables: {
        contentBiteId: id?.toString(),
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (data) {
      setImageUrl(data?.mediaContent);
    }
  }, [data]);

  const closeModalType = () => {
    setModalType(null);
    setImageEditModal(false);
    setFile('');
    setPreview('');
  };

  const imageFullSize = {
    width: '100%',
    height: '100%',
  };

  if (imageBite.__typename !== 'ImageBite') {
    return <p>Incorrect Bite Rendered</p>;
  }

  return (
    <>
      <Box
        className='image-bite-content'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          ...imageFullSize,
        }}
      >
        {loading || networkStatus === 4 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...imageFullSize,
            }}
          >
            <CircularProgress sx={{ color: 'black' }} />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...imageFullSize,
            }}
          >
            <IconButton color='inherit' onClick={() => refetch()}>
              <ReplayIcon color='common' />
            </IconButton>
          </Box>
        ) : (
          <img
            src={imageUrl}
            alt={imageDescription}
            loading='lazy'
            style={{ objectFit: 'cover' }}
          />
        )}

        <Box className='image-action-buttons' style={{ zIndex: '100' }}>
          <IconButton
            onClick={() => {
              props.handleEditItem();
              setImageEditModal(true);
              setModalType(null);
              setImageDescription(imageBite.imageDescription);
              setImageDimensions(JSON.parse(imageBite.imageSize));
              setPreview(imageUrl);
            }}
            disableRipple
            sx={{ background: 'blue', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <EditIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            onClick={() => {
              props.handleDeleteItem();
              setDeleteContentBiteModal(true);
            }}
            disableRipple
            sx={{ background: 'red', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
        </Box>

        {deleteContentBiteModal && (
          <DeleteContentBiteDialog
            onCloseDialog={onCloseDialog}
            deleteContentBite={deleteContentBite}
            deleteContentBiteLoading={deleteContentBiteLoading}
          />
        )}

        {contentBiteId?.toString() === id?.toString() && (
          <ImageBiteModal
            open={imageEditModal}
            closeModalType={closeModalType}
            edit={true}
            prevImageUrl={imageUrl}
          />
        )}
      </Box>
    </>
  );
};

export default memo(ImageBiteComponent);
