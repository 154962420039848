import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { fetchAuthSession } from 'aws-amplify/auth';
import { ClientSchema, typePolicyMap } from './extras';

const errorLink = onError(({ graphqlErrors, networkErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
      return null;
    });
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_LUNA_GRAPHQL_URL,
  fetchOptions: {},
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 500,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      return !!error;
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};

  const token = accessToken?.toString();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

// Concatenate all the links
const concatenatedLinks = from([authLink, retryLink, errorLink, httpLink]);

export const apolloClient = new ApolloClient({
  link: concatenatedLinks,
  typeDefs: ClientSchema,
  cache: new InMemoryCache({
    typePolicies: typePolicyMap,
  }),
});
