import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetModuleList,
  useGetModuleStructure,
  useModuleIconByIDQuery,
} from 'graphql/gen.types';
import _ from 'lodash';
import HubHeader from './HubHeader';
import { useMeContext } from 'context/meContext';
import HubModuleInfo from './HubModuleInfo';
import HubModuleRemove from './HubModuleRemove';
import {
  StyledTableCell,
  StyledTableRow,
} from 'components/Common/StyledTableComponent';
import { useHubModuleContext } from './HubModuleContext';

let PageSize = 10;

const TableData = ({ tableData, setViewInfoModuleId, setRemoveModuleId }) => {
  const navigate = useNavigate();

  const { loading, data, error, refetch } = useModuleIconByIDQuery({
    variables: {
      moduleId: tableData?.moduleId?.toString(),
    },
  });

  useEffect(() => {
    if (error) {
      refetch();
    }
  }, [error, refetch]);

  return (
    <StyledTableRow>
      <StyledTableCell align='center'>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '64px',
                height: '64px',
                borderRadius: '16px',
                background: '#eeeeee',
              }}
            />
          </Box>
        ) : (
          <img
            src={data?.moduleIcon}
            alt={tableData?.title}
            style={{
              width: '64px',
              height: '64px',
              borderRadius: '16px',
              objectFit: 'cover',
            }}
          />
        )}
      </StyledTableCell>
      <StyledTableCell align='left'>
        {tableData?.title}
        <Typography sx={{ fontSize: 'smaller', pt: '8px' }}>
          {tableData?.subtitle}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align='center'>{tableData?.category}</StyledTableCell>
      <StyledTableCell align='center'>
        <Box>
          <IconButton
            onClick={() => setViewInfoModuleId(tableData?.moduleId)}
            color='inherit'
          >
            <FontAwesomeIcon style={{ fontSize: 'large' }} icon={faEye} />
          </IconButton>
          <IconButton
            onClick={() => navigate(_.join(['/cms/', tableData?.moduleId], ''))}
            color='inherit'
          >
            <FontAwesomeIcon style={{ fontSize: 'large' }} icon={faPencilAlt} />
          </IconButton>
          <IconButton
            onClick={() => setRemoveModuleId(tableData?.moduleId)}
            color='inherit'
          >
            <FontAwesomeIcon style={{ fontSize: 'large' }} icon={faTrash} />
          </IconButton>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const HubModuleTable = () => {
  const { hubId: moduleId } = useParams();

  const { searchTerm, filteredOption, sortByOption } = useHubModuleContext();

  // eslint-disable-next-line
  const [displayData, setDisplayData] = useState([]);
  const [viewInfoModuleId, setViewInfoModuleId] = useState(undefined);
  const [removeModuleId, setRemoveModuleId] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const { loading, error, data } = useGetModuleStructure({
    variables: {
      moduleId: moduleId,
    },
  });

  const { userData } = useMeContext();

  const { data: moduleListData } = useGetModuleList({
    variables: {
      accountId: userData?.account?.id,
    },
  });

  // useMemo hook to memoize response data every time it changes
  const displayHubModuleData = useMemo(() => {
    // Check if data is undefined or not in the expected format
    if (!data || !Array.isArray(data.module?.contentInfo?.hubModules)) {
      return []; // Return an empty array or handle it appropriately
    }
    setDisplayData(data.module?.contentInfo?.hubModules);
    return data.module?.contentInfo?.hubModules; // Assuming data.module?.contentInfo?.hubModules is the array you want to display
  }, [data]);

  const computeDisplayData = (searchTerm, categoryType, sortByOption) => {
    let finalDisplayData = [...displayHubModuleData];

    // Filter on categoryType
    if (categoryType) {
      // Filter by categoryType when filteredOption is present
      finalDisplayData = finalDisplayData.filter(
        (obj) => obj?.category === categoryType,
      );
    }

    // Filter on searchTerm
    if (searchTerm) {
      // Filter by title when searchTerm is present
      const searchTermLower = searchTerm.toLowerCase();
      finalDisplayData = finalDisplayData.filter((obj) =>
        obj.title.toLowerCase().includes(searchTermLower),
      );
    }

    // Sort based on the selected option
    if (sortByOption?.value === 'asc-title') {
      // Sort by title
      let field = 'title';
      finalDisplayData = finalDisplayData.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString()),
      );
    } else if (sortByOption?.value === 'desc-title') {
      // Sort by title
      let field = 'title';
      finalDisplayData = finalDisplayData.sort((a, b) =>
        (b[field] || '').toString().localeCompare((a[field] || '').toString()),
      );
    }

    return finalDisplayData;
  };

  // Extract unique categories
  const uniqueCategories = [
    ...new Set(displayHubModuleData.map((item) => item.category)),
  ];

  // Create the desired array of objects
  const categoryArray = uniqueCategories.map((category) => ({
    value: category,
    label: category,
  }));

  const displayModuleListFilterData = useMemo(() => {
    // Check if data is undefined or not in the expected format
    if (!moduleListData || !Array.isArray(moduleListData?.modules)) {
      return []; // Return an empty array or handle it appropriately
    }

    return moduleListData?.modules?.filter(
      (module) =>
        module?.moduleType === 'SINGLE' &&
        !displayHubModuleData?.some(
          (hubModule) => hubModule?.moduleId === module?.id,
        ),
    );
  }, [moduleListData, displayHubModuleData]);

  const moduleInfo = displayHubModuleData?.filter(
    (hubModule) => hubModule?.moduleId === viewInfoModuleId,
  )?.[0];

  useEffect(() => {
    // Filter, sort, and paginate the data when the search term, moduleType, or sortByOption changes
    const filteredData = computeDisplayData(
      searchTerm?.toLowerCase(),
      filteredOption?.value,
      sortByOption,
    );

    setDisplayData(filteredData);
    setCurrentPage(1); // Reset to the first page when filters change
    // eslint-disable-next-line
  }, [data, searchTerm, sortByOption, filteredOption]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.error('GraphQL Error In Hub:', error);
    return <Alert severity='error'>Oops, something went wrong.</Alert>;
  }

  const computePagination = (data, currentPage, pageSize) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  };

  const currentData = computePagination(displayData, currentPage, PageSize);
  const totalCount = displayData.length;
  const totalPageCount = Math.ceil(totalCount / PageSize);

  return (
    <>
      <Box sx={{ px: '24px' }}>
        <HubHeader
          filterOption={categoryArray}
          displayModuleListFilterData={displayModuleListFilterData}
          hubName={data?.module?.name}
        />
        {displayData.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell width='96px' align='center'>
                    Icon
                  </StyledTableCell>
                  <StyledTableCell width='400px' align='left'>
                    Title
                  </StyledTableCell>
                  <StyledTableCell width='200px' align='center'>
                    Category
                  </StyledTableCell>
                  <StyledTableCell width='144px' align='center'>
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData?.map((data, i) => {
                  return (
                    <TableData
                      key={i}
                      tableData={data}
                      setViewInfoModuleId={setViewInfoModuleId}
                      setRemoveModuleId={setRemoveModuleId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {!loading && displayData?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <Typography variant='h5'>No Results Found</Typography>
        </Box>
      )}

      {displayData.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'end', my: 2 }}>
          <Pagination
            count={totalPageCount}
            page={currentPage}
            boundaryCount={2}
            onChange={handleChange}
          />
        </Box>
      )}

      {viewInfoModuleId && (
        <HubModuleInfo
          open={true}
          onClose={() => setViewInfoModuleId(undefined)}
          moduleId={viewInfoModuleId}
          moduleInfo={moduleInfo}
        />
      )}

      {removeModuleId && (
        <HubModuleRemove
          open={true}
          onClose={() => setRemoveModuleId(undefined)}
          moduleId={removeModuleId}
        />
      )}
    </>
  );
};

export default HubModuleTable;
