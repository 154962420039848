import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImagePreview from '../cms_bites_view/ImagePreview';
import { convertToWebP, getDimensions } from 'components/utils/CommonFunction';
import { useEditCMSContext } from '../EditCmsContext';
import { useImageContext } from '../cms_context/ImageContext';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { allowedImageFormats } from 'global/const';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { Check } from '@mui/icons-material';

const SPECIFIC_CONTENT_BITE_DOCUMENT = gql`
  query ContentBite($id: ID!) {
    contentBite(id: $id) {
      ... on ImageBite {
        id
        imageDescription
        imageSize
      }
    }
  }
`;

const ImageBiteModal = ({ closeModalType, open, edit, prevImageUrl }) => {
  const { setFile, contentBiteId } = useEditCMSContext();

  const {
    preview,
    setPreview,
    imageDimensions,
    setImageDimensions,
    imageDescription,
    setImageDescription,
    imageBiteError,
    imageBiteLoading,
    executeImageBite,
    resetImageData,
    updateImageBiteError,
    updateImageBiteLoading,
    updateImageBite,
    updateDescriptionError,
    updateDescriptionLoading,
    updateMediaDescription,
  } = useImageContext();

  const [isFileUploadError, setIsFileUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery(SPECIFIC_CONTENT_BITE_DOCUMENT, {
    variables: {
      id: contentBiteId,
    },
  });

  const [prevImageData, setPreviousImageData] = useState({
    preview: '',
    imageDescription: '',
    imageSize: '',
  });

  const fileInputRef = React.createRef();

  const handleOnChange = async (e) => {
    e.preventDefault();
    try {
      const imageFile = e.target.files[0];
      if (!imageFile) {
        console.log('Image file not found');
        return;
      }
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        setImageDimensions({
          height: img.height,
          width: img.width,
        });
      };
      setPreview('');

      if (!imageFile) {
        setIsLoading(false);
        throw new Error('No file selected');
      }

      if (!allowedImageFormats.includes(imageFile.type)) {
        setIsFileUploadError(
          'Invalid file format. Accepted formats: JPEG, WEBP, JPG, PNG, GIF',
        );
      } else if (imageFile.size > 12000000) {
        setIsFileUploadError('File size exceeds 12MB limit');
      } else {
        setIsLoading(true);
        setFile(imageFile);
        setIsFileUploadError('');

        const webpBase64 = await convertToWebP(imageFile);
        setPreview(`data:image/webp;base64,${webpBase64}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Handle the error, e.g., display a message to the user
    }
  };

  const resetDataOnClose = () => {
    closeModalType();
  };

  const addImageContentBite = async () => {
    try {
      await executeImageBite({
        onCompleted: () => {
          resetDataOnClose();
        },
      });
    } catch (error) {
      console.log('addImageContentBite error', error);
    }
  };

  const updateImageContentBite = async () => {
    try {
      await updateImageBite({
        onCompleted: () => {
          resetDataOnClose();
        },
      });
    } catch (error) {
      console.log('updateImageContentBite error', error);
    }
  };

  const updateImageDescription = async () => {
    try {
      await updateMediaDescription({
        onCompleted: () => {
          resetDataOnClose();
        },
      });
    } catch (error) {
      console.log('updateImageDescription error', error);
    }
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const maxDimension = 600;
  const imageResolution = getDimensions(imageDimensions, maxDimension);

  const hasImageFieldChange = () => {
    return !_.eq(preview, prevImageUrl);
  };

  const hasImageDescriptionChange = () => {
    return !_.eq(imageDescription, prevImageData.imageDescription);
  };

  const resetImageDescription = () => {
    const { imageDescription } = prevImageData;
    setImageDescription(imageDescription);
  };

  const resetData = () => {
    const { imageSize } = prevImageData;
    setPreview(prevImageUrl);
    setImageDimensions(JSON.parse(imageSize));
  };

  useEffect(() => {
    if (data && data.contentBite) {
      setPreviousImageData(data.contentBite);
    }
  }, [data]);

  useEffect(() => {
    if (!edit) {
      resetImageData();
    }
    // eslint-disable-next-line
  }, [edit]);

  return (
    <>
      <Dialog maxWidth='md' fullWidth open={open} onClose={resetDataOnClose}>
        <DialogTitle>
          <Typography variant='h4' component='span'>
            {edit ? 'Update Image' : 'Add Image'}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={resetDataOnClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <DialogContentText
              sx={{ maxWidth: `${maxDimension}px`, width: '100%' }}
            >
              {imageBiteError ||
              updateImageBiteError ||
              updateDescriptionError ? (
                <Alert sx={{ mb: 2 }} severity='error'>
                  Oops, something went wrong. Please try again later.
                </Alert>
              ) : null}
            </DialogContentText>
            <div id='cms_file'>
              <div className='container'>
                <div className='file-input-box'>
                  <Box
                    style={{
                      width: `${maxDimension}px`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ImagePreview
                      preview={preview}
                      isLoading={isLoading}
                      imageResolution={imageResolution}
                      isFileUploadError={isFileUploadError}
                    />
                  </Box>

                  {isFileUploadError && (
                    <Typography
                      variant='body1'
                      sx={{ my: 1 }}
                      style={{ color: '#d10000' }}
                    >
                      {isFileUploadError}
                    </Typography>
                  )}

                  <div className='wrapper-file-input'>
                    {preview || (edit && hasImageDescriptionChange()) ? (
                      <TextField
                        sx={{ marginBottom: '16px', marginTop: '0px' }}
                        label='Image Description'
                        margin='normal'
                        fullWidth
                        size='medium'
                        value={imageDescription}
                        onChange={(e) => setImageDescription(e.target.value)}
                        onKeyDown={(ev) => {
                          if (edit && hasImageDescriptionChange()) {
                            if (ev.key === 'Enter') {
                              updateImageDescription();
                              ev.preventDefault();
                            } else if (ev.key === 'Escape') {
                              resetImageDescription();
                              ev.preventDefault();
                            }
                          }
                        }}
                        InputProps={{
                          endAdornment: updateDescriptionLoading ? (
                            <InputAdornment position='end'>
                              <CircularProgress
                                color='inherit'
                                size='40px'
                                sx={{ padding: '8px' }}
                              />
                            </InputAdornment>
                          ) : edit && hasImageDescriptionChange() ? (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={updateImageDescription}
                                aria-label='submit changes'
                              >
                                <Check />
                              </IconButton>
                              <IconButton
                                onClick={resetImageDescription}
                                aria-label='cancel changes'
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                        }}
                      />
                    ) : null}

                    <div className='input-box' onClick={openFileInput}>
                      <h4>
                        <i className='fa-solid fa-upload'></i>
                        Choose File to upload
                      </h4>
                      <input
                        ref={fileInputRef}
                        hidden
                        id='file'
                        type='file'
                        accept={allowedImageFormats}
                        onChange={handleOnChange}
                      />
                    </div>
                    <small>Files Supported: JPEG, WEBP, JPG, PNG, GIF</small>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          {edit ? (
            <>
              {hasImageFieldChange() && (
                <Button onClick={resetData}>Reset</Button>
              )}
              <LoadingButton
                disabled={!(edit && hasImageFieldChange())}
                loading={updateImageBiteLoading}
                onClick={updateImageContentBite}
                variant='contained'
              >
                Save
              </LoadingButton>
            </>
          ) : (
            <LoadingButton
              disabled={!preview}
              loading={imageBiteLoading}
              onClick={addImageContentBite}
              variant='contained'
            >
              Add
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageBiteModal;
