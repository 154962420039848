import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const ImagePreview = ({
  preview,
  isLoading,
  imageResolution,
  isFileUploadError,
}) => {
  const imageStyle = {
    width: '100%',
    height: '100%',
  };

  return isFileUploadError ? null : (
    <Box sx={{ ...imageResolution?.mediaStyle, mb: 2 }}>
      {preview ? (
        <img
          src={preview}
          style={{ ...imageStyle, objectFit: 'cover' }}
          alt='preview'
        />
      ) : isLoading ? (
        <Box
          sx={{
            ...imageStyle,
            background: 'grey',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      ) : (
        <Box
          sx={{
            ...imageStyle,
            background: 'grey',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
    </Box>
  );
};

export default ImagePreview;
