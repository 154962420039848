import {
  Avatar,
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import appRoutes from '../../routes/appRoutes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import MainLogo from '../../assets/knidal-logo-white.svg';
import {
  ADMIN_CONSOLE_MODAL_SELECTION,
  rVarAdminConsoleModalContext,
  rVarHasUsersAccessRole,
} from 'global/const';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useReactiveVar } from '@apollo/client';

const Sidebar = () => {
  const logoStyle = {
    width: '96px',
    height: '96px',
  };

  const { hasUsersAccessRole } = useReactiveVar(rVarHasUsersAccessRole);

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sizeConfigs.sidebar.width,
          boxSizing: 'border-box',
          borderRight: '0px',
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          zIndex: 500,
        },
      }}
    >
      <List disablePadding>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginY: '16px',
          }}
        >
          <Avatar sx={logoStyle} src={MainLogo} />
        </Box>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              hasUsersAccessRole ? ( // Check if the user has access to users route
                <SidebarItemCollapse item={route} key={index} />
              ) : null
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null,
        )}
      </List>
      <Box sx={{ position: 'fixed', width: 'inherit', bottom: '0' }}>
        <List disablePadding>
          <ListItemButton
            onClick={() =>
              rVarAdminConsoleModalContext({
                selection: ADMIN_CONSOLE_MODAL_SELECTION.PROFILE_SETTINGS,
              })
            }
            sx={{
              '&: hover': {
                backgroundColor: colorConfigs.sidebar.hoverBg,
              },
              backgroundColor: colorConfigs.sidebar.activeBg,
              paddingY: '12px',
              paddingX: '24px',
            }}
          >
            <ListItemIcon
              sx={{
                color: colorConfigs.sidebar.color,
              }}
            >
              <AccountCircleIcon />
            </ListItemIcon>
            Profile
          </ListItemButton>
        </List>
        <List disablePadding>
          <ListItemButton
            onClick={() =>
              rVarAdminConsoleModalContext({
                selection: ADMIN_CONSOLE_MODAL_SELECTION.LOG_OUT,
              })
            }
            sx={{
              '&: hover': {
                backgroundColor: colorConfigs.sidebar.hoverBg,
              },
              backgroundColor: colorConfigs.sidebar.activeBg,
              paddingY: '12px',
              paddingX: '24px',
            }}
          >
            <ListItemIcon
              sx={{
                color: colorConfigs.sidebar.color,
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            LogOut
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
