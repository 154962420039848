/* eslint-disable no-unused-vars */
import React from 'react';

import ProfileSettings from 'components/admin_console/user_profile/ProfileSettings';
import Logout from 'components/admin_console/user_profile/Logout';
import { useReactiveVar } from '@apollo/client';
import {
  ADMIN_CONSOLE_MODAL_SELECTION,
  rVarAdminConsoleModalContext,
} from 'global/const';
import CreateNewUser from './user_dashboard/CreateNewUser';
import EditUser from './user_dashboard/EditUser';
import DeleteUser from './user_dashboard/DeleteUser';

const AdminConsoleModalHandler = () => {
  const modalContext = useReactiveVar(rVarAdminConsoleModalContext);

  const onModalHide = () => rVarAdminConsoleModalContext(undefined);

  let modal = <></>;
  switch (modalContext?.selection) {
    case ADMIN_CONSOLE_MODAL_SELECTION.PROFILE_SETTINGS:
      modal = (
        <div>
          <ProfileSettings onHide={onModalHide} />
        </div>
      );
      break;
    case ADMIN_CONSOLE_MODAL_SELECTION.LOG_OUT:
      modal = (
        <div>
          <Logout onHide={onModalHide} />
        </div>
      );
      break;
    case ADMIN_CONSOLE_MODAL_SELECTION.NEW_USER:
      modal = (
        <div>
          <CreateNewUser onClose={onModalHide} />
        </div>
      );
      break;
    case ADMIN_CONSOLE_MODAL_SELECTION.EDIT_USER:
      modal = (
        <div>
          <EditUser
            open={true}
            onClose={onModalHide}
            userId={modalContext.data.userId}
          />
        </div>
      );
      break;
    case ADMIN_CONSOLE_MODAL_SELECTION.DELETE_USER:
      modal = (
        <div>
          <DeleteUser
            open={true}
            onClose={onModalHide}
            userId={modalContext.data.userId}
          />
        </div>
      );
      break;
    default:
      break;
  }
  return modal;
};

export default AdminConsoleModalHandler;
