import React from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import {
  ID_CREATE_AUDIO_BITE,
  ID_CREATE_IMAGE_BITE,
  ID_CREATE_VIDEO_BITE,
  ID_CREATE_YOUTUBE_VIDEO_BITE,
} from '../Content_Bites_List';
import ImageBiteModal from './ImageBiteModal';
import AudioBiteModal from './AudioBiteModal';
import VideoBiteModal from './VideoBiteModal';
import YTBiteModal from './YTBiteModal';
import { useYTContext } from '../cms_context/YTContext';

const CMSContentBitesModalHandler = ({ handleRemoveContentBiteList }) => {
  const { modalType, setModalType, setFile, setPreview } = useEditCMSContext();
  const { setYtVideoUrl } = useYTContext();

  const closeModalType = () => {
    setModalType(null);
    setFile('');
    setPreview('');
    setYtVideoUrl('');
    handleRemoveContentBiteList();
  };

  let CMSContentBitesModal = <></>;

  switch (modalType) {
    case ID_CREATE_IMAGE_BITE:
      CMSContentBitesModal = (
        <ImageBiteModal open={true} closeModalType={closeModalType} />
      );
      break;
    case ID_CREATE_AUDIO_BITE:
      CMSContentBitesModal = (
        <AudioBiteModal open={true} closeModalType={closeModalType} />
      );
      break;
    case ID_CREATE_VIDEO_BITE:
      CMSContentBitesModal = (
        <VideoBiteModal open={true} closeModalType={closeModalType} />
      );
      break;
    case ID_CREATE_YOUTUBE_VIDEO_BITE:
      CMSContentBitesModal = (
        <YTBiteModal open={true} closeModalType={closeModalType} />
      );
      break;
    default:
      break;
  }
  return CMSContentBitesModal;
};

export default CMSContentBitesModalHandler;
