import React, { useState } from 'react';
import { Modal, Row, Button, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useReactiveVar } from '@apollo/client';
import { rVarCMSApp } from 'global/const';
import _ from 'lodash';
import { AppVariantsManager } from 'global/VariantUtils';

// styles for variant definiton multi select
const variantDefinitionSelectStyles = {
  multiValueLabel: (base, state) => {
    return { ...base, marginRight: 4, paddingLeft: 8, paddingRight: 4 };
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const productVariantsSelectStyles = {
  multiValueLabel: (base, state) => {
    return { ...base, marginRight: 4, paddingLeft: 8, paddingRight: 4 };
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

/** Place where app level variant settings could be modified */
const EditVariants = (props) => {
  // reactive variables used in the component
  const { module } = useReactiveVar(rVarCMSApp);

  // Variants definition for populating the select component
  const variantsDefinition = _.clone(
    module.adminInfo.objectizedVariantFieldsDefinition,
  );

  // Product variants
  const productVariants = _.clone(module.contentInfo.objectizedProductVariants);

  // View state
  const [
    {
      variantsDefinitionsViewState,
      productVariantsViewState,
      selectedVariantParts,
      inputValue,
    },
    setEditVariantsViewState,
  ] = useState({
    variantsDefinitionsViewState: variantsDefinition,
    productVariantsViewState: productVariants,
    selectedVariantParts: {},
    inputValue: {},
  });

  // We use the functions specific to displaying & editing variant definitions and product variants
  const {
    getVariantDefinitionSelectsOptions,
    getProductVariants,
    getSelectedVariantParts,
    getProductVariantsSelectOptions,
    getProductVariantMapSelectsOptions,
    getVariantsDefinition,
    addVariantDefinitionFieldValue,
    addSelectionToProductVariants,
    replaceVariantDefinitionFieldValues,
    clearVariantPart,
    addVariantPart,
    isValidNewProductVariant,
  } = AppVariantsManager(
    variantsDefinitionsViewState,
    productVariantsViewState,
    selectedVariantParts,
  );

  // Function object to handle changes to variant part select components
  const VariantDefinitionSelectManager = (_selectName) => {
    // The originating select component name
    const selectName = _selectName;

    // Function to handle the change
    function handleChange(value, { action }) {
      console.group(`Value Changed ${selectName}`);
      console.log(value);
      console.log(`action: ${action}`);
      console.groupEnd();
      switch (action) {
        case 'remove-value':
          const newFieldValues = value.map((item) => item.original);
          replaceVariantDefinitionFieldValues(selectName, newFieldValues);
          setEditVariantsViewState({
            variantsDefinitionsViewState: getVariantsDefinition(),
            productVariantsViewState,
            selectedVariantParts,
            inputValue,
          });
          break;
        default:
          break;
      }
      // update the changed value : removal and addition of values.
    }

    // Save the input value when the inputs change
    function handleInputChange(_inputValue) {
      setEditVariantsViewState({
        variantsDefinitionsViewState,
        productVariantsViewState,
        selectedVariantParts,
        inputValue: _.set(inputValue, selectName, _inputValue),
      });
    }

    // Upon clicking enter/tab keys, the input value should be added to the corresponding
    // field definitions
    function handleKeyDown(event) {
      if (_.isEmpty(_.get(inputValue, selectName, null))) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          // add the input value to the list of values in the corresponding field definition
          addVariantDefinitionFieldValue(
            selectName,
            _.get(inputValue, selectName, null),
          );
          setEditVariantsViewState({
            variantsDefinitionsViewState: getVariantsDefinition(),
            productVariantsViewState,
            selectedVariantParts,
            inputValue: {},
          });
          event.preventDefault();
          break;
        default:
          break;
      }
    }

    return { handleChange, handleInputChange, handleKeyDown };
  };

  // Construct the array of variant part <Select/> components
  const variantDefinitionsSelectObjArr =
    getVariantDefinitionSelectsOptions(variantsDefinition);
  const variantDefinitionsSelectComponents = variantDefinitionsSelectObjArr.map(
    (selectObj, index) => {
      const { handleChange, handleInputChange, handleKeyDown } =
        VariantDefinitionSelectManager(selectObj.selectName);
      return (
        <Col>
          <Form.Group as={Row} key={'form-group-select-' + index}>
            <Form.Label column xs={3}>
              {selectObj.selectName}
            </Form.Label>
            <Col>
              <CreatableSelect
                key={'select-variant-definitions-' + index}
                isMulti
                value={selectObj.selectValue}
                inputValue={_.get(inputValue, selectObj.selectName, '')}
                menuIsOpen={false}
                isClearable={false}
                placeholder='Add values and press enter to start adding values to the field'
                onChange={handleChange}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                styles={variantDefinitionSelectStyles}
                components={{ DropdownIndicator: null }}
              />
            </Col>
          </Form.Group>
        </Col>
      );
    },
  );

  // Function object to handle changes to section variants select component.
  // In our usecase, the only need is to handle the remove value action.
  const ProductVariantsSelectOnChange = (selectObj, { action }) => {
    let _addedProductVariants = [];
    if (selectObj != null) {
      _addedProductVariants = selectObj.map((item) => {
        return item.original;
      });
    }
    switch (action) {
      case 'remove-value':
        setEditVariantsViewState({
          variantsDefinitionsViewState,
          productVariantsViewState: _addedProductVariants,
          selectedVariantParts,
          inputValue,
        });
        break;
      default:
        break;
    }
  };

  // Function object to handle changes to variant part select components
  const ProductVariantsPartSelectManager = (_selectName) => {
    // The originating select component name
    const selectName = _selectName;
    // Function to handle the change
    function handleChange(selectObj, { action }) {
      switch (action) {
        case 'clear':
          setEditVariantsViewState({
            variantsDefinitionsViewState,
            productVariantsViewState,
            selectedVariantParts: clearVariantPart(selectName),
            inputValue,
          });
          break;
        case 'select-option':
          // Add/Replace the selectedProductVariant
          const variantPart = {};
          variantPart[selectName] = selectObj.value;
          setEditVariantsViewState({
            variantsDefinitionsViewState,
            productVariantsViewState,
            selectedVariantParts: addVariantPart(variantPart),
            inputValue,
          });
          break;
        default:
          break;
      }
    }
    return { handleChange };
  };

  const ProductVariantsSelect = () => {
    const { selectOptions, value } =
      getProductVariantsSelectOptions(productVariants);
    return (
      <Form.Group key='form-group-product-variants'>
        <Select
          key='select-section-variants'
          className='basic-multi-select'
          isMulti
          value={value}
          isClearable={false}
          isSearchable={false}
          options={selectOptions}
          classNamePrefix='select'
          placeholder='No Product Variants Added.'
          styles={productVariantsSelectStyles}
          onChange={ProductVariantsSelectOnChange}
          components={{ DropdownIndicator: null }}
        />
      </Form.Group>
    );
  };

  // Selects for selecting variant parts
  const selectsObjArr = getProductVariantMapSelectsOptions();
  const selectComponents = selectsObjArr.map((selectObj, index) => {
    return (
      <Col>
        <Form.Group key={'form-group-select-' + index}>
          <p
            className='font-weight-bold m-0'
            key={'form-group-select-' + index + '-name'}
          >
            {selectObj.selectName}
          </p>
          <Select
            className='basic-single'
            key={'select-' + index}
            isClearable={true}
            value={selectObj.selectValue}
            onChange={
              ProductVariantsPartSelectManager(selectObj.selectName)
                .handleChange
            }
            options={selectObj.selectOptions}
            placeholder={'Select ' + selectObj.selectName}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
      </Col>
    );
  });

  // add the current selected variant parts to section
  const addClickHandler = () => {
    addSelectionToProductVariants();
    setEditVariantsViewState({
      variantsDefinitionsViewState,
      productVariantsViewState: getProductVariants(),
      selectedVariantParts: getSelectedVariantParts(),
      inputValue,
    });
  };

  // Should the apply button be enabled or not. It is enabled if either
  // field values have changed or product variants have changed.
  const enableApplyButton = () => {
    const hasVariantDefinitionsChanged = () => {
      return !_.isEqual(variantsDefinition, variantsDefinitionsViewState);
    };
    const hasProductVariantsChanged = () => {
      return !_.isEqual(productVariants, productVariantsViewState);
    };
    return hasVariantDefinitionsChanged() || hasProductVariantsChanged();
  };

  // Save the current section variant selections
  const applyChanges = () => {
    console.log(
      JSON.stringify(
        { variantsDefinitionsViewState, productVariantsViewState },
        null,
        2,
      ),
    );
    props.onHide();
  };

  // When Loading is false & data is available, display the data in the table
  return (
    <Modal show={true} onHide={props.onHide} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Variants</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-4'>Update Field Definitions</h5>
        {variantDefinitionsSelectComponents}
        <h5 className='mb-4'>Add/Remove Product Variants</h5>
        <Col key='col-product-variants-additions'>
          {ProductVariantsSelect()}
          <Row className='p-0 d-flex align-items-center' key='row-select-parts'>
            {selectComponents}
            <Col xs={'auto'} className='mt-2' key='col-add-button'>
              <Button
                key='add-button'
                onClick={addClickHandler}
                className='btn btn-dark'
                disabled={!isValidNewProductVariant()}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center p-2'>
        <Row>
          <Col>
            <Button
              ref={null}
              onClick={applyChanges}
              className='btn btn-dark'
              disabled={!enableApplyButton()}
            >
              Apply
            </Button>
          </Col>
          <Col>
            <Button variant='error' onClick={() => props.onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default EditVariants;
