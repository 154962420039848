import { createContext, useContext, useState } from 'react';

const AppContext = createContext(undefined);

const AppProvider = ({ children }) => {
  const [filteredOption, setFilteredOption] = useState(null);
  const [sortByOption, setSortByOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <AppContext.Provider
      value={{
        filteredOption,
        setFilteredOption,
        searchTerm,
        setSearchTerm,
        sortByOption,
        setSortByOption,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useAppContext };
