import { useApolloClient } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  MeDocument,
  OrgUsersDocument,
  useCreateNewUser,
} from 'graphql/gen.types';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const options = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
];

const CreateNewUser = (props) => {
  const { onClose } = props;

  const [selectedAccountRoleOption, setSelectedAccountRoleOption] =
    useState('member');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const client = useApolloClient();

  const { me } = client.readQuery({
    query: MeDocument,
  });

  const createNewUserInput = {
    accountId: me?.account?.id,
    email: email,
    firstName: firstName,
    lastName: lastName,
    accountRole: selectedAccountRoleOption?.toUpperCase(),
  };

  const [createNewUser, { error, loading }] = useCreateNewUser({
    variables: {
      input: createNewUserInput,
    },
    refetchQueries: [
      {
        query: OrgUsersDocument,
        variables: {
          accountId: me?.account?.id,
        },
      },
    ],
  });

  const isFormComplete = () => {
    return !!(firstName && email && lastName && selectedAccountRoleOption);
  };

  const shouldShowClear = () => {
    return !!(firstName || email || lastName || selectedAccountRoleOption);
  };

  // When Loading is false & data is available, display the data in the table
  if (error) {
    console.log(error);
    return (
      <Alert sx={{ zIndex: 1500, position: 'relative' }} severity='error'>
        Error performing network request
      </Alert>
    );
  }

  const clearData = () => {
    setSelectedAccountRoleOption('');
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const handleClose = () => {
    onClose();
    clearData();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={true} onClose={handleClose}>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Typography variant='h4' component='span'>
          Create User
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <TextField
            label='First Name'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <TextField
            label='Last Name'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <TextField
            label='Email'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormControl margin='normal' fullWidth>
            <InputLabel id='demo-simple-select-label'>User Role</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='User Role'
              size='medium'
              value={selectedAccountRoleOption}
              onChange={(e) => setSelectedAccountRoleOption(e.target.value)}
            >
              {options?.map((menuItem, index) => {
                const { value, label } = menuItem;
                return (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        {shouldShowClear() && <Button onClick={clearData}>Clear</Button>}
        <LoadingButton
          variant='contained'
          size='medium'
          disabled={!isFormComplete()}
          loading={loading}
          onClick={() => {
            if (firstName && email && selectedAccountRoleOption && lastName) {
              createNewUser({
                onCompleted: () => {
                  handleClose();
                },
              }).catch((error) => console.log(error));
            }
          }}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewUser;
