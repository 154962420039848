import React, { useState } from 'react';
import CreateNewModule from 'components/admin_console/module_dashboard/CreateNewModule';
import { Steps, Step } from 'react-step-builder';
import { Button } from '@mui/material';

const NewModule = () => {
  const [showNewModule, setShowNewModule] = useState(false);

  const handleSelectNewModule = () => {
    setShowNewModule(true);
  };

  const hideNewModule = () => {
    setShowNewModule(false);
  };

  return (
    <>
      <Button variant='contained' onClick={handleSelectNewModule}>
        New Module
      </Button>
      <Steps>
        <Step
          open={showNewModule}
          onClose={hideNewModule}
          component={CreateNewModule}
        />
      </Steps>
    </>
  );
};

export default NewModule;
