import React, { useEffect, useState } from 'react';
import {
  IsModuleSlugAvailableDocument,
  useModuleIconByIDQuery,
  useModuleInfoByIdQuery,
  useUpdateModuleAccessScope,
  useUpdateModuleIcon,
  useUpdateModuleName,
  useUpdateModuleSlug
} from 'graphql/gen.types';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFileUpload } from 'use-file-upload';
import { useMeContext } from 'context/meContext';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Check, CloudUpload } from '@mui/icons-material';

const moduleAccessScopeOptions = [
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' }
];

const REGEX_URL_SLUG = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

const ModuleInfo = (props) => {
  const { moduleId, onClose, open } = props;
  const {
    userData: {
      account: { id: accountId },
    },
  } = useMeContext();

  // eslint-disable-next-line
  const [file, selectFile] = useFileUpload();

  const [newModuleName, setNewModuleName] = useState(undefined);
  const [newSlug, setNewSlug] = useState(undefined);
  const [newModuleIcon, setNewModuleIcon] = useState(undefined);
  const [moduleAccessScope, setModuleAccessScope] = useState('');
  const [moduleIcon, setModuleIcon] = useState(undefined);
  const [currentModule, setCurrentModule] = useState(undefined);

  const handleModuleAccessScope = (e) => {
    setModuleAccessScope(e.target.value);
  };

  const {
    loading: moduleLoading,
    error: moduleError,
    data: moduleData,
  } = useModuleInfoByIdQuery({
    variables: { moduleId },
  });

  const {
    loading: iconLoading,
    data: iconData,
    refetch: iconRefetch,
  } = useModuleIconByIDQuery({
    variables: { moduleId },
    notifyOnNetworkStatusChange: true,
  });

  // set the states that this component works on
  useEffect(() => {
    if (!_.isEmpty(moduleData)) {
      setCurrentModule(moduleData.module);
    }
  }, [moduleData]);

  useEffect(() => {
    if (!_.isEmpty(iconData)) {
      setModuleIcon(iconData.moduleIcon);
    }
  }, [iconData]);

  // set the state variables to the current value
  useEffect(() => {
    if (!_.isEmpty(currentModule)) {
      setNewModuleName(currentModule.name);
      setNewSlug(currentModule.adminInfo.slug);
      setModuleAccessScope(currentModule.adminInfo.moduleAccessScope);
    }
  }, [currentModule]);

  useEffect(() => {
    if (!_.isEmpty(moduleIcon)) {
      setNewModuleIcon(moduleIcon);
    }
  }, [moduleIcon]);

  // related to name
  const [updateName, { loading: nameUpdating }] = useUpdateModuleName({
    variables: {
      moduleId: moduleId,
      name: newModuleName,
    },
  });

  const [updateModuleAccessScope] = useUpdateModuleAccessScope({
    variables: {
      moduleId: moduleId,
      accessScope: moduleAccessScope,
    },
  });

  const hasNameChanged = () => {
    return !_.eq(newModuleName?.trim(), currentModule.name.trim());
  };

  const resetName = () => {
    if (hasNameChanged()) {
      setNewModuleName(currentModule?.name);
    }
  };

  const submitUpdateName = () => {
    if (hasNameChanged()) {
      updateName().catch((err) => console.log(err));
    }
  };

  // related to slug
  const [updateSlug, { loading: slugUpdating }] = useUpdateModuleSlug({
    variables: {
      moduleId: moduleId,
      slug: newSlug?.trim(),
    },
  });

  const [
    executeIsSlugAvailable,
    { loading: isSlugAvailableLoading, data: isSlugAvailable },
  ] = useLazyQuery(IsModuleSlugAvailableDocument, {
    variables: {
      accountId: accountId,
      slug: newSlug,
    },
    fetchPolicy: 'network-only',
  });

  const hasSlugChanged = () => {
    return !_.eq(newSlug?.trim(), currentModule?.adminInfo?.slug);
  };

  const hasModuleAccessScopeChanged = () => {
    return !_.eq(
      moduleAccessScope?.trim(),
      currentModule?.adminInfo?.moduleAccessScope,
    );
  };

  useEffect(() => {
    if (hasModuleAccessScopeChanged() && !_.isEmpty(moduleAccessScope)) {
      updateModuleAccessScope();
    }
    // eslint-disable-next-line
  }, [moduleAccessScope]);

  const isSlugValid = () => {
    return REGEX_URL_SLUG.test(newSlug);
  };

  const slugAvailable = () => {
    return !isSlugAvailableLoading && isSlugAvailable?.isModuleSlugAvailable;
  };

  const slugFieldErrorState = () => {
    if (!hasSlugChanged()) {
      return false;
    }
    if (isSlugValid()) {
      return false;
    }
    return !(isSlugAvailableLoading || slugAvailable());
  };

  const slugFieldHelperText = () => {
    if (!hasSlugChanged()) {
      return null;
    } else if (!isSlugValid()) {
      return 'Invalid slug';
    } else if (isSlugAvailableLoading) {
      return null;
    } else if (!isSlugAvailable) {
      return 'Slug not available';
    } else {
      return null;
    }
  };

  const resetSlug = () => {
    setNewSlug(currentModule?.adminInfo?.slug);
  };

  const submitUpdateSlug = () => {
    if (hasSlugChanged() && isSlugValid()) {
      updateSlug().catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (hasSlugChanged() && isSlugValid()) {
      executeIsSlugAvailable().catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [newSlug]);

  // module icon related
  const fileSelectedHandler = ({ file }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setNewModuleIcon(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
  };

  const [updateIcon, { loading: updateIconLoading }] = useUpdateModuleIcon({
    variables: {
      moduleId: moduleId,
      icon: newModuleIcon,
    },
    onCompleted() {
      iconRefetch().catch((err) => console.log(err));
    },
  });

  const hasIconChanged = () => {
    return !_.eq(moduleIcon, newModuleIcon);
  };

  const resetIcon = () => {
    setNewModuleIcon(moduleIcon);
  };

  const submitUpdateIcon = () => {
    if (hasIconChanged()) {
      updateIcon().catch((err) => console.log(err));
    }
  };

  // when Loading is true, show a loading view
  if (moduleLoading) {
    return (
      <Dialog fullWidth maxWidth='sm' open={open}>
        <DialogContent className='d-flex justify-content-center'>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant='h4' component='span'>
          Module Info
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {moduleError ? (
          <Alert severity='error'>Error performing network request.</Alert>
        ) : (
          currentModule && (
            <Box>
              <TextField
                label='Module Identifier'
                size='medium'
                margin='normal'
                fullWidth
                value={currentModule?.moduleIdentifier}
                disabled
              />

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <TextField
                  label='Module Name'
                  size='medium'
                  margin='normal'
                  fullWidth
                  value={newModuleName}
                  onChange={(e) => setNewModuleName(e.target.value)}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      submitUpdateName();
                      ev.preventDefault();
                    } else if (ev.key === 'Escape') {
                      resetName();
                      ev.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment: nameUpdating ? (
                      <InputAdornment position='end'>
                        <CircularProgress
                          color='inherit'
                          size='40px'
                          sx={{ padding: '8px' }}
                        />
                      </InputAdornment>
                    ) : hasNameChanged() ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='submit changes'
                          onClick={submitUpdateName}
                        >
                          <Check />
                        </IconButton>
                        <IconButton
                          aria-label='cancel changes'
                          onClick={resetName}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <TextField
                  label='Slug'
                  id='textfield-slug'
                  size='medium'
                  margin='normal'
                  fullWidth
                  value={newSlug ? newSlug : ''}
                  onChange={(e) => setNewSlug(e.target.value)}
                  error={slugFieldErrorState()}
                  helperText={slugFieldHelperText()}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      submitUpdateSlug();
                      ev.preventDefault();
                    } else if (ev.key === 'Escape') {
                      resetSlug();
                      ev.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment: slugUpdating ? (
                      <InputAdornment position='end'>
                        <CircularProgress
                          color='inherit'
                          size='40px'
                          sx={{ padding: '8px' }}
                        />
                      </InputAdornment>
                    ) : hasSlugChanged() ? (
                      <InputAdornment position='end'>
                        {!slugFieldErrorState() && (
                          <IconButton
                            aria-label='submit changes'
                            onClick={submitUpdateSlug}
                          >
                            <Check />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label='cancel changes'
                          onClick={resetSlug}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Box>

              <FormControl margin='normal' fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Module Access Scope
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Module Access Scope'
                  value={moduleAccessScope}
                  onChange={handleModuleAccessScope}
                  size='medium'
                >
                  {moduleAccessScopeOptions?.map((menuItem, i) => {
                    const { label, value } = menuItem;
                    return (
                      <MenuItem key={i} value={value?.toUpperCase()}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: '16px',
                  gap: '8px',
                }}
              >
                <Avatar sx={{ height: '96px', width: '96px' }}>
                  <img
                    src={newModuleIcon}
                    alt=''
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Avatar>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '8px',
                  }}
                >
                  {!hasIconChanged() && (
                    <Button
                      variant='primary'
                      size='medium'
                      startIcon={<CloudUpload />}
                      onClick={handleSelectFile}
                    >
                      Replace
                    </Button>
                  )}
                  {hasIconChanged() && (
                    <LoadingButton
                      variant='primary'
                      loading={updateIconLoading || iconLoading}
                      onClick={submitUpdateIcon}
                      size='medium'
                    >
                      Save
                    </LoadingButton>
                  )}
                  {hasIconChanged() && !updateIconLoading && !iconLoading && (
                    <Button variant='primary' onClick={resetIcon} size='medium'>
                      Reset
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModuleInfo;
