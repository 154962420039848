import React, { memo, useEffect, useState } from 'react';
import { useMediaContentLazyQuery } from 'graphql/gen.types';
import { useEditCMSContext } from '../EditCmsContext';
import { faPlay, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDEditor from '@uiw/react-md-editor';
import DeleteContentBiteDialog from './DeleteContentBiteDialog';
import { Box, CircularProgress, IconButton } from '@mui/material';
import AudioBiteModal from '../cms_bites_modal/AudioBiteModal';
import { useAudioContext } from '../cms_context/AudioContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AudioPlayer = ({ maxDimension, audioContentFile }) => {
  return (
    <audio
      className='audio-player'
      style={{
        width: maxDimension,
        height: '56px',
        padding: '8px 8px 0px 8px',
      }}
      src={audioContentFile}
      controls={true}
      controlsList='nodownload'
    />
  );
};

const AudioBiteComponent = (props) => {
  const audioContentBite = props.contentBite;

  const [deleteContentBiteModal, setDeleteContentBiteModal] = useState(false);
  const [audioEditModal, setAudioEditModal] = useState(false);

  const maxDimension = 600;

  const [getMediaContent, { loading, error, data, refetch, networkStatus }] =
    useMediaContentLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const {
    setFile,
    setModalType,
    contentBiteId,
    deleteContentBiteLoading,
    deleteContentBite,
  } = useEditCMSContext();

  const { setPreview, setDurationMillis, setAudioDescription } =
    useAudioContext();

  const [audioContentFile, setAudioContentFile] = useState('');
  const onCloseDialog = () => setDeleteContentBiteModal(false);

  useEffect(() => {
    if (data) {
      setPreview(data?.mediaContent);
    }
  }, [data, setPreview]);

  useEffect(() => {
    if (error) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, refetch]);

  const closeModalType = () => {
    setModalType(null);
    setAudioEditModal(false);
    setFile('');
    setPreview('');
  };

  const audioFullSize = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    if (!error && !loading && data) {
      setAudioContentFile(data?.mediaContent);
    }
  }, [error, loading, data]);

  useEffect(() => {
    const audioElements = document.querySelectorAll('.audio-player');

    const handlePlay = (event) => {
      const currentAudio = event.target;

      audioElements.forEach((audio) => {
        if (audio !== currentAudio) {
          audio.pause();
        }
      });
    };

    audioElements.forEach((audio) => {
      audio.addEventListener('play', handlePlay);
    });

    return () => {
      audioElements.forEach((audio) => {
        audio.removeEventListener('play', handlePlay);
      });
    };
  }, [audioContentFile]);

  if (audioContentBite.__typename !== 'AudioBite') {
    return <p>Incorrect Bite Rendered</p>;
  }

  if (error) {
    return (
      <div>
        <p>Error fetching data</p>
      </div>
    );
  }

  return (
    <>
      <Box
        className='audio-bite-content'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          className='media-bite-card position-relative'
          sx={{
            width: maxDimension,
          }}
        >
          {loading || networkStatus === 4 ? (
            <Box sx={{ position: 'relative' }}>
              <AudioPlayer
                maxDimension={maxDimension}
                audioContentFile={audioContentFile}
              />
              <Box
                sx={{
                  top: '12px',
                  left: '4px',
                  position: 'absolute',
                  ...audioFullSize,
                }}
              >
                <Box sx={{ ...audioFullSize }}>
                  <IconButton color='inherit'>
                    <CircularProgress color='common' size={24} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ) : error ? (
            <Box sx={{ position: 'relative' }}>
              <AudioPlayer
                maxDimension={maxDimension}
                audioContentFile={audioContentFile}
              />
              <Box
                sx={{
                  top: '16px',
                  left: '8px',
                  position: 'absolute',
                  ...audioFullSize,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...audioFullSize,
                  }}
                >
                  <IconButton color='inherit' onClick={() => refetch()}>
                    <FontAwesomeIcon
                      style={{ width: '15px', height: '15px' }}
                      icon={faRotateRight}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ) : audioContentFile ? (
            <AudioPlayer
              maxDimension={maxDimension}
              audioContentFile={audioContentFile}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <AudioPlayer
                maxDimension={maxDimension}
                audioContentFile={audioContentFile}
              />
              <Box
                sx={{
                  top: '16px',
                  left: '8px',
                  position: 'absolute',
                  ...audioFullSize,
                }}
              >
                <Box sx={{ ...audioFullSize }}>
                  <IconButton
                    color='inherit'
                    onClick={() =>
                      getMediaContent({
                        variables: {
                          contentBiteId: audioContentBite?.id?.toString(),
                        },
                      })
                    }
                  >
                    <FontAwesomeIcon
                      style={{ width: '15px', height: '15px' }}
                      icon={faPlay}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}

          {audioContentBite?.audioDescription &&
            audioContentBite?.audioDescription.trim() !== '' && (
              <MDEditor.Markdown source={audioContentBite?.audioDescription} />
            )}
        </Box>

        <Box
          className='audio-action-buttons'
          sx={{
            top: '0px',
            right: '0',
            zIndex: '100',
            position: 'absolute',
          }}
        >
          <IconButton
            onClick={() => {
              props.handleEditItem();
              setModalType(null);
              setAudioEditModal(true);
              setAudioDescription(audioContentBite?.audioDescription);
              setDurationMillis(audioContentBite?.durationMillis);
              setPreview(data?.mediaContent);
            }}
            disableRipple
            sx={{ background: 'blue', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <EditIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            onClick={() => {
              props.handleDeleteItem();
              setDeleteContentBiteModal(true);
            }}
            disableRipple
            sx={{ background: 'red', color: 'white' }}
            aria-label='delete'
            size='medium'
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Box>

      {deleteContentBiteModal && (
        <DeleteContentBiteDialog
          onCloseDialog={onCloseDialog}
          deleteContentBite={deleteContentBite}
          deleteContentBiteLoading={deleteContentBiteLoading}
        />
      )}

      {contentBiteId?.toString() === audioContentBite?.id?.toString() && (
        <AudioBiteModal
          open={audioEditModal}
          closeModalType={closeModalType}
          edit={true}
        />
      )}
    </>
  );
};

export default memo(AudioBiteComponent);
