import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCMSContext } from '../CMSContext';
import { highlightSearchTerms, getHighlightedText } from '../Highlighter';

export const SearchResultItemCard = ({ children, onClick }) => {
  return (
    <>
      <Box sx={{ cursor: 'pointer', my: 2 }} onClick={onClick}>
        <Paper sx={{ padding: 2 }}>{children}</Paper>
      </Box>
    </>
  );
};

const SearchResultItem = ({ contentBite, moduleIdentifier }) => {
  const { item } = contentBite;

  const { searchQuery, setSearchQuery } = useCMSContext();

  const navigate = useNavigate();

  const onSearchResultItemSelected = (sectionSlug, contentBiteId) => {
    const sectionPath = sectionSlug ? `/${sectionSlug}` : '';
    const contentBitePath = contentBiteId ? `/${contentBiteId}` : '';
    navigate(`/cms/${moduleIdentifier}${sectionPath}${contentBitePath}`);
    setSearchQuery('');
  };

  let biteJSX;

  switch (item?.__typename) {
    case 'TextBite':
      biteJSX = (
        <>
          <SearchResultItemCard
            onClick={() => {
              onSearchResultItemSelected(item?.parent?.id, item?.id);
            }}
          >
            <Typography variant='h5' gutterBottom>
              {item?.parent?.title}
            </Typography>
            {highlightSearchTerms(item?.text, searchQuery?.toString())}
          </SearchResultItemCard>
        </>
      );
      break;
    case 'ImageBite':
      biteJSX = (
        <>
          <SearchResultItemCard
            onClick={() => {
              onSearchResultItemSelected(item?.parent?.id, item?.id);
            }}
          >
            <Typography variant='h5' gutterBottom>
              {item?.parent?.title}
            </Typography>
            {highlightSearchTerms(
              item?.imageDescription,
              searchQuery?.toString(),
            )}
          </SearchResultItemCard>
        </>
      );
      break;
    case 'VideoBite':
      biteJSX = (
        <>
          <SearchResultItemCard
            onClick={() => {
              onSearchResultItemSelected(item?.parent?.id, item?.id);
            }}
          >
            <Typography variant='h5' gutterBottom>
              {item?.parent?.title}
            </Typography>
            {highlightSearchTerms(
              item?.videoDescription,
              searchQuery?.toString(),
            )}
          </SearchResultItemCard>
        </>
      );
      break;
    case 'AudioBite':
      biteJSX = (
        <>
          <SearchResultItemCard
            onClick={() => {
              onSearchResultItemSelected(item?.parent?.id, item?.id);
            }}
          >
            <Typography variant='h5' gutterBottom>
              {item?.parent?.title}
            </Typography>
            {highlightSearchTerms(
              item?.audioDescription,
              searchQuery?.toString(),
            )}
          </SearchResultItemCard>
        </>
      );
      break;
    case 'YouTubeVideoBite':
      biteJSX = (
        <>
          <SearchResultItemCard
            onClick={() => {
              onSearchResultItemSelected(item?.parent?.id, item?.id);
            }}
          >
            <Typography variant='h5' gutterBottom>
              {item?.parent?.title}
            </Typography>
          </SearchResultItemCard>
        </>
      );
      break;
    default:
      biteJSX = (
        <>
          <SearchResultItemCard
            onClick={() => {
              onSearchResultItemSelected(item?.id);
            }}
          >
            <Typography variant='h5' gutterBottom>
              {getHighlightedText(item?.title, searchQuery?.toString())}
            </Typography>
          </SearchResultItemCard>
        </>
      );
  }

  return (
    <Box key={item?.id} id={item?.id}>
      {biteJSX}
    </Box>
  );
};

export default SearchResultItem;
