/* 404 Page (page that gets loaded when the URL is wrong) */
import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='m-4'>
      <h2>Page Not Found</h2>
      <Link to={'/'}>Go Home</Link>
    </div>
  );
};

export default PageNotFound;
