import _ from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { rVarCMSApp } from 'global/const';
import { useEffect, useState } from 'react';
import { useCMSContext } from './CMSContext';
import { useMutateAddSection, useMutateSectionTitle } from 'graphql/gen.types';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Check } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';

const settings = [
  { id: 'Rename', title: 'Rename', icon: <EditIcon /> },
  { id: 'Add SubSection', title: 'Add SubSection', icon: <AddIcon /> },
  { id: 'Cover', title: 'Cover', icon: <ImageIcon /> },
  { id: 'Delete', title: 'Delete', icon: <DeleteIcon /> },
];

export const SubSectionTitle = ({ handleCloseSection }) => {
  const { moduleId } = useReactiveVar(rVarCMSApp);

  const [title, setTitle] = useState('');

  const { sectionId } = useCMSContext();

  const addSubSectionInput = {
    title: title,
    moduleId: moduleId,
    parentId: sectionId ? sectionId : null,
  };

  const [addSubSection, { loading: subSectionLoading }] = useMutateAddSection();

  const addSubSectionItem = async () => {
    await addSubSection({
      variables: {
        input: addSubSectionInput,
      },
      onCompleted: () => {
        handleCloseSection();
      },
    });
  };

  const resetTitle = () => {
    setTitle('');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: 1,
          px: 2,
          py: 0.5,
        }}
      >
        <TextField
          label='SubSection Title'
          size='medium'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
              addSubSectionItem();
              e.preventDefault();
            } else if (e.key === 'Escape') {
              resetTitle();
              e.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: subSectionLoading ? (
              <InputAdornment position='end'>
                <CircularProgress
                  color='inherit'
                  size='40px'
                  sx={{ padding: '8px' }}
                />
              </InputAdornment>
            ) : title ? (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='submit changes'
                  onClick={addSubSectionItem}
                >
                  <Check />
                </IconButton>
                <IconButton aria-label='cancel changes' onClick={resetTitle}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      </Box>
    </>
  );
};

export const SectionTitle = ({ handleCloseSection }) => {
  const { id: moduleId } = useParams();

  const { sectionTitle, setSectionTitle, sectionId, setSectionId } =
    useCMSContext();

  const [contentTitle, setContentTitle] = useState('');

  const sectionTitleInput = {
    moduleId: moduleId,
    title: contentTitle,
    sectionId: sectionId,
  };

  const [updateSectionTitle, { loading: sectionTitleLoading }] =
    useMutateSectionTitle();

  const submitUpdateSectionTitle = async () => {
    try {
      await updateSectionTitle({
        variables: {
          input: sectionTitleInput,
        },
        onCompleted: () => {
          // Assuming successful update, clear the input
          setSectionTitle('');
          setSectionId(null);
          handleCloseSection();
        },
      });
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const hasSectionTitleChanged = () => {
    return !_.eq(sectionTitle, contentTitle);
  };

  const resetTitle = () => {
    setContentTitle(sectionTitle);
  };

  useEffect(() => {
    setContentTitle(sectionTitle);
  }, [sectionTitle]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: 1,
          px: 2,
          py: 0.5,
        }}
      >
        <TextField
          label='Change Title'
          size='medium'
          value={contentTitle}
          onChange={(e) => setContentTitle(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
              submitUpdateSectionTitle();
              e.preventDefault();
            } else if (e.key === 'Escape') {
              resetTitle();
              e.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: sectionTitleLoading ? (
              <InputAdornment position='end'>
                <CircularProgress
                  color='inherit'
                  size='40px'
                  sx={{ padding: '8px' }}
                />
              </InputAdornment>
            ) : contentTitle && hasSectionTitleChanged() ? (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='submit changes'
                  onClick={submitUpdateSectionTitle}
                >
                  <Check />
                </IconButton>
                <IconButton aria-label='cancel changes' onClick={resetTitle}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      </Box>
    </>
  );
};

export const SectionMenuSubItem = ({ anchorElSection, handleCloseSection }) => {
  const { showSectionModal } = useCMSContext();

  return (
    <>
      <Menu
        sx={{ ml: '45px' }}
        anchorEl={anchorElSection}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElSection)}
        onClose={handleCloseSection}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: 1,
            px: 2,
            py: 0.5,
          }}
        >
          {showSectionModal === 'subSection' ? (
            <SubSectionTitle handleCloseSection={handleCloseSection} />
          ) : (
            <SectionTitle handleCloseSection={handleCloseSection} />
          )}
        </Box>
      </Menu>
    </>
  );
};

export const SectionMenuSetting = ({
  anchorElSetting,
  handleCloseSettingMenu,
  handleOpenSection,
}) => {
  const {
    setShowSectionThumbnailModal,
    setDeleteSectionModal,
    sectionId,
    setSectionId,
    setShowSectionModal,
  } = useCMSContext();

  return (
    <>
      <Menu
        sx={{ mt: '40px' }}
        id='menu-appbar'
        anchorEl={anchorElSetting}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElSetting)}
        onClose={handleCloseSettingMenu}
      >
        {settings.map((setting) => {
          const { icon, id, title } = setting;
          return (
            <MenuItem
              key={id}
              onClick={(e) => {
                if (id === 'Rename') {
                  handleOpenSection(e);
                  setShowSectionModal('');
                } else if (id === 'Cover') {
                  setShowSectionThumbnailModal(true);
                } else if (id === 'Add SubSection') {
                  handleOpenSection(e);
                  setShowSectionModal('subSection');
                  setSectionId(sectionId);
                } else {
                  setDeleteSectionModal(true);
                }
                handleCloseSettingMenu();
                e.stopPropagation();
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  gap: 1,
                }}
              >
                {icon} <Typography>{title}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
