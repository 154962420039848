import MDEditor from '@uiw/react-md-editor';
import React from 'react';

const Markdown = ({ children }) => {
  return (
    <>
      <MDEditor.Markdown
        remarkRehypeOptions={{ allowDangerousHtml: true }}
        components={{
          code: ({ inline, children = [], className, ...props }) => {
            const txt = children[0] || '';

            return typeof txt === 'string' &&
              txt?.includes(`<b class="highlighter">`) ? (
              <code dangerouslySetInnerHTML={{ __html: txt }} />
            ) : (
              <code>{children}</code>
            );
          },
        }}
        source={children}
      />
    </>
  );
};

const getHighlightedText = (textContent, highlight) => {
  // Split textContent on highlight term, include term itself into parts, ignore case
  const parts = textContent?.split(new RegExp(`(${highlight})`, 'gi'));
  return parts?.map((part, index) => (
    <React.Fragment key={index}>
      {part.toLowerCase() === highlight.toLowerCase() ? (
        <b className='highlighter'>{part}</b>
      ) : (
        part
      )}
    </React.Fragment>
  ));
};

const highlightSearchTerms = (text, searchTerm) => {
  const regex = new RegExp('\\b\\w*' + searchTerm + '\\w*\\b', 'gi');

  const highlightedHTML = text?.replace(regex, (match) => {
    return `<b class="highlighter">${match}</b>`;
  });

  return <Markdown children={highlightedHTML} />;
};

export { getHighlightedText, highlightSearchTerms };
