import CloseIcon from '@mui/icons-material/Close';
import { CloudUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GetModuleStructureDocument,
  useModuleIconByIDQuery,
  useMutateModuleInHub,
} from 'graphql/gen.types';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFileUpload } from 'use-file-upload';

const HubModuleInfo = (props) => {
  const { hubId: hubModuleId } = useParams();

  const { moduleId, moduleInfo, onClose, ...rest } = props;

  const { loading, data, error, refetch } = useModuleIconByIDQuery({
    variables: {
      moduleId: moduleId?.toString(),
    },
  });

  useEffect(() => {
    if (error) {
      refetch();
    }
  }, [error, refetch]);

  const [file, selectFile] = useFileUpload();
  const [thumbnail, setThumbnail] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [category, setCategory] = useState('');

  const fileSelectedHandler = ({ name, size, source, file }) => {
    console.log('Files Selected', { name, size, source, file });
    const reader = new FileReader();
    reader.onload = () => {
      setThumbnail(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
    console.log(file);
  };

  const clearData = () => {
    setThumbnail('');
    setSubtitle('');
    setTitle('');
    setCategory('');
  };

  const handleClose = () => {
    onClose();
    clearData();
  };

  const handleCancel = () => {
    setThumbnail(data?.moduleIcon);
    setSubtitle(moduleInfo?.subtitle);
    setTitle(moduleInfo?.title);
    setCategory(moduleInfo?.category);
  };

  const hasChangedFiled = () => {
    return (
      !_.eq(data?.moduleIcon, thumbnail) ||
      !_.eq(moduleInfo?.title, title) ||
      !_.eq(moduleInfo?.subtitle, subtitle) ||
      !_.eq(moduleInfo?.category, category)
    );
  };

  useEffect(() => {
    if (!loading && data) {
      setThumbnail(data?.moduleIcon);
    }
  }, [loading, data]);

  useEffect(() => {
    setSubtitle(moduleInfo?.subtitle);
    setTitle(moduleInfo?.title);
    setCategory(moduleInfo?.category);
  }, [moduleInfo]);

  const mutateModuleInHubInput = {
    hubModuleId: hubModuleId,
    moduleId: moduleId,
    title: title,
    subtitle: subtitle,
    category: category,
  };

  const [mutateModuleInHub, { loading: mutateModuleInHubLoading }] =
    useMutateModuleInHub();

  const mutateModuleInHubItem = async () => {
    await mutateModuleInHub({
      variables: {
        input: mutateModuleInHubInput,
      },
      refetchQueries: [
        {
          query: GetModuleStructureDocument,
          variables: {
            moduleId: hubModuleId,
          },
        },
      ],
      onCompleted: () => {
        handleClose();
      },
    });
  };

  return (
    <>
      <Dialog fullWidth maxWidth='md' {...rest} onClose={handleClose}>
        <DialogTitle>
          <Typography variant='h4' component='span'>
            Module Info
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <TextField
              label='Hub Module Id'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={hubModuleId}
              disabled
            />

            <TextField
              label='Module Id'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={moduleInfo?.moduleId}
              disabled
            />

            <TextField
              label='Title'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              label='Subtitle'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />

            <TextField
              label='Category'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '16px',
              }}
            >
              <Tooltip
                arrow
                placement='top'
                title='This thumbnail is an optional element. By default, it utilizes the module icon. Note that changes made to this thumbnail are specific to this context and will not alter the default module icon appearance.'
              >
                <Avatar sx={{ height: '96px', width: '96px' }}>
                  <img
                    src={thumbnail}
                    alt=''
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Avatar>
              </Tooltip>

              <Button
                sx={{ mt: 0.5 }}
                variant='primary'
                size='medium'
                startIcon={<CloudUpload />}
                onClick={handleFileChange}
              >
                Replace
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {hasChangedFiled() && !mutateModuleInHubLoading && (
            <Button onClick={handleCancel}>Reset</Button>
          )}

          <LoadingButton
            disabled={!hasChangedFiled()}
            variant='contained'
            size='medium'
            loading={mutateModuleInHubLoading}
            onClick={() => {
              if (hasChangedFiled()) {
                mutateModuleInHubItem();
              }
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HubModuleInfo;
