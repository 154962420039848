import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { CMS_MODAL_SELECTION, rVarCMSModalContext } from 'global/const';
import EditAccess from './EditAccess';
import EditVariants from './EditVariants';
import SectionSettings from './SectionSettings';

const CMSModalHandler = () => {
  const modalContext = useReactiveVar(rVarCMSModalContext);
  const onModalHide = () => rVarCMSModalContext(undefined);

  let modal = <></>;

  switch (modalContext?.selection) {
    case CMS_MODAL_SELECTION.SECTION_SETTINGS:
      modal = (
        <div>
          <SectionSettings onHide={onModalHide} />
        </div>
      );
      break;
    case CMS_MODAL_SELECTION.EDIT_ACCESS:
      modal = (
        <div>
          <EditAccess onClose={onModalHide} />
        </div>
      );
      break;
    case CMS_MODAL_SELECTION.EDIT_VARIANTS:
      modal = (
        <div>
          <EditVariants onHide={onModalHide} />
        </div>
      );
      break;
    default:
      break;
  }
  return modal;
};

export default CMSModalHandler;
