import React from 'react';
import _ from 'lodash';

/***********************************************************
 * Region - GraphQL Conversion
 ***********************************************************/
// A function to object-ize product variants (App.ContentInfo.ProductVariants)
export const ObjectizeProductVariants = function (productVariants) {
  if (!productVariants || productVariants.length === 0) {
    return [];
  }
  // Construct a simpler flattened product variants list object
  const productVariantsObjArr = [];
  productVariants.forEach((item) => {
    const aProductVariantObj = {};
    item.variant.forEach((mappedVarientField) => {
      aProductVariantObj[mappedVarientField.fieldName] =
        mappedVarientField.fieldValue;
    });
    productVariantsObjArr.push(aProductVariantObj);
  });
  return productVariantsObjArr;
};

export const ObjectizeVariantFieldDefinitions = function (
  variantFieldsDefinition,
) {
  if (!variantFieldsDefinition || variantFieldsDefinition.length === 0) {
    return [];
  }
  // Construct a simpler flattened product variants list object
  const variantFieldDefinitionsObj = {};
  variantFieldsDefinition.forEach((item) => {
    variantFieldDefinitionsObj[item.fieldName] = item.fieldValues;
  });
  return variantFieldDefinitionsObj;
};

/***********************************************************
 * Region - Supporting UI utilities
 ***********************************************************/
// A function to help manage variant views/selections. All objects are in
// objecti-zed representation.
//
// Components :
// One for managing app level variant options : add/update field definitions & product variants
// One for managing section level variants : add/update section level variant settings
// One for managing CMS view : setting the variant view in which the CMS operates.
//
// Write unit tests for this. (Important)

// Manager for all views related to creating/editing variant definitions and product variants
// at the app level
export function AppVariantsManager(
  _variantsDefinition,
  _productVariants,
  _selectedVariantParts,
) {
  let productVariants = _productVariants;
  let variantsDefinition = _variantsDefinition;
  let selectedVariantParts = _selectedVariantParts;

  // Adds a value to an existing field definition
  function addVariantDefinitionFieldValue(fieldName, fieldValue) {
    variantsDefinition = { ...variantsDefinition };
    variantsDefinition[fieldName] = Object.assign(
      [],
      variantsDefinition[fieldName],
    );
    variantsDefinition[fieldName].push(fieldValue);
    return variantsDefinition;
  }

  // After removing a specific value, some UI libraries instead of returning the
  // removed element returns all the remaining elements. This funciton is used
  // in such cases.
  function replaceVariantDefinitionFieldValues(fieldName, fieldValues) {
    variantsDefinition = { ...variantsDefinition };
    variantsDefinition[fieldName] = Object.assign([], fieldValues);
    return variantsDefinition;
  }

  // Adds a field to variants definition
  function addVariantDefinitionField(fieldName) {
    if (variantsDefinition.length >= 2) {
      throw InvalidUsageException(`Only upto 2 variant fields are allowed`);
    }
    variantsDefinition = [...variantsDefinition];
    variantsDefinition[fieldName] = [];
    return variantsDefinition;
  }

  // Adds a variant part to the selectedVariantParts
  function addVariantPart(variantPart) {
    selectedVariantParts = { ...selectedVariantParts, ...variantPart };
    return selectedVariantParts;
  }

  // Clears a variant part from the selectedVariantParts
  function clearVariantPart(variantPartName) {
    selectedVariantParts = _.omit(selectedVariantParts, variantPartName);
    return selectedVariantParts;
  }

  // Checks if the selectedVariantParts constitutes a full valid new product variant
  function isValidNewProductVariant() {
    // Make sure all keys are present
    if (
      !_.isEqual(
        Object.keys(selectedVariantParts),
        Object.keys(variantsDefinition),
      )
    ) {
      return false;
    }
    const findResult = _.find(productVariants, (variant) =>
      _.isEqual(variant, selectedVariantParts),
    );
    return _.isEmpty(findResult);
  }

  // add selected parts to product variants
  function addSelectionToProductVariants() {
    if (isValidNewProductVariant()) {
      productVariants = [...productVariants];
      productVariants.push(selectedVariantParts);
    }
    selectedVariantParts = {};
    return productVariants;
  }

  // Select objects for populating single-select react-select UI element for
  // all the variant parts in the product variant mapper.
  function getProductVariantMapSelectsOptions() {
    return Object.keys(variantsDefinition).map((variantDefKey) => {
      const selectName = variantDefKey;
      const selectedOptionValue = _.get(
        selectedVariantParts,
        selectName,
        undefined,
      );
      // The select value must be initialized to null. An undefined selectValue
      // will not reset the state of the select
      let selectValue = null;
      if (selectedOptionValue !== undefined) {
        selectValue = {
          value: selectedOptionValue,
          label: selectedOptionValue,
        };
      }
      // Options is based on what's available for adding
      let selectOptions = [];
      if (!_.has(selectedVariantParts, selectName)) {
        // Send all values when there are no variant parts
        selectOptions = variantsDefinition[variantDefKey].map((fieldValue) => {
          return {
            value: fieldValue,
            label: fieldValue,
          };
        });
      }
      return {
        selectName,
        selectOptions,
        selectValue,
      };
    });
  }

  // Function that returns an object for react-select multi select component
  // with all currently selected section variants.
  function getProductVariantsSelectOptions(originalProductVariants) {
    const selectName = 'Product Variants';
    const selectOptions = null;
    const value = productVariants.map((productVariant) => {
      const value = _.join(Object.values(productVariant));
      // if the value is already included in the original product variants, it cannot be
      // removed
      const isFixed = originalProductVariants.some((orig) =>
        _.isEqual(productVariant, orig),
      );
      return { value, label: value, isFixed, original: productVariant };
    });
    return {
      selectName,
      selectOptions,
      value,
    };
  }

  // Function that returns an object for react-select multi select component
  // with all currently added field definition and values
  function getVariantDefinitionSelectsOptions(originalVariantsDefinition) {
    return Object.keys(variantsDefinition).map((variantDefKey) => {
      const selectName = variantDefKey;
      let selectValue = variantsDefinition[selectName].map((value) => {
        // if the value is already included in the original variants definition, it cannot be
        // removed
        const isFixed = originalVariantsDefinition[selectName].some(
          (orig) => orig === value,
        );
        return { value, label: value, isFixed, original: value };
      });
      let selectOptions = [];
      return {
        selectName,
        selectOptions,
        selectValue,
        inputValue: '',
      };
    });
  }

  function getSelectedVariantParts() {
    return selectedVariantParts;
  }

  function getProductVariants() {
    return productVariants;
  }

  function getVariantsDefinition() {
    return variantsDefinition;
  }

  return {
    addVariantPart,
    addVariantDefinitionFieldValue,
    replaceVariantDefinitionFieldValues,
    addVariantDefinitionField,
    clearVariantPart,
    isValidNewProductVariant,
    addSelectionToProductVariants,
    getProductVariantsSelectOptions,
    getProductVariantMapSelectsOptions,
    getVariantDefinitionSelectsOptions,
    getSelectedVariantParts,
    getProductVariants,
    getVariantsDefinition,
  };
}

// Manager for all views related to adding/removing variants to/from sections
export function SectionVariantManager(
  _variantsDefinition,
  _productVariants,
  _selectedVariantParts,
  _sectionVariants,
) {
  let variantsDefinition = _variantsDefinition;
  let productVariants = _productVariants;
  let selectedVariantParts = _selectedVariantParts;
  let sectionVariants = _sectionVariants;

  // Adds a variant part to the selectedVariantParts
  function addVariantPart(variantPart) {
    selectedVariantParts = { ...selectedVariantParts, ...variantPart };
    return selectedVariantParts;
  }

  // Clears a variant part from the selectedVariantParts
  function clearVariantPart(variantPartName) {
    selectedVariantParts = _.omit(selectedVariantParts, variantPartName);
    return selectedVariantParts;
  }

  // Checks if the selectedVariantParts constitutes a full valid variant
  function isValidVariant() {
    const findResult = _.find(productVariants, (variant) =>
      _.isEqual(variant, selectedVariantParts),
    );
    return findResult !== undefined;
  }

  // Select objects for populating single-select react-select UI element for
  // all the product variant parts.
  function getVariantPartSelectsOptions() {
    // If section variants are set, the productVariants to be used for the selects
    // options should include the ones that aren't already added to section
    let selectsProductVariants = productVariants;
    selectsProductVariants = _.differenceWith(
      productVariants,
      sectionVariants,
      _.isEqual,
    );

    return Object.keys(variantsDefinition).map((variantDefKey) => {
      const selectName = variantDefKey;
      const selectedOptionValue = _.get(
        selectedVariantParts,
        selectName,
        undefined,
      );
      // The select value must be initialized to null. An undefined selectValue
      // will not reset the state of the select
      let selectValue = null;
      if (selectedOptionValue !== undefined) {
        selectValue = {
          value: selectedOptionValue,
          label: selectedOptionValue,
        };
      }
      let selectOptions = [];
      if (_.isEmpty(selectedVariantParts)) {
        // Send all values when there are no variant parts
        selectOptions = _.uniqWith(
          selectsProductVariants.map((productVariant) => {
            return {
              value: productVariant[selectName],
              label: productVariant[selectName],
            };
          }),
          _.isEqual,
        );
      } else if (
        !isValidVariant() &&
        !_.has(selectedVariantParts, selectName)
      ) {
        const key = Object.keys(selectedVariantParts)[0];
        const value = Object.values(selectedVariantParts)[0];
        const filteredProductVariants = _.filter(
          selectsProductVariants,
          (productVariant) => {
            return (
              _.has(productVariant, key) && _.get(productVariant, key) === value
            );
          },
        );
        selectOptions = _.uniqWith(
          filteredProductVariants.map((filteredProductVariant) => {
            return {
              value: filteredProductVariant[selectName],
              label: filteredProductVariant[selectName],
            };
          }),
          _.isEqual,
        );
      }
      return {
        selectName,
        selectOptions,
        selectValue,
      };
    });
  }

  // Function used to add a selection to section variants
  function addSelectionToSection() {
    if (isValidVariant()) {
      sectionVariants = [...sectionVariants];
      sectionVariants.push(selectedVariantParts);
    }
    selectedVariantParts = {};
    return sectionVariants;
  }

  // Function that returns an object for react-select multi select component
  // with all currently selected section variants.
  function getSectionVariantsSelectOptions() {
    const selectName = 'Section Variants';
    const selectOptions = null;
    const value = sectionVariants.map((sectionVariant) => {
      const value = _.join(Object.values(sectionVariant));
      return { value, label: value, original: sectionVariant };
    });
    return {
      selectName,
      selectOptions,
      value,
    };
  }

  function getSelectedVariantParts() {
    return selectedVariantParts;
  }

  function getSectionVariants() {
    return sectionVariants;
  }

  return {
    addVariantPart,
    clearVariantPart,
    isValidVariant,
    getVariantPartSelectsOptions,
    addSelectionToSection,
    getSectionVariantsSelectOptions,
    getSelectedVariantParts,
    getSectionVariants,
  };
}

// Manager to help with setting a specific variant view in the CMS
export function CMSVariantViewManager(
  _variantsDefinition,
  _productVariants,
  _selectedVariantParts,
) {
  let productVariants = _productVariants;
  let variantsDefinition = _variantsDefinition;
  let selectedVariantParts = _selectedVariantParts;

  // Adds a variant part to the selectedVariantParts
  function addVariantPart(variantPart) {
    selectedVariantParts = { ...selectedVariantParts, ...variantPart };
    return selectedVariantParts;
  }

  // Clears a variant part from the selectedVariantParts
  function clearVariantPart(variantPartName) {
    selectedVariantParts = _.omit(selectedVariantParts, variantPartName);
    return selectedVariantParts;
  }

  // Checks if the selectedVariantParts constitutes a full valid variant
  function isValidVariant() {
    const findResult = _.find(productVariants, (variant) =>
      _.isEqual(variant, selectedVariantParts),
    );
    return findResult !== undefined;
  }

  // Select objects for populating single-select react-select UI element for
  // all the product variant parts.
  function getVariantPartSelectsOptions() {
    // If section variants are set, the productVariants to be used for the selects
    // options should include the ones that aren't already added to section
    let selectsProductVariants = productVariants;
    return Object.keys(variantsDefinition).map((variantDefKey) => {
      const selectName = variantDefKey;
      const selectedOptionValue = _.get(
        selectedVariantParts,
        selectName,
        undefined,
      );
      // The select value must be initialized to null. An undefined selectValue
      // will not reset the state of the select
      let selectValue = null;
      if (selectedOptionValue !== undefined) {
        selectValue = {
          value: selectedOptionValue,
          label: selectedOptionValue,
        };
      }
      let selectOptions = [];
      if (_.isEmpty(selectedVariantParts)) {
        // Send all values when there are no variant parts
        selectOptions = _.uniqWith(
          selectsProductVariants.map((productVariant) => {
            return {
              value: productVariant[selectName],
              label: productVariant[selectName],
            };
          }),
          _.isEqual,
        );
      } else if (
        !isValidVariant() &&
        !_.has(selectedVariantParts, selectName)
      ) {
        const key = Object.keys(selectedVariantParts)[0];
        const value = Object.values(selectedVariantParts)[0];
        const filteredProductVariants = _.filter(
          selectsProductVariants,
          (productVariant) => {
            return (
              _.has(productVariant, key) && _.get(productVariant, key) === value
            );
          },
        );
        selectOptions = _.uniqWith(
          filteredProductVariants.map((filteredProductVariant) => {
            return {
              value: filteredProductVariant[selectName],
              label: filteredProductVariant[selectName],
            };
          }),
          _.isEqual,
        );
      }
      return {
        selectName,
        selectOptions,
        selectValue,
      };
    });
  }

  function getSelectedVariantParts() {
    return selectedVariantParts;
  }

  return {
    addVariantPart,
    clearVariantPart,
    isValidVariant,
    getVariantPartSelectsOptions,
    getSelectedVariantParts,
  };
}

export const getVariantViewButtonText = (variantView) => {
  if (variantView === undefined) {
    return [
      'Variant View - ',
      <span key='var-view' className='badge badge-dark'>
        Global (＊)
      </span>,
    ];
  }
  return [
    'Variant View - ',
    <span key='var-view' className='badge badge-primary'>
      {_.join(Object.values(variantView)).toString()}
    </span>,
  ];
};

export const getVariantViewTextSimple = (variantView) => {
  if (variantView === undefined) {
    return '(＊)';
  }
  return _.join(Object.values(variantView)).toString();
};

function InvalidUsageException(_message) {
  return {
    message: _message,
    name: 'InvalidUsageException',
  };
}
