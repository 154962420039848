import React from 'react';
import MainLogo from '../../assets/knidal-logo-white.svg';
import { Avatar, Box } from '@mui/material';

const CompanyLogo = () => {
  const logoStyle = {
    width: '192px',
    height: '192px',
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Avatar sx={logoStyle} src={MainLogo} alt='logo' />
      </Box>
    </>
  );
};

export default CompanyLogo;
