import * as Yup from 'yup';

export const signInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
  password: Yup.string()
    .required('Please enter your password')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
    ),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter your password')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
    ),
  confirmPassword: Yup.string()
    .required('Please enter your password') // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of password.
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter your password')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
    ),
  confirmPassword: Yup.string()
    .required('Please enter your password') // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of password.
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  resetCode: Yup.string().required('Please enter your code').min(6),
});
