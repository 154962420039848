/* Dashboard component renders the Module Dashboard. The page where users land after logging in */

import { Box, Button, Typography } from '@mui/material';
import {
  ADMIN_CONSOLE_MODAL_SELECTION,
  rVarAdminConsoleModalContext,
} from 'global/const';
import React from 'react';

//Extra Columns are created to for the proper alignment
const UserDashboardHeader = () => {
  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  return (
    <Box className='mb-4'>
      <Box sx={boxStyle}>
        <Box>
          <Typography variant='h4'>Users</Typography>
        </Box>
        <Box>
          <Button
            variant='contained'
            onClick={() =>
              rVarAdminConsoleModalContext({
                selection: ADMIN_CONSOLE_MODAL_SELECTION.NEW_USER,
              })
            }
          >
            New User
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDashboardHeader;
