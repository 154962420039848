// Function to render a contentbite depending on the type of contentbite
import { getDimensions } from 'components/utils/CommonFunction';
import { useEditCMSContext } from '../EditCmsContext';
import TextBiteComponent from './TextBiteComponent';
import { Box } from '@mui/material';
import ImageBiteComponent from './ImageBiteComponent';
import MDEditor from '@uiw/react-md-editor';
import VideoBiteComponent from './VideoBiteComponent';
import AudioBiteComponent from './AudioBiteComponent';
import YouTubeVideoBiteComponent from './YouTubeVideoBiteComponent';
import {
  ID_CREATE_AUDIO_BITE,
  ID_CREATE_IMAGE_BITE,
  ID_CREATE_VIDEO_BITE,
  ID_CREATE_YOUTUBE_VIDEO_BITE,
} from '../Content_Bites_List';

export const ContentBiteComponent = ({ item }) => {
  const { setShowEdit, setContentBiteId, setTextBite, setModalType } =
    useEditCMSContext();

  const maxDimension = 600;

  const contentBite = item;

  let biteJSX = <></>;

  switch (contentBite.__typename) {
    case 'TextBite':
      const handleEditTextBite = () => {
        setShowEdit(true);
        setContentBiteId(contentBite?.id);
        setTextBite(contentBite.text);
      };

      const handleDeleteTextBite = () => {
        setContentBiteId(contentBite?.id);
      };

      biteJSX = (
        <>
          <TextBiteComponent
            handleEditItem={handleEditTextBite}
            handleDeleteItem={handleDeleteTextBite}
            contentBite={contentBite}
          />
        </>
      );
      break;
    case 'ImageBite':
      const handleEditImageBite = () => {
        setContentBiteId(contentBite?.id);
        setModalType(ID_CREATE_IMAGE_BITE);
      };

      const handleDeleteImageBite = () => {
        setContentBiteId(contentBite?.id);
      };

      const imageSizeData = JSON.parse(contentBite?.imageSize);
      const imageResolution = getDimensions(imageSizeData, 600);
      const imageDescription = contentBite?.imageDescription;

      biteJSX = (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              className='media-bite-card'
              sx={{
                width: `${imageResolution?.width}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box sx={imageResolution?.mediaStyle}>
                <ImageBiteComponent
                  handleEditItem={handleEditImageBite}
                  handleDeleteItem={handleDeleteImageBite}
                  contentBite={contentBite}
                />
              </Box>
              {imageDescription && imageDescription.trim() !== '' && (
                <MDEditor.Markdown source={imageDescription} />
              )}
            </Box>
          </Box>
        </>
      );
      break;
    case 'VideoBite':
      const handleEditVideoBite = () => {
        setContentBiteId(contentBite?.id);
        setModalType(ID_CREATE_VIDEO_BITE);
      };

      const handleDeleteVideoBite = () => {
        setContentBiteId(contentBite?.id);
      };

      const videoSizeData = JSON.parse(contentBite?.videoSize);
      const videoResolution = getDimensions(videoSizeData, maxDimension);
      const videoDescription = contentBite?.videoDescription;

      biteJSX = (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              className='media-bite-card'
              sx={{
                width: `${videoResolution?.width}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box sx={videoResolution?.mediaStyle}>
                <VideoBiteComponent
                  handleEditItem={handleEditVideoBite}
                  handleDeleteItem={handleDeleteVideoBite}
                  contentBite={contentBite}
                />
              </Box>
              {videoDescription && videoDescription.trim() !== '' && (
                <MDEditor.Markdown source={videoDescription} />
              )}
            </Box>
          </Box>
        </>
      );
      break;
    case 'AudioBite':
      const handleEditAudioBite = () => {
        setContentBiteId(contentBite?.id);
        setModalType(ID_CREATE_AUDIO_BITE);
      };

      const handleDeleteAudioBite = () => {
        setContentBiteId(contentBite?.id);
      };

      biteJSX = (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AudioBiteComponent
              handleEditItem={handleEditAudioBite}
              handleDeleteItem={handleDeleteAudioBite}
              contentBite={contentBite}
            />
          </Box>
        </>
      );
      break;
    case 'YouTubeVideoBite':
      const handleEditYouTubeVideoBite = () => {
        setContentBiteId(contentBite?.id);
        setModalType(ID_CREATE_YOUTUBE_VIDEO_BITE);
      };

      const handleDeleteYouTubeVideoBite = () => {
        setContentBiteId(contentBite?.id);
      };

      biteJSX = (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: `${maxDimension}px`,
              }}
            >
              <YouTubeVideoBiteComponent
                handleEditItem={handleEditYouTubeVideoBite}
                handleDeleteItem={handleDeleteYouTubeVideoBite}
                contentBite={contentBite}
              />
            </Box>
          </Box>
        </>
      );
      break;
    default:
      return <></>;
  }

  return (
    <Box sx={{ padding: '8px 16px' }} key={item.id} id={item.id}>
      {biteJSX}
    </Box>
  );
};
