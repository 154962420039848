import React, { useEffect, createContext, useState, useContext } from 'react';
import { useMeQuery } from 'graphql/gen.types';
import { Alert, CircularProgress } from '@mui/material';
import { rVarHasUsersAccessRole } from 'global/const';

const MeContext = createContext();

export const targetRoles = ['ACCOUNT_OWNER', 'ADMIN'];

const MeProvider = ({ children }) => {
  const [userData, setMeData] = useState({});
  const [hasUsersAccessRole, setHasUsersAccessRole] = useState(undefined);

  const { data, loading, error } = useMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data && data.me) {
      // Assuming you have a 'me' field in your GraphQL query
      const hasUsersAccessRole = targetRoles.some((role) =>
        data?.me?.accountRole?.includes(role),
      );
      setMeData(data.me);
      setHasUsersAccessRole(hasUsersAccessRole);
      rVarHasUsersAccessRole({
        hasUsersAccessRole: hasUsersAccessRole,
      });
    }
  }, [data]);

  if (loading) {
    return (
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    console.error('GraphQL Error:', error);
    return <Alert severity='error'>Error performing network request.</Alert>;
  }

  return (
    <MeContext.Provider
      value={{
        userData,
        hasUsersAccessRole,
      }}
    >
      {children}
    </MeContext.Provider>
  );
};

const useMeContext = () => {
  return useContext(MeContext);
};

export { MeContext, MeProvider, useMeContext };
