/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useMeQuery } from 'graphql/gen.types';
import React, { useMemo, useState } from 'react';
import { accountRoleToDisplayText } from '../../utils/CommonFunction';

// A function to map response data to display data
const queryDataMapper = function (queryData) {
  if (!queryData) return {};
  const user = queryData.me;
  return {
    name: user.firstName + ' ' + user.lastName,
    email: user.email,
    phone: user.phone,
    accountRole: user.accountRole,
  };
};

const ProfileSettings = (props) => {
  const [show, setShow] = useState(true);
  const [editing, setEditing] = useState(false);

  const nameInput = React.createRef();
  // const phoneNumberInput = React.createRef();

  const handleClose = () => {
    setShow(false);
    props.onHide();
  };

  // const handleClick = () => {
  //   if (editing) {
  //     // Submit the name & phone number to the backend
  //   }
  //   setEditing(!editing);
  // };

  const { loading, error, data } = useMeQuery();

  const displayData = useMemo(() => queryDataMapper(data), [data]);

  // When Loading is true, show a loading view
  if (loading) {
    return (
      <Dialog fullWidth maxWidth='md' open={true} onClose={handleClose}>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog fullWidth maxWidth='md' open={true} onClose={handleClose}>
        <DialogContent>
          <Alert severity='error'>Error performing network request.</Alert>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth maxWidth='md' open={true} onClose={handleClose}>
      <DialogTitle>
        <Typography variant='h4' component='span'>
          Profile
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <TextField
            disabled
            label='Full Name'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            defaultValue={displayData.name}
            inputProps={{ readOnly: true }}
            inputRef={nameInput}
          />
          <TextField
            disabled
            label='Email'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            defaultValue={displayData.email}
            inputProps={{ readOnly: true }}
          />
          <TextField
            disabled
            label='Role'
            id='outlined-size-small'
            size='medium'
            margin='normal'
            fullWidth
            defaultValue={accountRoleToDisplayText(displayData.accountRole)}
            inputProps={{ readOnly: true }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileSettings;
