import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Logout = (props) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await signOut();
      localStorage.clear();
      navigate('/login');
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log('error signing out: ', error);
      toast.error(error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.onHide();
  };

  return (
    <Dialog fullWidth={true} maxWidth='xs' open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>Are you sure you want to Logout.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          type='submit'
          onClick={handleSignOut}
        >
          Yes, I'm sure
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Logout;
