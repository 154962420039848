import _ from 'lodash';

export const AppSectionsToReactTree = (_appId, _sections) => {
  const appId = _appId;
  const sections = _sections;

  const build = () => {
    let active = null;
    const tree = {};
    tree.title = 'Sections List';
    tree.id = appId;
    tree.children = [];

    const buildSection = (section) => {
      const _section = {};
      _section.id = section.id;
      _section.title = section.title;
      _section.is_global = isSectionGlobal(section);

      // if this is the first section
      if (tree.children.length === 0) {
        active = _section;
      }

      if (section.subsections && section.subsections.length !== 0) {
        _section.collapsed = true;
        _section.children = section.subsections?.map(buildSection);
      } else {
        _section.leaf = true;
      }

      return _section;
    };

    tree.children = sections?.map(buildSection);

    return { active, tree };
  };

  const isSectionGlobal = (section) => {
    return _.isEmpty(section.objectizedVariants);
  };

  return { build };
};

export const ReorderSectionsToReactTree = (_sections) => {
  const sections = _sections;

  const build = () => {
    const tree = {};
    tree.sections = [];

    const buildSection = (section) => {
      const _section = {
        id: section.id,
        subsections: [],
      };

      if (section?.children && section?.children?.length !== 0) {
        _section.subsections = section?.children?.map(buildSection);
      }

      return _section;
    };

    return (tree.sections = sections?.map(buildSection));
  };

  return { build };
};

// Given sections content, this function returns the content of a specific section/subsection which matches the
// the id passed
export const SectionByID = (id, sections) => {
  if (!sections || typeof sections[Symbol.iterator] !== 'function') {
    return null;
  }

  const findInSection = (section) => {
    if (section.id === id) {
      return section;
    }

    if (section.subsections && section.subsections.length > 0) {
      for (const subsection of section.subsections) {
        const found = findInSection(subsection);
        if (found) {
          return found;
        }
      }
    }

    return null;
  };

  for (const section of sections) {
    const foundSection = findInSection(section);
    if (foundSection) {
      return foundSection;
    }
  }

  return null;
};

// For printing sections which have valid product variants assigned to it.
// ONLY TO HELP TESTING WHILE USING MOCK DATA.
export const PrintSectionsWithValidVariantAssignments = (app) => {
  const productVariants = app.contentInfo.objectizedProductVariants;
  console.log(
    'Printing sections which have valid product variants assigned to it',
  );
  app.sections.forEach((section) => {
    const intersections = _.intersectionWith(
      productVariants,
      section.objectizedVariants,
      _.isEqual,
    );
    if (!_.isEmpty(intersections)) {
      console.log(
        JSON.stringify({ title: section.title, intersections }, null, 2),
      );
    }
  });
};
