import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

export const AuthRoute = ({ children }) => {
  const {
    authState: { isAuthenticated },
  } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={'/'} replace />;
  }

  if (isAuthenticated === false) return children ? children : <Outlet />;
};
