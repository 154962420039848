import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

const defaultOptions = {};

export let UserRole;
(function (UserRole) {
  UserRole['Admin'] = 'Admin';
  UserRole['Author'] = 'Author';
  UserRole['Reviewer'] = 'Reviewer';
  UserRole['SuperAdmin'] = 'SuperAdmin';
})(UserRole || (UserRole = {}));

export let UserStatus;
(function (UserStatus) {
  UserStatus['Active'] = 'Active';
  UserStatus['Deactivated'] = 'Deactivated';
})(UserStatus || (UserStatus = {}));

export const ModuleByIdDocument = gql`
  query ModuleByID($moduleId: ID!) {
    module(id: $moduleId) {
      id
      createdAt
      name
      moduleType
      moduleIdentifier
      adminInfo {
        id
        slug
        variantFieldsDefinition {
          fieldName
          fieldValues
        }
      }
      contentInfo {
        id
        productVariants {
          variant {
            fieldName
            fieldValue
          }
        }
      }
      sections {
        id
        title
        paid
        content {
          ... on TextBite {
            id
            createdAt
            text
          }
          ... on ImageBite {
            id
            createdAt
            imageDescription
            imageSize
          }
          ... on AudioBite {
            id
            createdAt
            audioDescription
            durationMillis
          }
          ... on VideoBite {
            id
            createdAt
            videoDescription
            videoSize
            disableAudio
            loop
            durationMillis
          }
          ... on YouTubeVideoBite {
            id
            createdAt
            ytVideoId
          }
        }
        subsections {
          id
          title
          paid
          content {
            ... on TextBite {
              id
              createdAt
              text
            }
            ... on ImageBite {
              id
              createdAt
              imageDescription
              imageSize
            }
            ... on AudioBite {
              id
              createdAt
              audioDescription
              durationMillis
            }
            ... on VideoBite {
              id
              createdAt
              videoDescription
              videoSize
              disableAudio
              loop
              durationMillis
            }
            ... on YouTubeVideoBite {
              id
              createdAt
              ytVideoId
            }
          }
          subsections {
            id
            title
            paid
            content {
              ... on TextBite {
                id
                createdAt
                text
              }
              ... on ImageBite {
                id
                createdAt
                imageDescription
                imageSize
              }
              ... on AudioBite {
                id
                createdAt
                audioDescription
                durationMillis
              }
              ... on VideoBite {
                id
                createdAt
                videoDescription
                videoSize
                disableAudio
                loop
                durationMillis
              }
              ... on YouTubeVideoBite {
                id
                createdAt
                ytVideoId
              }
            }
            subsections {
              id
              title
              paid
              content {
                ... on TextBite {
                  id
                  createdAt
                  text
                }
                ... on ImageBite {
                  id
                  createdAt
                  imageDescription
                  imageSize
                }
                ... on AudioBite {
                  id
                  createdAt
                  audioDescription
                  durationMillis
                }
                ... on VideoBite {
                  id
                  createdAt
                  videoDescription
                  videoSize
                  disableAudio
                  loop
                  durationMillis
                }
                ... on YouTubeVideoBite {
                  id
                  createdAt
                  ytVideoId
                }
              }
              subsections {
                id
                title
                paid
                content {
                  ... on TextBite {
                    id
                    createdAt
                    text
                  }
                  ... on ImageBite {
                    id
                    createdAt
                    imageDescription
                    imageSize
                  }
                  ... on AudioBite {
                    id
                    createdAt
                    audioDescription
                    durationMillis
                  }
                  ... on VideoBite {
                    id
                    createdAt
                    videoDescription
                    videoSize
                    disableAudio
                    loop
                    durationMillis
                  }
                  ... on YouTubeVideoBite {
                    id
                    createdAt
                    ytVideoId
                  }
                }
              }
            }
          }
        }
        productVariants {
          variant {
            fieldName
            fieldValue
          }
        }
      }
    }
  }
`;

export function useModuleByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ModuleByIdDocument, options);
}

export const ModuleIconByIDQuery = gql`
  query ModuleIconByID($moduleId: ID!) {
    moduleIcon(moduleId: $moduleId)
  }
`;

export function useModuleIconByIDQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ModuleIconByIDQuery, options);
}

export function useModuleIconByIDLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ModuleIconByIDQuery, options);
}

export const ModuleInfoByIdDocument = gql`
  query ModuleInfoByID($moduleId: ID!) {
    module(id: $moduleId) {
      id
      name
      moduleType
      moduleIdentifier
      contentInfo {
        id
        productVariants {
          variant {
            fieldName
            fieldValue
          }
        }
      }
      adminInfo {
        id
        slug
        moduleAccessScope
        variantFieldsDefinition {
          fieldName
          fieldValues
        }
      }
    }
  }
`;

export function useModuleInfoByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ModuleInfoByIdDocument, options);
}

export function useModuleInfoByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ModuleInfoByIdDocument, options);
}

export const ModuleListingDocument = gql`
  query ModulesListing($accountId: ID!) {
    modules(accountId: $accountId) {
      id
      createdAt
      name
      moduleType
      moduleIdentifier
      adminInfo {
        id
        slug
        moduleAccessScope
      }
    }
  }
`;

export function useModuleListingQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ModuleListingDocument, options);
}

export const MeDocument = gql`
  query Me {
    me {
      id
      firstName
      lastName
      email
      accountRole
      userConfirmed
      moduleAccessList {
        moduleId
        moduleRole
      }
      account {
        id
        slug
        name
        brandColor
        colorOnBrand
      }
    }
  }
`;

export function useMeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(MeDocument, options);
}

export const SearchMembersDocument = gql`
  query SearchMembers($input: SearchMemberInput!) {
    searchMembers(input: $input) {
      id
      firstName
      lastName
      email
      accountRole
      userConfirmed
      moduleAccessList {
        moduleId
        moduleRole
      }
      account {
        id
        slug
        name
        brandColor
        colorOnBrand
      }
    }
  }
`;

export function useSearchMembersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(SearchMembersDocument, options);
}

export function useSearchMembersLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(SearchMembersDocument, options);
}

export const OrgSettingsDocument = gql`
  query OrgSettings($accountId: ID!) {
    _account(accountId: $accountId) {
      id
      name
      brandColor
      colorOnBrand
    }
  }
`;

export function useOrgSettingsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(OrgSettingsDocument, options);
}

export function useOrgSettingsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(OrgSettingsDocument, options);
}

export const OrgUsersDocument = gql`
  query OrgUsers($accountId: ID!) {
    users(accountId: $accountId) {
      id
      firstName
      lastName
      email
      accountRole
      userConfirmed
    }
  }
`;

/**
 * __useOrgUsersQuery__
 *
 * To run a query within a React component, call `useOrgUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUsersQuery({
 *   variables: {
 *   },
 * });
 */

export function useOrgUsersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(OrgUsersDocument, options);
}

export const UserByIdDocument = gql`
  query UserByID($userID: ID!) {
    user(id: $userID) {
      id
      firstName
      lastName
      email
      accountRole
      userConfirmed
      moduleAccessList {
        moduleId
        moduleRole
      }
    }
  }
`;

export function useUserByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UserByIdDocument, options);
}

export const ModuleAccessUsersDocument = gql`
  query ModuleAccessUsers($moduleId: ID!) {
    moduleAccessUsers(moduleId: $moduleId) {
      id
      firstName
      lastName
      email
      accountRole
      moduleAccessList {
        moduleId
        moduleRole
      }
    }
  }
`;

export function useModuleAccessUsersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ModuleAccessUsersDocument, options);
}

export const GetMediaContentData = gql`
  query GetMediaContentData($contentBiteId: ID!) {
    mediaContent(contentBiteId: $contentBiteId)
  }
`;

export function useMediaContentQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetMediaContentData, options);
}

export function useMediaContentLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetMediaContentData, options);
}

export const GetIsModuleIdentifierAvailable = gql`
  query IsModuleIdentifierAvailable($moduleIdentifier: String!) {
    isModuleIdentifierAvailable(moduleIdentifier: $moduleIdentifier)
  }
`;

export function useIsModuleIdentifierAvailableQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetIsModuleIdentifierAvailable, options);
}

export function useIsModuleIdentifierAvailableLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetIsModuleIdentifierAvailable, options);
}

export const ModuleIconQueryDocument = gql`
  query ModuleIcon($moduleId: ID!) {
    moduleIcon(moduleId: $moduleId)
  }
`;

export function useModuleIconQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ModuleIconQueryDocument, options);
}

export const GetMediaContent = gql`
  query GetMediaContentData($contentBiteId: ID!) {
    mediaContent(contentBiteId: $contentBiteId)
  }
`;

export function useGetMediaContentQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetMediaContent, options);
}

export function useGetMediaContentLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetMediaContent, options);
}

export const GetVideoPosterFrame = gql`
  query GetVideoPosterFrame($videoBiteId: ID!) {
    posterFrame(videoBiteId: $videoBiteId)
  }
`;

export function useGetVideoPosterFrameQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetVideoPosterFrame, options);
}

export function useGetVideoPosterFrameLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetMediaContent, options);
}

// Mutate graphql query
export const AddTextBiteDocument = gql`
  mutation AddTextBite($input: AddTextBiteInput!) {
    addTextBite(input: $input) {
      id
      content {
        ... on TextBite {
          id
          createdAt
          text
        }
      }
    }
  }
`;

export function useAddTextBiteQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddTextBiteDocument, options);
}

export const UpdateTextBiteDocument = gql`
  mutation UpdateText($input: UpdateTextInput!) {
    updateText(input: $input) {
      id
      createdAt
      text
    }
  }
`;

export function useUpdateTextBiteQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateTextBiteDocument, options);
}

export const UpdateAccountBrandColorsDocument = gql`
  mutation UpdateAccountBrandColors(
    $accountId: ID!
    $brandColor: HexColorCode!
    $colorOnBrand: HexColorCode!
  ) {
    updateAccountBrandColors(
      accountId: $accountId
      brandColor: $brandColor
      colorOnBrand: $colorOnBrand
    ) {
      id
      name
      brandColor
      colorOnBrand
    }
  }
`;

export function useUpdateAccountBrandColors(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateAccountBrandColorsDocument, options);
}

export const UpdateAccountLogo = gql`
  mutation UpdateAccountLogo($accountId: ID!, $logo: DataURL!) {
    updateAccountLogo(accountId: $accountId, logo: $logo) {
      id
      name
      brandColor
      colorOnBrand
    }
  }
`;

export function useUpdateAccountLogo(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateAccountLogo, options);
}

export const CreateNewUser = gql`
  mutation CreateNewUser($input: CreateNewUserInput!) {
    createNewUser(input: $input) {
      id
      firstName
      lastName
      email
      accountRole
    }
  }
`;

export function useCreateNewUser(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateNewUser, options);
}

export const AddImageBiteDocument = gql`
  mutation AddImageBite($input: AddImageBiteInput!) {
    addImageBite(input: $input) {
      id
      content {
        ... on ImageBite {
          id
          createdAt
          imageDescription
          imageSize
        }
      }
    }
  }
`;

export function useMutateImageBiteQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddImageBiteDocument, options);
}

export const UpdateImageBiteDocument = gql`
  mutation UpdateImage($input: UpdateImageInput!) {
    updateImage(input: $input) {
      id
      createdAt
      imageDescription
      imageSize
    }
  }
`;

export function useUpdateImageBiteQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateImageBiteDocument, options);
}

export const UpdateDescriptionDocument = gql`
  mutation UpdateDescription($input: UpdateMediaDescriptionInput!) {
    updateDescription(input: $input) {
      ... on ImageBite {
        id
        createdAt
        imageDescription
        imageSize
      }
      ... on AudioBite {
        id
        createdAt
        audioDescription
        durationMillis
      }
      ... on VideoBite {
        id
        createdAt
        videoDescription
        videoSize
        disableAudio
        loop
        durationMillis
      }
    }
  }
`;

export function useUpdateDescriptionQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateDescriptionDocument, options);
}

export const AddAudioBiteDocument = gql`
  mutation AddAudioBite($input: AddAudioBiteInput!) {
    addAudioBite(input: $input) {
      id
      content {
        ... on AudioBite {
          id
          createdAt
          audioDescription
          durationMillis
        }
      }
    }
  }
`;

export function useMutateAudioBiteQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddAudioBiteDocument, options);
}

export const UpdateAudioBiteDocument = gql`
  mutation UpdateAudio($input: UpdateAudioInput!) {
    updateAudio(input: $input) {
      id
      createdAt
      audioDescription
      durationMillis
    }
  }
`;

export function useUpdateAudioBiteQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateAudioBiteDocument, options);
}

export const AddVideoBiteDocument = gql`
  mutation AddVideoBite($input: AddVideoBiteInput!) {
    addVideoBite(input: $input) {
      id
      content {
        ... on VideoBite {
          id
          createdAt
          videoDescription
          videoSize
          disableAudio
          loop
          durationMillis
        }
      }
    }
  }
`;

export function useAddVideoBite(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddVideoBiteDocument, options);
}

export const UpdateVideoBiteDocument = gql`
  mutation UpdateVideo($input: UpdateVideoInput!) {
    updateVideo(input: $input) {
      id
      createdAt
      videoDescription
      videoSize
      disableAudio
      loop
      durationMillis
    }
  }
`;

export function useUpdateVideoBite(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateVideoBiteDocument, options);
}

export const MutateYouTubeVideoBiteDocument = gql`
  mutation MutateYouTubeVideoBite($input: MutateYouTubeVideoBiteInput!) {
    mutateYouTubeVideoBite(input: $input) {
      id
      content {
        ... on YouTubeVideoBite {
          id
          createdAt
          ytVideoId
        }
      }
    }
  }
`;

export function useMutateYouTubeVideoBite(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateYouTubeVideoBiteDocument, options);
}

export const AddSectionDocument = gql`
  mutation AddSection($input: AddSectionInput!) {
    addSection(input: $input) {
      id
      sections {
        id
        title
        subsections {
          id
          title
          subsections {
            id
            title
            subsections {
              id
              title
              subsections {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export function useMutateAddSection(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddSectionDocument, options);
}

// Delete Query

export const DeleteContentBiteDocument = gql`
  mutation DeleteContentBite($input: DeleteContentBiteInput!) {
    deleteContentBite(input: $input) {
      id
      content {
        ... on YouTubeVideoBite {
          id
          createdAt
          ytVideoId
        }
        ... on VideoBite {
          id
          createdAt
          videoDescription
          videoSize
          disableAudio
          loop
          durationMillis
        }
        ... on AudioBite {
          id
          createdAt
          audioDescription
          durationMillis
        }
        ... on ImageBite {
          id
          createdAt
          imageDescription
          imageSize
        }
        ... on TextBite {
          id
          createdAt
          text
        }
      }
    }
  }
`;

export function useDeleteContentBite(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteContentBiteDocument, options);
}

export const CreateNewModuleDocument = gql`
  mutation CreateNewModule($input: CreateNewModuleInput!) {
    createNewModule(input: $input) {
      id
    }
  }
`;

export function useCreateNewModule(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateNewModuleDocument, options);
}

export const MutateUserEmailDocument = gql`
  mutation MutateUserEmail($input: MutateUserEmailInput!) {
    mutateUserEmail(input: $input) {
      id
      firstName
      lastName
      email
      accountRole
    }
  }
`;

export function useMutateUserEmail(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateUserEmailDocument, options);
}

export const MutatePosterFrameDocument = gql`
  mutation MutatePosterFrame($input: MutatePosterFrameInput!) {
    mutatePosterFrame(input: $input) {
      id
    }
  }
`;

export function useMutatePosterFrame(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutatePosterFrameDocument, options);
}

export const MutateUpdateModuleNameDocument = gql`
  mutation UpdateModuleName($moduleId: ID!, $name: String!) {
    updateModuleName(moduleId: $moduleId, name: $name) {
      id
      name
    }
  }
`;

export function useUpdateModuleName(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateUpdateModuleNameDocument, options);
}

export const MutateUpdateModuleAccessScopeDocument = gql`
  mutation UpdateModuleAccessScope(
    $moduleId: ID!
    $accessScope: ModuleAccessScope!
  ) {
    updateModuleAccessScope(moduleId: $moduleId, accessScope: $accessScope) {
      id
      adminInfo {
        id
        slug
        moduleAccessScope
        variantFieldsDefinition {
          fieldName
          fieldValues
        }
      }
    }
  }
`;

export function useUpdateModuleAccessScope(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateUpdateModuleAccessScopeDocument, options);
}

export const MutateUpdateModuleSlugDocument = gql`
  mutation UpdateModuleSlug($moduleId: ID!, $slug: Slug!) {
    updateModuleSlug(moduleId: $moduleId, slug: $slug) {
      id
      adminInfo {
        id
        slug
      }
    }
  }
`;

export function useUpdateModuleSlug(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateUpdateModuleSlugDocument, options);
}

export const MutateUpdateModuleIconDocument = gql`
  mutation UpdateModuleIcon($moduleId: ID!, $icon: DataURL!) {
    updateModuleIcon(moduleId: $moduleId, icon: $icon) {
      id
    }
  }
`;

export function useUpdateModuleIcon(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateUpdateModuleIconDocument, options);
}

export const MutateSectionTitleDocument = gql`
  mutation MutateSectionTitle($input: MutateSectionTitleInput!) {
    mutateSectionTitle(input: $input) {
      id
      title
    }
  }
`;

export function useMutateSectionTitle(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateSectionTitleDocument, options);
}

export const DeleteSectionDocument = gql`
  mutation DeleteSection($input: DeleteSectionInput!) {
    deleteSection(input: $input)
  }
`;

export function useDeleteSection(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteSectionDocument, options);
}

export const MutateSectionCoverDocument = gql`
  mutation MutateSectionCover($input: MutateSectionCoverInput!) {
    mutateSectionCover(input: $input) {
      id
    }
  }
`;

export function useMutateSectionCover(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateSectionCoverDocument, options);
}

export const GetSectionCoverDocument = gql`
  query SectionCover($moduleId: ID!, $sectionId: ID!) {
    sectionCover(moduleId: $moduleId, sectionId: $sectionId)
  }
`;

export function useGetSectionCover(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetSectionCoverDocument, options);
}

export const UpdateAccountNameDocument = gql`
  mutation UpdateAccountName($accountId: ID!, $name: String!) {
    updateAccountName(accountId: $accountId, name: $name) {
      id
      name
    }
  }
`;

export function useUpdateAccountName(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateAccountNameDocument, options);
}

export const UpdateAccountSlugDocument = gql`
  mutation UpdateAccountSlug($accountId: ID!, $slug: Slug!) {
    updateAccountSlug(accountId: $accountId, slug: $slug) {
      id
      slug
    }
  }
`;

export function useUpdateAccountSlug(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateAccountSlugDocument, options);
}

export const GetModuleStructureDocument = gql`
  query Module($moduleId: ID!) {
    module(id: $moduleId) {
      id
      name
      moduleType
      moduleIdentifier
      sections {
        id
        title
        subsections {
          id
          title
        }
      }
      contentInfo {
        id
        hubModules {
          moduleId
          title
          subtitle
          category
        }
        productVariants {
          variant {
            fieldName
            fieldValue
          }
        }
      }
    }
  }
`;

export function useGetModuleStructure(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetModuleStructureDocument, options);
}

export const AddModuleToHubDocument = gql`
  mutation AddModuleToHub($input: AddModuleToHubInput!) {
    addModuleToHub(input: $input) {
      id
    }
  }
`;

export function useAddModuleToHub(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddModuleToHubDocument, options);
}

export const MutateModuleInHubDocument = gql`
  mutation MutateModuleInHub($input: MutateModuleInHubInput!) {
    mutateModuleInHub(input: $input) {
      id
    }
  }
`;

export function useMutateModuleInHub(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(MutateModuleInHubDocument, options);
}

export const RemoveModuleFromHubDocument = gql`
  mutation RemoveModuleFromHub($input: RemoveModuleFromHubInput!) {
    removeModuleFromHub(input: $input) {
      id
    }
  }
`;

export function useRemoveModuleFromHub(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RemoveModuleFromHubDocument, options);
}

export const GetModuleListDocument = gql`
  query Modules($accountId: ID!) {
    modules(accountId: $accountId) {
      id
      moduleIdentifier
      moduleType
    }
  }
`;

export function useGetModuleList(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetModuleListDocument, options);
}

export const ReorderSectionsDocument = gql`
  mutation ReorderSections($input: ReorderSectionsInput!) {
    reorderSections(input: $input) {
      id
    }
  }
`;

export function useReorderSections(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ReorderSectionsDocument, options);
}

export const ReorderContentBitesDocument = gql`
  mutation ReorderContentBites($input: ReorderContentBitesInput!) {
    reorderContentBites(input: $input) {
      id
    }
  }
`;

export function useReorderContentBites(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ReorderContentBitesDocument, options);
}

export const DeleteUserDocument = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export function useDeleteUser(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteUserDocument, options);
}

export const AssignAccountRoleDocument = gql`
  mutation AssignAccountRole($input: AssignAccountRoleInput!) {
    assignAccountRole(input: $input) {
      id
      firstName
      lastName
      email
      accountRole
    }
  }
`;

export function useAssignAccountRole(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AssignAccountRoleDocument, options);
}

export const AssignUserToModuleDocument = gql`
  mutation AssignUserToModule($input: AssignModuleRoleInput!) {
    assignModuleRole(input: $input) {
      id
      moduleAccessList {
        moduleId
        moduleRole
      }
    }
  }
`;

export function useAssignUserToModule(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AssignUserToModuleDocument, options);
}

export const RemoveUserFromModuleDocument = gql`
  mutation RemoveUserFromModule($input: RemoveUserFromModuleInput!) {
    removeUserFromModule(input: $input) {
      id
    }
  }
`;

export function useRemoveUserFromModule(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RemoveUserFromModuleDocument, options);
}

export const ResendInviteDocument = gql`
  mutation ResendInvite($input: ResendInviteInput!) {
    resendInvite(input: $input)
  }
`;

export function useResendInvite(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ResendInviteDocument, options);
}

export const IsAccountSlugAvailableDocument = gql`
  query IsAccountSlugAvailable($slug: Slug!) {
    isAccountSlugAvailable(slug: $slug)
  }
`;

export function useIsAccountSlugAvailable(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(IsAccountSlugAvailableDocument, options);
}

export const IsModuleSlugAvailableDocument = gql`
  query isModuleSlugAvailable($accountId: String!, $slug: Slug!) {
    isModuleSlugAvailable(accountId: $accountId, slug: $slug)
  }
`;

export function useIsModuleSlugAvailable(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(IsModuleSlugAvailableDocument, options);
}

export const GetSimpleKeywordSearchDocument = gql`
  query SimpleKeywordSearch($input: SearchInput!) {
    simpleKeywordSearch(input: $input) {
      item {
        __typename
        ... on TextBite {
          id
          createdAt
          text
          parent {
            id
            title
            slug
            paid
          }
        }
        ... on ImageBite {
          id
          createdAt
          imageDescription
          imageSize
          parent {
            id
            title
            slug
            paid
          }
        }
        ... on AudioBite {
          id
          createdAt
          audioDescription
          durationMillis
          parent {
            id
            title
            slug
            paid
          }
        }
        ... on VideoBite {
          id
          createdAt
          videoDescription
          videoSize
          disableAudio
          loop
          durationMillis
          parent {
            id
            title
            slug
            paid
          }
        }
        ... on YouTubeVideoBite {
          id
          createdAt
          ytVideoId
          parent {
            id
            title
            slug
            paid
          }
        }
        ... on Section {
          id
          title
          slug
          paid
        }
      }
      locations
    }
  }
`;

export function useSimpleKeywordSearchQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetSimpleKeywordSearchDocument, options);
}

export function useSimpleKeywordSearchLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetSimpleKeywordSearchDocument, options);
}