import React, { useEffect, useMemo, useState } from 'react';
import {
  MeDocument,
  ModuleAccessUsersDocument,
  useAssignUserToModule,
  useModuleAccessUsersQuery,
  useSearchMembersLazyQuery,
} from 'graphql/gen.types';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApolloClient } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  StyledTableCell,
  StyledTableRow,
} from 'components/Common/StyledTableComponent';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import RemoveAccess from './RemoveAccess';
import { Check, Email } from '@mui/icons-material';
import _ from 'lodash';

const settingsRole = [
  { id: 'ADMIN', value: 'ADMIN', label: 'Admin' },
  { id: 'WRITE', value: 'WRITE', label: 'Write' },
  { id: 'READ', value: 'READ', label: 'Read' },
];

const EditAccess = (props) => {
  const { onClose } = props;
  const { id: moduleIdentifier } = useParams();

  const [anchorElRole, setAnchorElRole] = React.useState(null);

  const handleOpenRoleMenu = (event) => {
    setAnchorElRole(event.currentTarget);
  };

  const handleCloseRoleMenu = () => {
    setAnchorElRole(null);
  };

  const [selectedOption, setSelectedOption] = useState({ id: '', email: '' });
  const [steps, setSteps] = useState(1);
  const [removeUserAccessId, setRemoveUserAccessId] = useState('');
  const [emailSearchText, setEmailSearchText] = useState('');
  const [moduleRole, setModuleRole] = useState('WRITE');
  const [presentRole, setPresentRole] = useState('');
  const [presentSelectedOption, setPresentSelectedOption] = useState({
    userId: '',
    moduleRole: '',
  });

  const hasChangeModuleRole = () => {
    return !_.eq(presentRole, presentSelectedOption.moduleRole);
  };

  const updateUserToModuleRoleInput = {
    userId: presentSelectedOption.userId,
    moduleIdentifier: moduleIdentifier,
    moduleRole: presentRole,
  };

  const [updateUserToModuleAccess] = useAssignUserToModule({
    variables: {
      input: updateUserToModuleRoleInput,
    },
    onCompleted: () => {
      handleCloseRoleMenu();
    },
  });

  useEffect(() => {
    if (hasChangeModuleRole()) {
      updateUserToModuleAccess();
    }
    // eslint-disable-next-line
  }, [presentRole]);

  const handleModuleRoleChange = (event) => {
    const moduleRole = event.target.value;
    setModuleRole(moduleRole);
  };

  const handleEmailSearchText = (event) => {
    setEmailSearchText(event.target.value);
  };

  const resetEmailSearchText = () => {
    setEmailSearchText('');
  };

  const onCloseUserAccess = () => {
    setRemoveUserAccessId('');
  };

  const client = useApolloClient();

  const user = client.readQuery({
    query: MeDocument,
  });

  const accountId = user?.me?.account?.id || ''; // If it's undefined, accountId will be an empty string

  // Fetch the query
  const [getSearchMembers, { loading, error, data }] =
    useSearchMembersLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const getSerchMembersList = async () => {
    const searchMembersInput = {
      accountId: accountId,
      moduleId: moduleIdentifier,
      emailSearchText: emailSearchText,
    };
    await getSearchMembers({
      variables: {
        input: searchMembersInput,
      },
    });
  };

  useEffect(() => {
    if (!_.isEmpty(emailSearchText)) {
      getSerchMembersList();
    }
    // eslint-disable-next-line
  }, [emailSearchText]);

  // Fetch the query
  const {
    loading: moduleAccessUsersLoading,
    error: moduleAccessUsersError,
    data: moduleAccessUsersData,
  } = useModuleAccessUsersQuery({
    displayName: 'moduleAccessUsers',
    variables: {
      moduleId: moduleIdentifier,
    },
  });

  // useMemo hook to memoize response data everytime it changes
  const displayData = useMemo(() => {
    return data === undefined ? [] : data.searchMembers;
  }, [data]);

  const displayModuleAccessUsersData = useMemo(() => {
    return moduleAccessUsersData === undefined
      ? []
      : moduleAccessUsersData?.moduleAccessUsers;
  }, [moduleAccessUsersData]);

  const displayUserFilterData = useMemo(() => {
    // Check if data is undefined or not in the expected format
    if (
      !moduleAccessUsersData ||
      !Array.isArray(moduleAccessUsersData.moduleAccessUsers)
    ) {
      return []; // Return an empty array
    }

    const filteredData = displayData.filter((moduleAccess) => {
      return !moduleAccessUsersData?.moduleAccessUsers.some(
        (data) => data?.id === moduleAccess?.id,
      );
    });

    return filteredData;
  }, [moduleAccessUsersData, displayData]);

  const [assignUserToModule, { loading: assignUserToModuleLoading }] =
    useAssignUserToModule({});

  const handleAssignUserToModule = async () => {
    const assignUserToModuleInput = {
      userId: selectedOption.id,
      moduleIdentifier: moduleIdentifier,
      moduleRole: moduleRole,
    };
    const { errors } = await assignUserToModule({
      variables: {
        input: assignUserToModuleInput,
      },
      refetchQueries: [
        {
          query: ModuleAccessUsersDocument,
          variables: {
            moduleId: moduleIdentifier,
          },
        },
      ],
      onCompleted: () => {
        setSelectedOption({ id: '', email: '' });
        setSteps(1);
      },
    });
    if (errors) {
      console.log('errors', errors);
    }
  };

  // When Loading is false & data is available, display the data in the table
  if (moduleAccessUsersError) {
    console.log(error);
    return (
      <Dialog fullWidth maxWidth='lg' open={true} onClose={onClose}>
        <DialogContent>
          <Alert severity='error'>Error performing network request.</Alert>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog fullWidth maxWidth='lg' open={true} onClose={onClose}>
        <DialogTitle>Manage Access</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {steps === 1 ? (
            <Box>
              <div style={{ width: '100%' }} className='search-box'>
                <input
                  className='search-input'
                  value={emailSearchText}
                  onChange={handleEmailSearchText}
                  type='text'
                  placeholder='Search by email'
                />
                <button className='search-btn'>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>

              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress />
                </Box>
              ) : !loading &&
                !error &&
                !_.isEmpty(emailSearchText) &&
                displayUserFilterData.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Typography variant='h5'>No Results Found.</Typography>
                </Box>
              ) : displayUserFilterData?.length > 0 ? (
                <List>
                  {displayUserFilterData?.map((data, index) => {
                    const { id, email, firstName, lastName } = data;
                    return (
                      <ListItemButton
                        onClick={() => {
                          setSelectedOption((prevSelectedOption) => ({
                            ...prevSelectedOption,
                            id: id,
                            email: email,
                          }));
                          setSteps(2);
                          resetEmailSearchText();
                        }}
                        key={index}
                        alignItems='center'
                      >
                        <ListItemIcon>
                          <Email />
                        </ListItemIcon>
                        <ListItemText
                          primary={email}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.primary'
                              >
                                {`${firstName} ${lastName}`}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              ) : null}
            </Box>
          ) : steps === 2 ? (
            <Box>
              <List>
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={() => setSteps(1)}
                      edge='end'
                      aria-label='close'
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={true}>
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText primary={selectedOption.email} />
                  </ListItemButton>
                </ListItem>
              </List>
              {selectedOption && (
                <Box>
                  <Box>
                    <FormControl margin='normal'>
                      <FormLabel>Role In The Module</FormLabel>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                        }}
                      >
                        <RadioGroup
                          row
                          aria-labelledby='demo-role'
                          name='role'
                          value={moduleRole}
                          onChange={handleModuleRoleChange}
                        >
                          <FormControlLabel
                            value='WRITE'
                            control={<Radio />}
                            label='Write'
                          />
                          <FormControlLabel
                            value='READ'
                            control={<Radio />}
                            label='Read'
                          />
                          <FormControlLabel
                            value='ADMIN'
                            control={<Radio />}
                            label='Admin'
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                  </Box>
                  <LoadingButton
                    fullWidth
                    loading={assignUserToModuleLoading}
                    variant='contained'
                    onClick={handleAssignUserToModule}
                  >
                    {`Add ${selectedOption.email} to this module`}
                  </LoadingButton>
                </Box>
              )}
            </Box>
          ) : null}

          {moduleAccessUsersLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : displayModuleAccessUsersData.length > 0 ? (
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table
                sx={{ minWidth: 700 }}
                aria-label='Edit Module Access table'
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Contact</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayModuleAccessUsersData?.map((row, i) => {
                    const moduleAccessFilter = row.moduleAccessList?.filter(
                      (moduleAccess) =>
                        moduleAccess?.moduleId === moduleIdentifier,
                    )?.[0];
                    return (
                      <StyledTableRow key={i}>
                        <StyledTableCell
                          sx={{ minWidth: '160px', maxWidth: '160px' }}
                        >
                          {row.firstName === null && row.lastName === null
                            ? 'null'
                            : `${row.firstName} ${row.lastName}`}
                        </StyledTableCell>
                        <StyledTableCell width='auto'>
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: '176px', maxWidth: '176px' }}
                        >
                          <Button
                            sx={{ border: '1px solid gray' }}
                            onClick={(e) => {
                              setPresentSelectedOption(
                                (prevSelectedOption) => ({
                                  ...prevSelectedOption,
                                  userId: row.id,
                                  moduleRole: moduleAccessFilter?.moduleRole,
                                }),
                              );
                              setPresentRole(moduleAccessFilter?.moduleRole);
                              handleOpenRoleMenu(e);
                            }}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Role: {moduleAccessFilter?.moduleRole}
                          </Button>
                          <Menu
                            sx={{ mt: '45px' }}
                            id='menu-appbar'
                            anchorEl={anchorElRole}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorElRole)}
                            onClose={handleCloseRoleMenu}
                          >
                            {settingsRole.map((setting) => {
                              const { id, label, value } = setting;
                              return (
                                <MenuItem
                                  key={id}
                                  onClick={() => {
                                    setPresentRole(value);
                                    if (!hasChangeModuleRole()) {
                                      handleCloseRoleMenu();
                                    }
                                  }}
                                >
                                  <ListItemIcon>
                                    {value === presentRole ? (
                                      <Check fontSize='small' />
                                    ) : null}
                                  </ListItemIcon>
                                  <Typography textAlign='center'>
                                    {label}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Menu>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: '112px', maxWidth: '112px' }}
                        >
                          <IconButton
                            onClick={() => setRemoveUserAccessId(row.id)}
                            color='inherit'
                          >
                            <FontAwesomeIcon
                              style={{ fontSize: 'large' }}
                              icon={faTrash}
                            />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </DialogContent>
      </Dialog>

      {removeUserAccessId && (
        <RemoveAccess
          open={removeUserAccessId}
          onClose={onCloseUserAccess}
          userId={removeUserAccessId}
        />
      )}
    </>
  );
};

export default EditAccess;
