/**
 * This is the main CMS component which manages all other components, handles navigations between different
 * components etc.,
 *
 * The input to this component is the appId.
 */

import React from 'react';
import CMSHeader from './CMSHeader';
import CoreContentSection from './CoreContentSection';
import CMSModalHandler from './CMSModalHandler';
import { EditCMSProvider } from './EditCmsContext';
import { YTContextProvider } from './cms_context/YTContext';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
} from '@mui/material';
import { VideoContextProvider } from './cms_context/VideoContext';
import { ImageContextProvider } from './cms_context/ImageContext';
import { AudioContextProvider } from './cms_context/AudioContext';
import SectionSideBar from './SectionSideBar';
import { useCMSMainContext } from 'context/CMSMainContext';
import { useCMSContext } from './CMSContext';
import SearchResultComponent from './cms_search/SearchResultComponent';

const CMS = () => {
  const {
    selectedModule: { moduleLoading, moduleError, module, refetch },
  } = useCMSMainContext();

  const { searchQuery } = useCMSContext();

  if (moduleLoading) {
    return (
      <Dialog fullWidth maxWidth='sm' open={true}>
        <DialogContent dividers>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (moduleError) {
    return (
      <Dialog fullWidth maxWidth='sm' open={true}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent dividers>
          Network Error. Please check your network and retry.
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => refetch()}>
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <EditCMSProvider>
        <YTContextProvider>
          <VideoContextProvider>
            <ImageContextProvider>
              <AudioContextProvider>
                <Box sx={{ overflow: 'hidden', px: 0 }} id='cms-root'>
                  <CMSModalHandler />
                  <CMSHeader />

                  {searchQuery ? (
                    <SearchResultComponent
                      moduleIdentifier={module?.id}
                      searchQuery={searchQuery}
                    />
                  ) : (
                    <Box id='cms-content-management' sx={{ height: '86vh' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={2}
                          sx={{ borderRight: '1px solid black' }}
                        >
                          <SectionSideBar />
                        </Grid>
                        <Grid item xs={10}>
                          <CoreContentSection />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </AudioContextProvider>
            </ImageContextProvider>
          </VideoContextProvider>
        </YTContextProvider>
      </EditCMSProvider>
    </>
  );
};

export default CMS;
