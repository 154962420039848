import { fetchUserAttributes, getCurrentUser, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(undefined);
const KEY_ATTR_ACCOUNT_ID = 'custom:account_id';

const AUTH_GENERIC_ERROR_MSG =
  'Please try after sometime or contact your administrator if this persists';

const AUTH_ERROR = {
  ERROR_NO_ACCOUNT_ID: 'Missing account ID. Contact your administrator.', // This is an admin error
  // This is an error where user likely ended up in a wrong url
  ERROR_INVALID_ACCOUNT_ID: 'Access denied. Verify the URL.',
  // This is an error that happens when a user has been disabled/removed
  ERROR_TOKEN_REFRESH_FAILURE:
    'Access denied. Contact your administrator if you think this is a mistake.',
  // Generic error
  ERROR_GENERIC: AUTH_GENERIC_ERROR_MSG,
};

const AuthProvider = ({ children }) => {
  const [amplifySignedIn, setAmplifySignedIn] = useState(undefined);

  /*
   * 1. initial state, process of setting state is yet to run
   *        isAuthenticated: undefined,
   *        authError: undefined
   * 2. user is not authenticated with no errors (refresh token failures, logout leads to this state)
   *        isAuthenticated: false
   *        authError: undefined
   * 3. user is not authenticated with errors (missing or mismatch in accountId leads to this state)
   *        isAuthenticated: false
   *        authError: <error message>
   */
  const [authState, setAuthState] = useState({
    isAuthenticated: undefined,
    authError: undefined,
  });

  const clearAuthError = () => {
    setAuthState({ ...authState, authError: undefined });
  };

  async function ensureUserAuthenticated() {
    const userAttributes = await fetchUserAttributes().catch(async (error) => {
      await signOut();
    });
    const attrAccountId = userAttributes[KEY_ATTR_ACCOUNT_ID];
    if (_.isEmpty(attrAccountId)) {
      setAuthState({
        isAuthenticated: false,
        authError: AUTH_ERROR.ERROR_NO_ACCOUNT_ID,
      });
      setAmplifySignedIn(false);
    } else {
      setAuthState({
        isAuthenticated: true,
        authError: undefined,
      });
    }
  }

  async function currentAuthenticatedUser() {
    try {
      await getCurrentUser();
      setAmplifySignedIn(true);
    } catch (err) {
      console.log(err);
      setAmplifySignedIn(false);
      setAuthState({
        isAuthenticated: false,
        authError: undefined,
      });
    }
  }

  const listener = (data) => {
    switch (data.payload.event) {
      case 'signedIn':
        setAmplifySignedIn(true);
        break;
      case 'signedOut':
        setAmplifySignedIn(false);
        setAuthState({
          isAuthenticated: false,
          authError: undefined,
        });
        console.log('user has been signedOut successfully.');
        break;
      case 'tokenRefresh':
        console.log('auth tokens have been refreshed.');
        break;
      case 'tokenRefresh_failure':
        setAmplifySignedIn(false);
        setAuthState({
          authError: undefined,
          isAuthenticated: false,
        });
        console.log('failure while refreshing auth tokens.');
        break;
      default:
        console.log(`unhandled auth event ${data.payload.event}`);
    }
  };

  useEffect(() => {
    currentAuthenticatedUser().catch(console.error);
    Hub.listen('auth', (data) => listener(data));
  }, []);

  useEffect(() => {
    if (amplifySignedIn) {
      ensureUserAuthenticated().catch(console.error);
    } else if (amplifySignedIn === false) {
      localStorage.clear();
    }
  }, [amplifySignedIn]);

  return (
    <AuthContext.Provider value={{ authState, clearAuthError }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
