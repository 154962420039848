import React from 'react';
import { MeDocument, OrgUsersDocument, useDeleteUser } from 'graphql/gen.types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';

const DeleteUser = (props) => {
  const { userId, onClose, ...rest } = props;

  const client = useApolloClient();

  const { me } = client.readQuery({
    query: MeDocument,
  });

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUser();

  const handleDeleteUser = async () => {
    try {
      const { errors } = await deleteUser({
        variables: {
          userId: userId,
        },
        refetchQueries: [
          {
            query: OrgUsersDocument,
            variables: {
              accountId: me?.account?.id,
            },
          },
        ],
      });

      if (errors) {
        toast.error('Error deleting user.');
      }

      if (!deleteUserLoading && !errors) {
        onClose();
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <Dialog fullWidth maxWidth='sm' {...rest} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete user.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          variant='contained'
          size='medium'
          loading={deleteUserLoading}
          onClick={handleDeleteUser}
        >
          Yes, I'm sure
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUser;
