import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

const MDLoadingButton = ({ loading, children }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', py: '8px' }}>
      <LoadingButton
        loading={loading}
        variant='contained'
        type='submit'
        sx={{ width: '240px' }}
      >
        {/* There is a known issue with translating a page using Chrome tools when a Loading Button is present.
          After the page is translated, the application crashes when the loading state of a Button changes.
          To prevent this, ensure that the contents of the Loading Button are nested inside any HTML element,
          such as a <span> */}
        <span>{children}</span>
      </LoadingButton>
    </Box>
  );
};

export default MDLoadingButton;
