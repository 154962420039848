/**
 * Component which displays the current variant settings for the selected section and allows
 * for changing variants for the sections.
 */
import React, { useState } from 'react';
import _ from 'lodash';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { useReactiveVar } from '@apollo/client';
import { rVarCMSApp, rVarCMSSectionSelectionID } from 'global/const';
import { SectionByID } from 'global/DataUtils';
import { SectionVariantManager } from 'global/VariantUtils';

const SectionSettings = (props) => {
  // reactive variables used in the component
  const { module } = useReactiveVar(rVarCMSApp);
  const selection = useReactiveVar(rVarCMSSectionSelectionID);

  // selected section's & its variants
  const section = SectionByID(selection, module.sections);
  const sectionVariants = section.objectizedVariants;

  // Variants definition for populating the select component
  const variantsDefinition = module.adminInfo.objectizedVariantFieldsDefinition;

  // Product variants
  const productVariants = _.clone(module.contentInfo.objectizedProductVariants);

  // View state
  const [
    { globalViewChecked, selectedVariantParts, addedSectionVariants },
    setSectionSettingsViewState,
  ] = useState({
    globalViewChecked: sectionVariants.length === 0,
    selectedVariantParts: {},
    addedSectionVariants: sectionVariants,
  });
  const [assureDelete, setAssureDelete] = useState(false);

  // A manager function object to organize all operations
  const {
    addVariantPart,
    clearVariantPart,
    isValidVariant,
    getVariantPartSelectsOptions,
    addSelectionToSection,
    getSectionVariantsSelectOptions,
    getSelectedVariantParts,
  } = SectionVariantManager(
    variantsDefinition,
    productVariants,
    selectedVariantParts,
    addedSectionVariants,
  );

  // Function object to handle changes to variant part select components
  const PartSelectManager = (_selectName) => {
    // The originating select component name
    const selectName = _selectName;
    // Function to handle the change
    function handleChange(selectObj, { action }) {
      switch (action) {
        case 'clear':
          setSectionSettingsViewState({
            globalViewChecked,
            selectedVariantParts: clearVariantPart(selectName),
            addedSectionVariants,
          });
          break;
        case 'select-option':
          // Add/Replace the selectedProductVariant
          const variantPart = {};
          variantPart[selectName] = selectObj.value;
          setSectionSettingsViewState({
            globalViewChecked,
            selectedVariantParts: addVariantPart(variantPart),
            addedSectionVariants,
          });
          break;
        default:
          break;
      }
    }
    return { handleChange };
  };

  // Function object to handle changes to section variants select component.
  // In our usecase, the only need is to handle the remove value action.
  const SectionVariantsSelectOnChange = (selectObj, { action }) => {
    let _addedSectionVariants = [];
    if (selectObj != null) {
      _addedSectionVariants = selectObj.map((item) => {
        return item.original;
      });
    }
    switch (action) {
      case 'remove-value':
        setSectionSettingsViewState({
          globalViewChecked,
          selectedVariantParts,
          addedSectionVariants: _addedSectionVariants,
        });
        break;
      default:
        break;
    }
  };

  const sectionSelect = () => {
    const { selectName, selectOptions, value } =
      getSectionVariantsSelectOptions();
    return (
      <Form.Group key='form-group-section-variants'>
        <p className='font-weight-bold m-0' key='section-variants-select-name'>
          {selectName}
        </p>
        <Select
          key='select-section-variants'
          className='basic-multi-select'
          isMulti
          value={value}
          isClearable={false}
          isSearchable={false}
          options={selectOptions}
          isDisabled={globalViewChecked}
          classNamePrefix='select'
          placeholder='No Section Variants Added.'
          onChange={SectionVariantsSelectOnChange}
          components={{ DropdownIndicator: null }}
        />
      </Form.Group>
    );
  };

  // Construct the array of variant part <Select/> components
  const selectsObjArr = getVariantPartSelectsOptions();
  const selectComponents = selectsObjArr.map((selectObj, index) => {
    return (
      <Col>
        <Form.Group key={'form-group-select-' + index}>
          <p
            className='font-weight-bold m-0'
            key={'form-group-select-' + index + '-name'}
          >
            {selectObj.selectName}
          </p>
          <Select
            className='basic-single'
            key={'select-' + index}
            isClearable={true}
            isSearchable={true}
            isDisabled={globalViewChecked}
            value={selectObj.selectValue}
            onChange={PartSelectManager(selectObj.selectName).handleChange}
            options={selectObj.selectOptions}
            placeholder={'Select ' + selectObj.selectName}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
      </Col>
    );
  });

  // add the current selected variant parts to section
  const addClickHandler = () => {
    const _secVariants = addSelectionToSection();
    setSectionSettingsViewState({
      globalViewChecked,
      selectedVariantParts: getSelectedVariantParts(),
      addedSectionVariants: _secVariants,
    });
  };

  // Should the apply button be enabled or not. It is enabled if either
  // the global view has changed and/or the section variants have changed
  // and the data is valid.
  const enableApplyButton = () => {
    const hasSectionVariantsChanged = () => {
      return !_.isEqual(sectionVariants, addedSectionVariants);
    };

    const hasGlobalViewChanged = () => {
      return !(globalViewChecked === (sectionVariants.length === 0));
    };

    if (hasGlobalViewChanged()) {
      if (globalViewChecked) {
        return true;
      }
      if (hasSectionVariantsChanged()) {
        return true;
      }
      return false;
    }

    if (
      !globalViewChecked &&
      hasSectionVariantsChanged() &&
      addedSectionVariants.length !== 0
    ) {
      return true;
    }
    return false;
  };

  // Save the current section variant selections
  const applySectionVariantSelections = () => {
    console.log(JSON.stringify(addedSectionVariants, null, 2));
    props.onHide();
  };

  // Delete this section mutation should come here
  const confirmDelete = () => {
    console.log('Delete the section here');
    props.onHide();
  };

  return (
    <Modal show={true} onHide={props.onHide} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>Section Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll' }}>
        <Row className='p-2'>
          <Col xs={'auto'} key='col-checkbox-global'>
            <Form.Check
              type='checkbox'
              key='checkbox-global'
              id={'select-global'}
              label='Global'
              checked={globalViewChecked}
              className='mt-4'
              onChange={() =>
                setSectionSettingsViewState({
                  globalViewChecked: !globalViewChecked,
                  selectedVariantParts,
                  addedSectionVariants,
                })
              }
            />
          </Col>
          <Col key='col-section-variants-additions'>
            {sectionSelect()}
            <Row
              className='p-0 d-flex align-items-center'
              key='row-select-parts'
            >
              {selectComponents}
              <Col xs={'auto'} className='mt-2' key='col-add-button'>
                <Button
                  key='add-button'
                  onClick={addClickHandler}
                  className='btn btn-dark'
                  disabled={!isValidVariant()}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center p-2'>
        <Row>
          <Col>
            <Button
              ref={null}
              onClick={applySectionVariantSelections}
              className='btn btn-dark'
              disabled={!enableApplyButton()}
            >
              Apply
            </Button>
          </Col>
          <Col>
            <Button variant='error' onClick={() => props.onHide()}>
              Cancel
            </Button>
          </Col>
          <Col xs={'auto'}>
            <Button variant='error' onClick={() => setAssureDelete(true)}>
              Delete Section
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
      <>
        <Alert show={assureDelete} variant='danger'>
          <p>Are you sure you want to delete this section?</p>
          <Container className='d-flex justify-content-end'>
            <Button variant='error' onClick={confirmDelete} className='mr-2'>
              Yes
            </Button>
            <Button
              variant='dark'
              onClick={() => {
                setAssureDelete(false);
              }}
              className='ml-2'
            >
              No
            </Button>
          </Container>
        </Alert>
      </>
    </Modal>
  );
};

export default SectionSettings;
