/**
 * This file contains all globally available constants. Constants include :
 * 1. Transpiled GraphQL query documents
 * 2. All reactive variables for managing the application state
 */
import { makeVar } from '@apollo/client';

// Admin console
export let ADMIN_CONSOLE_MODAL_SELECTION;
(function (ADMIN_CONSOLE_MODAL_SELECTION) {
  ADMIN_CONSOLE_MODAL_SELECTION['ORG_SETTINGS'] = 'Org Settings';
  ADMIN_CONSOLE_MODAL_SELECTION['PROFILE_SETTINGS'] = 'Profile Settings';
  ADMIN_CONSOLE_MODAL_SELECTION['LOG_OUT'] = 'Log out';
  ADMIN_CONSOLE_MODAL_SELECTION['NEW_USER'] = 'New User';
  ADMIN_CONSOLE_MODAL_SELECTION['EDIT_USER'] = 'Edit User';
  ADMIN_CONSOLE_MODAL_SELECTION['DELETE_USER'] = 'Delete User';
})(ADMIN_CONSOLE_MODAL_SELECTION || (ADMIN_CONSOLE_MODAL_SELECTION = {}));

// CMS
export let CMS_MODAL_SELECTION;
(function (CMS_MODAL_SELECTION) {
  CMS_MODAL_SELECTION['SECTION_SETTINGS'] = 'Section Settings';
  CMS_MODAL_SELECTION['EDIT_ACCESS'] = 'Edit Access';
  CMS_MODAL_SELECTION['EDIT_VARIANTS'] = 'Edit Variants';
})(CMS_MODAL_SELECTION || (CMS_MODAL_SELECTION = {}));

// Export reactive variables (Reactive variables are prefixed with rVar_ for readability)
export const rVarAdminConsoleModalContext = makeVar(undefined);
export const rVarCMSModalContext = makeVar(undefined);
export const rVarCMSApp = makeVar(undefined);
export const rVarCMSSectionSelectionID = makeVar(undefined);
export const rVarSelectedContent = makeVar({
  moduleId: undefined,
  sectionId: undefined,
  contentBiteId: undefined,
});
export const rVarHasUsersAccessRole = makeVar({
  hasUsersAccessRole: undefined,
});
export const rVarHasModuleAccessRoles = makeVar({
  hasModuleAccessRoles: undefined,
});

// Global Upload Formats
export const allowedImageFormats = [
  'image/jpeg',
  'image/jpg',
  'image/webp',
  'image/png',
  'image/gif',
];

export const allowedAudioFormats = ['audio/mpeg', 'audio/ogg'];

export const allowedVideoFormats = ['video/mp4', 'video/webm'];
