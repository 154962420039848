import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

export const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  const {
    authState: { isAuthenticated },
  } = useAuthContext();

  if (isAuthenticated !== undefined && !isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
