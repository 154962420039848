import { uniqueId } from 'lodash';

export const ID_CREATE_TEXT_BITE = 'create-text-bite';
export const ID_CREATE_IMAGE_BITE = 'create-image-bite';
export const ID_CREATE_AUDIO_BITE = 'create-audio-bite';
export const ID_CREATE_VIDEO_BITE = 'create-video-bite';
export const ID_CREATE_YOUTUBE_VIDEO_BITE = 'create-youtube-video-bite';

export const CreateBiteList = [
  { id: ID_CREATE_TEXT_BITE, label: 'Text' },
  { id: ID_CREATE_IMAGE_BITE, label: 'Image' },
  { id: ID_CREATE_AUDIO_BITE, label: 'Audio' },
  { id: ID_CREATE_VIDEO_BITE, label: 'Video' },
  { id: ID_CREATE_YOUTUBE_VIDEO_BITE, label: 'Youtube Video' },
];

export const CreateNewContentBite = (createBite) => {
  const createBiteId = createBite.id;
  let contentBite = { id: uniqueId() };
  if (createBiteId === ID_CREATE_TEXT_BITE) {
    contentBite = {
      ...contentBite,
      __typename: 'TextBite',
      text: createBite?.text,
      // text: 'Testing a Random Text For Created Bite',
    };
  }
  // else {
  //   contentBite = {
  //     ...contentBite,
  //     __typename: 'TextBite',
  //     text: createBiteId,
  //   };
  // }
  return contentBite;
};
