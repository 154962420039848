const AWSConfig = {
  Auth: {
    Cognito: {
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENTID,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'https://knidal-development.auth.us-east-1.amazoncognito.com',
        scope: [
          'phone',
          'email',
          'openid',
          'profile',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: 'knidal://mainactivity',
        redirectSignOut: 'knidal://mainactivity',
      },
    },
  },
};

export default AWSConfig;
