import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import {
  ModuleAccessUsersDocument,
  useRemoveUserFromModule,
} from 'graphql/gen.types';
import React from 'react';
import { useParams } from 'react-router-dom';

const RemoveAccess = (props) => {
  const { userId, onClose, ...rest } = props;

  const { id: moduleIdentifier } = useParams();

  const removeUserFromModuleInput = {
    userId: userId,
    moduleIdentifier: moduleIdentifier,
  };

  const [removeUserFromModule, { loading: removeUserFromModuleLoading }] =
    useRemoveUserFromModule();

  const handleRemoveUserFromModule = async () => {
    try {
      await removeUserFromModule({
        variables: { input: removeUserFromModuleInput },
        refetchQueries: [
          {
            query: ModuleAccessUsersDocument,
            variables: {
              moduleId: moduleIdentifier,
            },
          },
        ],
        onCompleted: () => {
          onClose();
        },
      });
    } catch (error) {
      console.log('🚀 ~ handleRemoveUserFromModule ~ error:', error);
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth='sm' {...rest} onClose={onClose}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove access.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button>Close</Button>
          <LoadingButton
            loading={removeUserFromModuleLoading}
            onClick={handleRemoveUserFromModule}
            variant='contained'
            size='medium'
          >
            Yes, I'm sure
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveAccess;
