import { Box } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import React, { useEffect } from 'react';
import { useEditCMSContext } from '../EditCmsContext';
import { scrollIntoView } from 'components/utils/CommonFunction';
import { LoadingButton } from '@mui/lab';

const TextBiteContentHandler = React.forwardRef((props, ref) => {
  const { setContentBiteType } = props;

  const {
    textBite,
    setTextBite,
    setShow,
    textBiteLoading,
    createContentBite,
    setContentBiteIndex,
  } = useEditCMSContext();

  useEffect(() => {
    scrollIntoView('text-bite-handler');
  }, []);

  const close = () => {
    setShow(false);
    setContentBiteIndex(null);
    setContentBiteType('');
  };

  const handleCreateTextBite = () => {
    createContentBite(close);
  };

  return (
    <>
      <Box id='text-bite-handler' sx={{ padding: '8px 16px' }} ref={ref}>
        <MDEditor value={textBite} onChange={setTextBite} />
        <Box sx={{ display: 'flex', justifyContent: 'end', my: 1 }}>
          <Box>
            <LoadingButton
              loading={textBiteLoading}
              disabled={!textBite}
              onClick={handleCreateTextBite}
              fullWidth
              variant='contained'
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default TextBiteContentHandler;
