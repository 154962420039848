import React, { useState } from 'react';
import CompanyLogo from '../../Common/CompanyLogo';
import Title from '../Title';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { signIn } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { signInSchema } from '../ValidationSchema/ValidationSchema';
import SignInError from './SignInError';
import { Box, Button, Container, TextField } from '@mui/material';
import MDLoadingButton from '../MDLoadingButton';
import { useAuthContext } from 'context/AuthContext';

const SignIn = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    authState: { authError },
    clearAuthError,
  } = useAuthContext();

  const initialValues = { email: '', password: '' };

  const SignIn = async (email, password) => {
    try {
      const { nextStep } = await signIn({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
      if (
        nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        navigate('/set-new-password');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    }
  };

  return (
    <>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              const { email, password } = values;
              setIsLoading(true);
              await SignIn(email, password);
              setIsLoading(false);
            } catch (error) {
              console.log(' ~ error:', error);
              setIsLoading(false);
            }
          }}
          validationSchema={signInSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <Box
                component='form'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                }}
                onSubmit={handleSubmit}
              >
                <Box
                  sx={{
                    minWidth: '304px',
                    maxWidth: '372px',
                    py: 2,
                    px: 1,
                  }}
                >
                  <CompanyLogo />
                  <Title>Login</Title>
                  <TextField
                    error={errors.email && touched.email}
                    variant='standard'
                    color='common'
                    fullWidth
                    margin='dense'
                    id='email'
                    type='email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Email ID'
                    autoComplete='off'
                    helperText={errors.email && touched.email && errors.email}
                  />
                  <TextField
                    error={errors.password && touched.password}
                    variant='standard'
                    color='common'
                    fullWidth
                    margin='dense'
                    name='password'
                    id='password'
                    type='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Password'
                    autoComplete='off'
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      width: '100%',
                      py: 1,
                    }}
                  >
                    <Button
                      variant='text'
                      onClick={() => {
                        navigate('/forgot-password');
                      }}
                    >
                      Forgot Password?
                    </Button>
                  </Box>
                  <MDLoadingButton loading={isLoading}>Login</MDLoadingButton>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Container>

      {authError && (
        <SignInError onCloseDialog={clearAuthError} errorMessage={authError} />
      )}
    </>
  );
};

export default SignIn;
