import { confirmResetPassword } from 'aws-amplify/auth';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Container, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CompanyLogo from '../../Common/CompanyLogo';
import MDLoadingButton from '../MDLoadingButton';
import Title from '../Title';
import { resetPasswordSchema } from '../ValidationSchema/ValidationSchema';

const initialValues = { password: '', confirmPassword: '', resetCode: '' };

const ResetPassword = () => {
  const navigate = useNavigate();
  const clientEmail = localStorage.getItem('email');
  const [isLoading, setIsLoading] = useState(false);

  // Collect confirmation code and new password
  const resetPasswordSubmit = async (email, code, newPassword) => {
    try {
      setIsLoading(true);
      const confirmResetPasswordData = {
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      };
      await confirmResetPassword(confirmResetPasswordData);
      toast.success('Reset Password SuccessFully');
      navigate('/login');
      localStorage.removeItem('email');
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          const isValid =
            values.password && values.confirmPassword && values.resetCode;
          if (isValid) {
            // Perform your login logic here
            const { password, resetCode } = values;
            await resetPasswordSubmit(clientEmail, resetCode, password);
          }
        }}
        validationSchema={resetPasswordSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <Box
              component='form'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
              }}
              onSubmit={handleSubmit}
            >
              <Box
                sx={{
                  minWidth: '304px',
                  maxWidth: '372px',
                  py: 2,
                  px: 1,
                }}
              >
                <CompanyLogo />

                <Title>Reset Password</Title>

                <Typography marginTop='1rem'>
                  Enter the reset code you received on your email and the new
                  password
                </Typography>

                <TextField
                  error={errors.resetCode && touched.resetCode}
                  autoComplete='off'
                  variant='standard'
                  fullWidth
                  margin='dense'
                  helperText={
                    errors.resetCode && touched.resetCode && errors.resetCode
                  }
                  color='common'
                  id='resetCode'
                  name='resetCode'
                  type='text'
                  value={values.resetCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Reset Code'
                />

                <TextField
                  error={errors.password && touched.password}
                  autoComplete='off'
                  variant='standard'
                  fullWidth
                  margin='dense'
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  color='common'
                  id='password'
                  name='password'
                  type='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Password'
                />

                <TextField
                  error={errors.confirmPassword && touched.confirmPassword}
                  autoComplete='off'
                  variant='standard'
                  fullWidth
                  margin='dense'
                  helperText={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                  color='common'
                  type='password'
                  label='Confirm Password'
                  name='confirmPassword'
                  id='confirmPassword'
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Container
                  sx={{
                    height: '8px',
                  }}
                />
                <MDLoadingButton loading={isLoading}>
                  Change Password
                </MDLoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ResetPassword;
