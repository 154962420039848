import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchBar from 'components/SearchBar/SearchBar';
import {
  GetModuleStructureDocument,
  useAddModuleToHub,
  useModuleIconByIDLazyQuery,
  useModuleInfoByIdLazyQuery,
} from 'graphql/gen.types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFileUpload } from 'use-file-upload';
import Select from 'react-select';
import { useHubModuleContext } from './HubModuleContext';
import { CloudUpload, ReplayCircleFilled } from '@mui/icons-material';
import _ from 'lodash';

const sortByOptions = [
  { type: 'ascending', value: 'asc-title', label: '↑ Title' },
  { type: 'descending', value: 'desc-title', label: '↓ Title' },
];

const HubHeader = ({ filterOption, displayModuleListFilterData, hubName }) => {
  const { hubId: hubModuleId } = useParams();
  const {
    searchTerm,
    setSearchTerm,
    filteredOption,
    setFilteredOption,
    sortByOption,
    setSortByOption,
  } = useHubModuleContext();

  const [open, setOpen] = useState(false);

  const [file, selectFile] = useFileUpload();
  const [moduleId, setModuleId] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [category, setCategory] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  const clearData = () => {
    setModuleId('');
    setTitle('');
    setSubTitle('');
    setCategory('');
    setThumbnail('');
  };

  const handleModuleId = (e) => {
    setModuleId(e.target.value);
  };

  const fileSelectedHandler = ({ file }) => {
    console.log('Files Selected', { file });
    const reader = new FileReader();
    reader.onload = () => {
      setThumbnail(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
    console.log(file);
  };

  const handleUseDefaultThumbnail = () => {
    setThumbnail('');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const addModuleToHubInput = {
    hubModuleId: hubModuleId,
    moduleId: moduleId,
    title: title,
    subtitle: subTitle,
    category: category,
  };

  const [loadModuleInfo, { data }] = useModuleInfoByIdLazyQuery();
  const [loadModuleIcon, { data: iconData }] = useModuleIconByIDLazyQuery();

  useEffect(() => {
    if (moduleId) {
      loadModuleInfo({
        variables: {
          moduleId: moduleId,
        },
      }).catch((error) => console.log(error));
      loadModuleIcon({
        variables: {
          moduleId: moduleId,
        },
      }).catch((error) => console.log(error));
    }
  }, [moduleId, loadModuleInfo, loadModuleIcon]);

  const [addModuleToHub, { loading: addModuleToHubLoading }] =
    useAddModuleToHub();

  const isFormValid = () => {
    return !!(moduleId && title && subTitle && category);
  };

  const anyFieldEntered = () => {
    return moduleId || title || subTitle || category;
  };

  const executeAddModuleToHub = async () => {
    if (hubModuleId && moduleId && title && subTitle && category) {
      await addModuleToHub({
        variables: {
          input: addModuleToHubInput,
        },
        refetchQueries: [
          {
            query: GetModuleStructureDocument,
            variables: {
              moduleId: hubModuleId,
            },
          },
        ],
        onCompleted: () => {
          handleClose();
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      setTitle(data?.module?.name);
    }
  }, [data]);

  return (
    <>
      <Box>
        <Typography variant='h4'>Hub - {hubName}</Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <div className='d-flex justify-content-between align-items-center my-4'>
          <div>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </div>
          <div className='d-flex align-items-center'>
            <div style={{ width: '260px' }}>
              <Select
                key='select-section-variants'
                className='basic-multi-select'
                defaultValue={filteredOption}
                isClearable={true}
                isSearchable={false}
                options={filterOption}
                classNamePrefix='select'
                placeholder='Filter'
                onChange={setFilteredOption}
              />
            </div>
            <div style={{ width: '200px' }} className='px-4'>
              <Select
                key='select-section-variants'
                className='basic-multi-select'
                defaultValue={sortByOption}
                isClearable={true}
                isSearchable={false}
                options={sortByOptions}
                classNamePrefix='select'
                placeholder='Sort By'
                onChange={setSortByOption}
              />
            </div>
            <div>
              <Button onClick={handleClickOpen} variant='contained'>
                Add Module To Hub
              </Button>
            </div>
          </div>
        </div>
      </Box>

      <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant='h4' component='span'>
            Add Module To Hub
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <TextField
              label='Hub Module Identifier'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={hubModuleId}
              disabled
            />
            <FormControl margin='normal' fullWidth>
              <InputLabel id='select-module-label'>
                Select Module to Add
              </InputLabel>
              <MUISelect
                labelId='select-module-label'
                id='module'
                label='Select Module to Add'
                size='medium'
                value={moduleId}
                onChange={handleModuleId}
              >
                {displayModuleListFilterData?.map((menuItem, index) => {
                  const { id } = menuItem;
                  return (
                    <MenuItem key={index} value={id}>
                      {id}
                    </MenuItem>
                  );
                })}
              </MUISelect>
            </FormControl>

            <TextField
              label='Title'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label='Sub Title'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <TextField
              label='Category'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              fullWidth
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 2,
              }}
            >
              <Tooltip
                arrow
                placement='top'
                title='This thumbnail is an optional element. By default, it utilizes the module icon. Changes made to this thumbnail are specific to this context and will not alter the default module icon appearance.'
              >
                <Avatar sx={{ height: '8rem', width: '8rem' }}>
                  <img
                    src={
                      _.isEmpty(moduleId)
                        ? ''
                        : _.isEmpty(thumbnail)
                        ? iconData?.moduleIcon
                        : thumbnail
                    }
                    alt=''
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Avatar>
              </Tooltip>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  disabled={_.isEmpty(moduleId)}
                  variant='primary'
                  size='small'
                  sx={{ justifyContent: 'start' }}
                  startIcon={<CloudUpload />}
                  onClick={handleFileChange}
                >
                  Replace
                </Button>
                <Button
                  disabled={_.isEmpty(moduleId)}
                  variant='primary'
                  size='small'
                  sx={{ justifyContent: 'start' }}
                  startIcon={<ReplayCircleFilled />}
                  onClick={handleUseDefaultThumbnail}
                >
                  Use Default
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {anyFieldEntered() && !addModuleToHubLoading && (
            <Button onClick={clearData}>Clear</Button>
          )}
          <LoadingButton
            disabled={!isFormValid()}
            variant='contained'
            size='medium'
            loading={addModuleToHubLoading}
            onClick={executeAddModuleToHub}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HubHeader;
