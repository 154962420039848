import { useReactiveVar } from '@apollo/client';
import { CloudUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from '@mui/material';
import { rVarCMSApp } from 'global/const';
import { useGetSectionCover, useMutateSectionCover } from 'graphql/gen.types';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useFileUpload } from 'use-file-upload';

const CoverModal = ({ sectionId, onCloseDialog }) => {
  const { moduleId } = useReactiveVar(rVarCMSApp);

  const [file, selectFile] = useFileUpload();
  const [sectionCover, setSectionThumbnail] = useState('');

  const {
    loading,
    error,
    data: sectionCoverData,
  } = useGetSectionCover({
    variables: {
      moduleId: moduleId,
      sectionId: sectionId,
    },
  });

  useMemo(() => {
    if (!loading && !error && sectionCoverData.sectionCover) {
      const { sectionCover } = sectionCoverData;
      setSectionThumbnail(sectionCover);
    }
  }, [sectionCoverData, loading, error]);

  const fileSelectedHandler = ({ name, size, source, file }) => {
    console.log('Files Selected', { name, size, source, file });
    const reader = new FileReader();
    reader.onload = () => {
      setSectionThumbnail(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onClick = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
    console.log(file);
  };

  const [
    updateSectionThumbnail,
    { loading: sectionCoverLoading, error: sectionCoverError },
  ] = useMutateSectionCover();

  const MutateSectionThumbnailInput = {
    moduleId: moduleId,
    sectionId: sectionId,
    cover: sectionCover ? sectionCover : null,
  };

  const updateSectionThumbnailItem = async () => {
    try {
      await updateSectionThumbnail({
        variables: {
          input: MutateSectionThumbnailInput,
        },
      });
      if (!sectionCoverLoading) {
        onCloseDialog();
      }
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  if (sectionCoverError) {
    return <Alert severity='error'>Error performing network request</Alert>;
  }

  const isSectionCoverFieldChange = () => {
    if (!loading && !error && sectionCoverData.sectionCover) {
      return !_.eq(sectionCoverData?.sectionCover, sectionCover);
    }
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={true}
      onClose={onCloseDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle>
        {error ? (
          <Alert severity='error'>Error performing network request</Alert>
        ) : (
          ''
        )}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Avatar variant='square' sx={{ height: '12rem', width: '100%' }}>
            {loading ? (
              <CircularProgress />
            ) : sectionCover && !error ? (
              <img
                src={sectionCover}
                alt=''
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : (
              ''
            )}
          </Avatar>
          <Button
            sx={{ my: 1 }}
            variant='primary'
            size='medium'
            startIcon={<CloudUpload />}
            onClick={onClick}
          >
            {sectionCoverData?.sectionCover ? 'Replace Cover' : 'Upload  Cover'}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        {!loading && !error ? (
          sectionCoverData?.sectionCover ? (
            <LoadingButton
              disabled={!isSectionCoverFieldChange()}
              loading={sectionCoverLoading}
              onClick={updateSectionThumbnailItem}
              variant='contained'
              size='medium'
            >
              Update Section Cover
            </LoadingButton>
          ) : (
            <LoadingButton
              disabled={!sectionCover}
              loading={sectionCoverLoading}
              onClick={updateSectionThumbnailItem}
              variant='contained'
              size='medium'
            >
              Create Section Cover
            </LoadingButton>
          )
        ) : (
          ''
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CoverModal;
