import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useYTContext } from '../cms_context/YTContext';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';

const YTBiteModal = ({ closeModalType, open, edit, prevYTVideoId }) => {
  const maxDimension = 600;

  const [YTError, setYTError] = useState(null);

  const {
    ytVideoId,
    ytVideoUrl,
    setYtVideoUrl,
    youtubeVideoBiteError: error,
    youtubeVideoBiteLoading: loading,
    executeYouTubeVideoBite,
  } = useYTContext();

  const embedUrl = `https://www.youtube.com/embed/${ytVideoId}`;

  const hasYTVideoIdChanged = () => {
    return !_.eq(ytVideoId, prevYTVideoId);
  };

  const resetYTVideoId = () => {
    const embedUrl = `https://www.youtube.com/embed/${prevYTVideoId}`;
    setYtVideoUrl(embedUrl);
  };

  const handleYouTubeVideo = async () => {
    try {
      ytVideoUrl &&
        (await executeYouTubeVideoBite({
          onCompleted: () => {
            closeModalType();
          },
        }));
    } catch (error) {
      console.log('saveVideoBite  error:', error);
    }
  };

  useEffect(() => {
    if (error) {
      setYTError(true);
      setTimeout(() => {
        setYTError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Dialog maxWidth='md' fullWidth open={open} onClose={closeModalType}>
      <DialogTitle>
        <Typography variant='h4' component='span'>
          {edit ? 'Update YouTube Video' : 'Add YouTube Video'}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={closeModalType}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        className='d-flex align-items-center justify-content-center flex-column'
      >
        <DialogContentText
          sx={{ maxWidth: `${maxDimension}px`, width: '100%', mb: 2 }}
        >
          {YTError ? (
            <Alert severity='error'>
              Oops, something went wrong. Please try again later.
            </Alert>
          ) : null}
        </DialogContentText>

        <Box sx={{ width: `${maxDimension}px` }}>
          <Box
            sx={{
              aspectRatio: '16/9',
              position: 'relative',
              width: '100%',
            }}
          >
            <iframe
              src={embedUrl}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Box>

          <TextField
            label='Paste YouTube Video Url'
            fullWidth
            size='medium'
            margin='normal'
            value={ytVideoUrl}
            onChange={(e) => setYtVideoUrl(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {edit ? (
          <>
            {hasYTVideoIdChanged() && (
              <Button onClick={resetYTVideoId}>Reset</Button>
            )}
            <LoadingButton
              loading={loading}
              disabled={!hasYTVideoIdChanged()}
              onClick={handleYouTubeVideo}
              variant='contained'
            >
              Save
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            loading={loading}
            disabled={!ytVideoUrl}
            onClick={handleYouTubeVideo}
            variant='contained'
          >
            Add
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default YTBiteModal;
