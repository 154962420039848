import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { rVarCMSSectionSelectionID, rVarSelectedContent } from 'global/const';
import CoverModal from './section_modal/CoverModal';
import DeleteContentBiteDialog from './content_bites/DeleteContentBiteDialog';
import { useDeleteSection, useReorderSections } from 'graphql/gen.types';
import SectionModal from './section_modal/SectionModal';
import _ from 'lodash';
import {
  AppSectionsToReactTree,
  ReorderSectionsToReactTree,
} from 'global/DataUtils';
import { useCMSContext } from './CMSContext';
import { TreeItem } from 'components/cms/TreeItemComponent';
import { SortableTree } from 'dnd-kit-sortable-tree';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCMSMainContext } from 'context/CMSMainContext';

const SectionSideBar = () => {
  const {
    selectedModule: { module },
  } = useCMSMainContext();

  const selectedContentId = useReactiveVar(rVarSelectedContent);

  const sectionCloseRef = useRef();

  // state used by the component
  const [treeState, setTreeState] = useState(() => {
    return { tree: {}, active: null };
  });

  const {
    showSectionModal,
    setShowSectionModal,
    sectionId,
    setSectionId,
    deleteSectionModal,
    setDeleteSectionModal,
    showSectionThumbnailModal,
    setShowSectionThumbnailModal,
    setDisplaySelectedCGId,
  } = useCMSContext();

  const [sectionsData, setSectionsData] = useState(
    treeState?.tree?.children || [],
  );

  useEffect(() => {
    setDisplaySelectedCGId(
      selectedContentId?.sectionId || module?.sections?.[0]?.id,
    );
    // eslint-disable-next-line
  }, [selectedContentId, module]);

  // After rendering, set the active section id so that core content section can display
  // the content bite list
  useEffect(() => {
    rVarCMSSectionSelectionID(module?.sections?.[0]?.id);
  });

  // set the treestate using a memo-ized function
  useMemo(() => {
    // object for building the tree
    setTreeState(AppSectionsToReactTree(module?.id, module?.sections).build());
  }, [module]);

  const onCloseDialog = () => {
    setDeleteSectionModal(false);
    setShowSectionModal('');
    setSectionId(null);
    setShowSectionThumbnailModal(false);
  };

  const deleteSectionInput = {
    moduleId: module?.id,
    sectionId: sectionId,
  };

  const [deleteSection, { loading: deleteSectionLoading }] = useDeleteSection();

  const deleteSectionItem = async () => {
    try {
      await deleteSection({
        variables: {
          input: deleteSectionInput,
        },
        onCompleted: () => {
          onCloseDialog();
        },
      });
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const SectionHeader = () => {
    return (
      <>
        <Container maxWidth='xl'>
          <Box sx={{ pt: 4 }}>
            <Typography gutterBottom variant='h4'>
              Sections
            </Typography>
            <Divider />
          </Box>
        </Container>
      </>
    );
  };

  useMemo(() => {
    if (treeState) {
      setSectionsData(_.cloneDeep(treeState?.tree?.children));
    }
  }, [treeState]);

  const [reorderSection] = useReorderSections();

  const reorderSectionData = async (input) => {
    await reorderSection({
      variables: { input: input },
    }).catch((error) => {
      console.error(error);
    });
  };

  const updateSectionsAndReorder = (updatedSections) => {
    if (!_.isEqual(updatedSections, sectionsData)) {
      const reorderSectionInput = {
        moduleId: module?.id,
        sections: ReorderSectionsToReactTree(updatedSections).build(),
      };
      reorderSectionData(reorderSectionInput);
    }
    setSectionsData(updatedSections);
  };

  return (
    <>
      {SectionHeader()}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            height: 'calc(100vh - 284px)',
            cursor: 'pointer',
            overflowX: 'hidden',
            overflowY: 'auto',
            py: 2,
          }}
        >
          {sectionsData ? (
            <SortableContext
              items={sectionsData}
              strategy={verticalListSortingStrategy}
            >
              <SortableTree
                indentationWidth={32}
                items={sectionsData}
                onItemsChanged={updateSectionsAndReorder}
                TreeItemComponent={TreeItem}
              />
            </SortableContext>
          ) : null}

          {showSectionModal === 'section' && (
            <SectionModal
              ref={sectionCloseRef}
              showSectionModal={showSectionModal}
              onCloseDialog={onCloseDialog}
            />
          )}
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Divider sx={{ mb: 1 }} />
          <Box>
            <Button
              fullWidth
              onClick={() => setShowSectionModal('section')}
              startIcon={<AddIcon />}
            >
              Add a Section
            </Button>
          </Box>
        </Box>
      </Box>

      {showSectionThumbnailModal && (
        <CoverModal sectionId={sectionId} onCloseDialog={onCloseDialog} />
      )}

      {deleteSectionModal && (
        <DeleteContentBiteDialog
          onCloseDialog={onCloseDialog}
          deleteContentBite={deleteSectionItem}
          deleteContentBiteLoading={deleteSectionLoading}
        />
      )}
    </>
  );
};

export default SectionSideBar;
