import { createContext, useContext, useState } from 'react';

const HubModuleContext = createContext(undefined);

const HubModuleProvider = ({ children }) => {
  const [filteredOption, setFilteredOption] = useState(null);
  const [sortByOption, setSortByOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <HubModuleContext.Provider
      value={{
        filteredOption,
        setFilteredOption,
        searchTerm,
        setSearchTerm,
        sortByOption,
        setSortByOption,
      }}
    >
      {children}
    </HubModuleContext.Provider>
  );
};

const useHubModuleContext = () => {
  return useContext(HubModuleContext);
};

export { HubModuleContext, HubModuleProvider, useHubModuleContext };
