import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  IsAccountSlugAvailableDocument,
  MeDocument,
  useUpdateAccountBrandColors,
  useUpdateAccountLogo,
  useUpdateAccountName,
  useUpdateAccountSlug,
} from 'graphql/gen.types';
import React, { useEffect, useState } from 'react';
import { useFileUpload } from 'use-file-upload';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { Check, CloudUpload } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiColorInput } from 'mui-color-input';

const REGEX_URL_SLUG = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const AccountLogoDocument = gql`
  query AccountLogo($accountId: ID!) {
    accountLogo(id: $accountId)
  }
`;

const CompanySettingComponent = () => {
  const client = useApolloClient();

  // Fetch the query
  const { loading, error, me } = client.readQuery({
    query: MeDocument,
  });

  const data = me;

  const [brandColor, setBrandColor] = useState('');
  const [colorOnBrand, setColorOnBrand] = useState('');
  const [accountName, setAccountName] = useState('');
  const [slug, setSlug] = useState('');

  // eslint-disable-next-line
  const [file, selectFile] = useFileUpload();
  const [imgDataUrl, setImgDataUrl] = useState('');

  const {
    loading: iconLoading,
    data: iconData,
    refetch: iconRefetch,
  } = useQuery(AccountLogoDocument, {
    variables: {
      accountId: data?.account?.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    executeSlugAvailable,
    { data: slugAvailable, loading: isAccountSlugAvailableLoading },
  ] = useLazyQuery(IsAccountSlugAvailableDocument, {
    variables: {
      slug: slug,
    },
    fetchPolicy: 'network-only',
  });

  const [
    updateAccountName,
    { error: updateAccountNameError, loading: updateAccountNameLoading },
  ] = useUpdateAccountName({
    variables: {
      accountId: me?.account?.id?.toString(),
      name: accountName,
    },
  });

  const [
    updateAccountSlug,
    { error: updateAccountSlugError, loading: updateAccountSlugLoading },
  ] = useUpdateAccountSlug({
    variables: {
      accountId: me?.account?.id?.toString(),
      slug: slug?.toString(),
    },
  });

  const [
    updateAccountLogo,
    { error: accountLogoError, loading: updateAccountIconLoading },
  ] = useUpdateAccountLogo({
    variables: {
      accountId: me?.account?.id?.toString(),
      logo: imgDataUrl,
    },
    onCompleted() {
      iconRefetch().catch((err) => console.log(err));
    },
  });

  const [
    updateAccountBrandColors,
    {
      error: updateAccountBrandColorsError,
      loading: updateAccountBrandColorsLoading,
    },
  ] = useUpdateAccountBrandColors({
    variables: {
      accountId: me?.account?.id?.toString(), // Replace with your actual account ID
      brandColor: brandColor,
      colorOnBrand: colorOnBrand,
    },
  });

  const fileSelectedHandler = ({ file }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImgDataUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = () => {
    selectFile({ accept: 'image/*' }, fileSelectedHandler);
  };

  // set the states that this component works on
  useEffect(() => {
    if (!_.isEmpty(data)) {
      setBrandColor(data?.account?.brandColor);
      setColorOnBrand(data?.account?.colorOnBrand);
      setAccountName(data?.account?.name);
      setSlug(data?.account.slug);
    }
  }, [data]);

  // set the states that this component works on
  useEffect(() => {
    if (!_.isEmpty(iconData)) {
      setImgDataUrl(iconData?.accountLogo);
    }
  }, [iconData]);

  const hasIconChanged = () => {
    return !_.eq(iconData?.accountLogo, imgDataUrl);
  };

  const resetIcon = () => {
    setImgDataUrl(iconData?.accountLogo);
  };

  const submitUpdateIcon = () => {
    if (hasIconChanged()) {
      updateAccountLogo().catch((err) => console.log(err));
    }
  };

  const hasNameChanged = () => {
    return !_.eq(accountName.trim(), data?.account?.name?.trim());
  };

  const resetName = () => {
    if (hasNameChanged()) {
      setAccountName(data?.account?.name?.trim());
    }
  };

  const submitUpdateName = () => {
    if (hasNameChanged()) {
      updateAccountName().catch((err) => console.log(err));
    }
  };

  const hasSlugChanged = () => {
    return !_.eq(data?.account.slug?.trim(), slug);
  };

  const isSlugValid = () => {
    return REGEX_URL_SLUG.test(slug);
  };

  const isAccountSlugAvailable = () => {
    return !slugAvailable && slugAvailable?.isAccountSlugAvailable;
  };

  const slugFieldErrorState = () => {
    if (!hasSlugChanged()) {
      return false;
    }
    if (isSlugValid()) {
      return false;
    }
    return !(isAccountSlugAvailableLoading || isAccountSlugAvailable());
  };

  const slugFieldHelperText = () => {
    if (!hasSlugChanged()) {
      return null;
    } else if (!isSlugValid()) {
      return 'Invalid slug';
    } else if (isAccountSlugAvailableLoading) {
      return null;
    } else if (!slugAvailable) {
      return 'Slug not available';
    } else {
      return null;
    }
  };

  const resetSlug = () => {
    setSlug(data?.account.slug);
  };

  const submitUpdateSlug = () => {
    if (hasSlugChanged() && isSlugValid()) {
      updateAccountSlug().catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (hasSlugChanged() && isSlugValid()) {
      executeSlugAvailable();
    }
    // eslint-disable-next-line
  }, [slug]);

  const hasColorsChanged = () => {
    return (
      !_.eq(data?.account?.colorOnBrand, colorOnBrand) ||
      !_.eq(data?.account?.brandColor, brandColor)
    );
  };

  const resetColors = () => {
    setColorOnBrand(data?.account?.colorOnBrand);
    setBrandColor(data?.account?.brandColor);
  };

  const submitUpdateAccountBrandColors = async () => {
    if (hasColorsChanged()) {
      updateAccountBrandColors().catch((err) => console.log(err));
    }
  };

  if (loading) {
    return (
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (
    error ||
    updateAccountNameError ||
    updateAccountSlugError ||
    accountLogoError ||
    updateAccountBrandColorsError
  ) {
    console.error('GraphQL Error:', error);
    return <Alert severity='error'>Error performing network request.</Alert>;
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h4'>Account Settings</Typography>
        <Container style={{ width: '520px', marginTop: '32px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Avatar sx={{ height: '128px', width: '128px' }}>
              <img
                src={imgDataUrl}
                alt=''
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            </Avatar>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '8px',
                mt: 1.2,
              }}
            >
              {!hasIconChanged() && (
                <Button
                  variant='primary'
                  size='medium'
                  startIcon={<CloudUpload />}
                  onClick={handleSelectFile}
                >
                  Replace
                </Button>
              )}

              {hasIconChanged() && (
                <LoadingButton
                  variant='primary'
                  loading={updateAccountIconLoading || iconLoading}
                  onClick={submitUpdateIcon}
                  size='medium'
                >
                  Save
                </LoadingButton>
              )}

              {hasIconChanged() &&
                !updateAccountIconLoading &&
                !iconLoading && (
                  <Button variant='primary' onClick={resetIcon} size='medium'>
                    Reset
                  </Button>
                )}
            </Box>
          </Box>

          <Box>
            <TextField
              fullWidth
              label='Account Id'
              id='outlined-size-small'
              size='medium'
              margin='normal'
              value={data.account.id}
              disabled
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              gap: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                label='Account Name'
                id='textfield-name'
                size='med'
                margin='normal'
                fullWidth
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    submitUpdateName();
                    ev.preventDefault();
                  } else if (ev.key === 'Escape') {
                    resetName();
                    ev.preventDefault();
                  }
                }}
                InputProps={{
                  endAdornment: updateAccountNameLoading ? (
                    <InputAdornment position='end'>
                      <CircularProgress
                        color='inherit'
                        size='40px'
                        sx={{ padding: '8px' }}
                      />
                    </InputAdornment>
                  ) : hasNameChanged() ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='submit changes'
                        onClick={submitUpdateName}
                      >
                        <Check />
                      </IconButton>
                      <IconButton
                        aria-label='cancel changes'
                        onClick={resetName}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              gap: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                label='Slug'
                id='textfield-slug'
                size='med'
                margin='normal'
                fullWidth
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                error={slugFieldErrorState()}
                helperText={slugFieldHelperText()}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    submitUpdateSlug();
                    ev.preventDefault();
                  } else if (ev.key === 'Escape') {
                    resetSlug();
                    ev.preventDefault();
                  }
                }}
                InputProps={{
                  endAdornment: updateAccountSlugLoading ? (
                    <InputAdornment position='end'>
                      <CircularProgress
                        color='inherit'
                        size='40px'
                        sx={{ padding: '8px' }}
                      />
                    </InputAdornment>
                  ) : hasSlugChanged() ? (
                    <InputAdornment position='end'>
                      {!slugFieldErrorState() && (
                        <IconButton
                          aria-label='submit changes'
                          onClick={submitUpdateSlug}
                        >
                          <Check />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label='cancel changes'
                        onClick={resetSlug}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '384px',
                gap: '16px',
              }}
            >
              <MuiColorInput
                margin='normal'
                label='Primary Color'
                format='hex'
                value={brandColor}
                onChange={(newValue) => setBrandColor(newValue)}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
              <MuiColorInput
                margin='normal'
                label='Secondary Color'
                format='hex'
                value={colorOnBrand}
                onChange={(newValue) => setColorOnBrand(newValue)}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '72px',
              }}
            >
              {updateAccountBrandColorsLoading ? (
                <CircularProgress
                  size={24}
                  sx={{ color: (theme) => theme.palette.grey[600] }}
                />
              ) : (
                hasColorsChanged() && (
                  <>
                    <IconButton
                      aria-label='submit changes'
                      onClick={submitUpdateAccountBrandColors}
                    >
                      <Check />
                    </IconButton>
                    <IconButton
                      aria-label='cancel changes'
                      onClick={resetColors}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CompanySettingComponent;
